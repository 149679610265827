import { useEffect } from 'react';
import DateRange from "../../../Filters/DateRange";
import WeekdayFilter from "../../../Filters/WeekdayFilter";
import CampaignStatusFilter from "../../../Filters/CampaignStatusFilter";
import NetworkFilter from "../../../Filters/NetworkFilter";
import UserFilter from "../../../Filters/UserFilter";
import AgencyCampaignFilter from '../../../Filters/AgencyCampaignFilter.tsx';
import { getFromLocalStorage } from '../../../../utils/local-storage-utils.ts';
import { changeFilter } from '../../../../Redux/Slices/filters.ts';
import { useAppDispatch, useAppSelector  } from '../../../../hooks.ts';
import type { AppDispatch } from '../../../../Redux/reduxStore.ts';

export const getFiltersFromLocalStorageState = (dispatch: AppDispatch) => {
	const filterLSKey = `${getFromLocalStorage('userID')}_filters`;
	let storedFilters = getFromLocalStorage(filterLSKey) || '{}';
	storedFilters = JSON.parse(storedFilters);
	Object.keys(storedFilters).forEach((filter: string) => {
		dispatch(changeFilter({ filter, value: storedFilters[filter] }));
	});
};

export const useIsFilterFromLocalStoragePulled = (): boolean => {
	const filters = useAppSelector((state) => state.filters);
	const filterLSKey = `${getFromLocalStorage('userID')}_filters`;
	const storedFilters = JSON.parse(getFromLocalStorage(filterLSKey) || '{}');
	const filterNamesArr = Object.keys(storedFilters);

	return filterNamesArr.length
		? filterNamesArr.every(filterName => storedFilters[filterName] === filters[filterName])
		: true
	}

export default function Filters () {
	const dispatch = useAppDispatch();

	useEffect(() => {
		getFiltersFromLocalStorageState(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex w-full items-center border-b border-b-black px-2 py-3">
			<DateRange />
			<WeekdayFilter />
			<NetworkFilter />
			<UserFilter />
			<CampaignStatusFilter />
			<AgencyCampaignFilter />
		</div>
	)
}
