import { LinearProgress } from '@mui/material'

export default function MaximusLoader({ size = 30 }: { size?: number }) {
	return (
		<div
			className="mx-auto my-2 text-center"
			style={{ width: size + '%', padding: size + 'px 0' }}
			data-testid="loader-container"
		>
			<LinearProgress classes={{ colorPrimary: 'bg-[#4285F466]', bar: 'bg-[#4285F4]' }} />
		</div>
	)
}
