import {useAppDispatch, useAppSelector} from "../hooks";
import {updateMainStore} from "../Redux/Slices/main";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";
import networkRequest from './networkRequest.ts';
import { getFromLocalStorage } from './local-storage-utils.ts';


export default function useAuth() {
	const { isLoading, isAuthenticated, getAccessTokenSilently, user, logout } = useAuth0();
	const {token: storedToken, userID: storedUserID} = useAppSelector((state) => state.main);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const interval = useRef<ReturnType<typeof setTimeout>>();

	const doLogout = () => {
		localStorage.removeItem(getFromLocalStorage('userID'));
		localStorage.removeItem(`${getFromLocalStorage('userID')}_filters`);
		localStorage.removeItem('userID');
		localStorage.removeItem('userSettings_detailsReport');
		const endpoint = window.location.origin + '/login';
		logout({ logoutParams: { returnTo: endpoint } });
	};

	useEffect(() => {
		if (isLoading) return;

		if (!isAuthenticated || !user) {
			dispatch(updateMainStore({key: 'appLoaded', value: true}));
			if (pathname !== '/login') navigate('/login');
			return
		}

		const checkAuth = async () => {
			networkRequest('api/user/check', {
				externalID: user.sub,
				email: user.email,
				name: user.name,
			}, 'GET')
				.then((response) => response && response.json())
				.then((response) => {
					if (response && typeof response === 'object' && !response.success) {
						doLogout();
					}
				});
		};
		checkAuth();
		if (interval.current) clearInterval(interval.current);
		interval.current = setInterval(() => checkAuth(), 30_000);

		const storeAuth = async () => {
			const {id_token: token} = await getAccessTokenSilently({detailedResponse: true});

			if (user.email) {
				dispatch(updateMainStore({key: 'email', value: user.email}));
			}

			if (!storedToken) {
				dispatch(updateMainStore({key: 'token', value: token}));
			}

			if (!storedUserID) {
				dispatch(updateMainStore({key: 'userID', value: user.sub}));
				localStorage.setItem('userID', user.sub);
			}

			if (pathname === '/login') {
				navigate('/');
			}

			dispatch(updateMainStore({key: 'appLoaded', value: true}));
		}
		storeAuth();
	}, [isLoading, isAuthenticated, getAccessTokenSilently, storedToken, storedUserID, user, dispatch, navigate, pathname]);
}