import { Tooltip, Zoom } from '@mui/material'

export const ImageCell = ({ url }: { url: string }) => {
	if (!url) return ''
	return (
		<Tooltip
			slotProps={{
				tooltip: {
					sx: {
						display: 'flex',
						padding: 0,
						maxWidth: 'none',
						maxHeight: 'none',
						boxShadow: '0 1px 3px #0004, 0 3px 6px #0005',
					},
				},
			}}
			placement='right'
			TransitionComponent={Zoom}
			title={<img className="images-report-thumbnail-cell popover" src={url} />}
		>
			<img className="images-report-thumbnail-cell" src={url} />
		</Tooltip>
	)
}
