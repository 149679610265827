import Input from '../../../Fields/Input';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeFireAndForgetField } from '@/Redux/Slices/addCampaign.ts';

export default function SlowAmount(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			fireAndForgetStatus,
			slowStopAmountActive,
			slowAmount,
			stopAmount,
		},
		errors: {
			fields: {
				slowAmount: slowAmountError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => {
				updateFireAndForget('slowAmount', value);
				updateFireAndForget('stopAmount', stopAmount);
			}}
			label="Slow Amount"
			value={slowAmount}
			name="slow-amount"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={slowStopAmountActive === false || fireAndForgetStatus === false}
			error={!!slowAmountError}
			helperText={slowAmountError}
		/>
	)
}
