export default function Lock({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M242.87-72.587q-37.538 0-64.269-26.731-26.732-26.731-26.732-64.269v-394.261q0-37.537 26.732-64.269 26.731-26.731 64.269-26.731h33.543v-73.065q0-84.913 59.457-144.609 59.456-59.696 144.13-59.696 84.674 0 144.13 59.696 59.457 59.696 59.457 144.609v73.065h33.543q37.538 0 64.269 26.731 26.732 26.732 26.732 64.269v394.261q0 37.538-26.732 64.269-26.731 26.731-64.269 26.731H242.87ZM480-280.717q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5ZM367.413-648.848h225.174v-73.065q0-47.21-32.728-80.257Q527.13-835.217 480-835.217q-47.13 0-79.859 33.047-32.728 33.047-32.728 80.257v73.065Z"/>
    </svg>
  )
}
