import {IconButton} from "@mui/material";
import SectionTitle from "../Components/SectionTitle";
import Minus from "../../../../Icons/Minus";
import Add from "../../../../Icons/Add";
import Toggle from "../../../FormComponents/Toggle";
import Input from "../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {
	changePresellLink,
	changeLandingPageLink,
	removeLink,
	addLink,
	changeNonField,
	changeLink, changeSettingsField,
} from "../../../../Redux/Slices/addCampaign";

interface AdditionalLinkProps {
	type: 'presell' | 'landing',
}

function AdditionalLinks({type}: AdditionalLinkProps) {
	const typeMapping = {
		presell: 'presellLinks',
		landing: 'landingPageLinks',
	}
	const links = useAppSelector((state) => state.addCampaign[typeMapping[type]]);
	const { errors } = useAppSelector((state) => state.addCampaign);
	const dispatch = useAppDispatch();
	const onChange = (value: string, linkNumber: string, name: string) => {
		const newValue = {...links[linkNumber], [name]: value};
		if (type === 'presell') {
			dispatch(changePresellLink({index: linkNumber, value: newValue}));
		} else if (type === 'landing') {
			dispatch(changeLandingPageLink({index: linkNumber, value: newValue}));
		}
	};
	const returnTitles = (): { title: string, name: string } => {
		switch (type) {
			case 'presell':
				return {
					title: 'Presell URL',
					name: 'presell-url',
				};
			case 'landing':
				return {
					title: 'Landing Page URL',
					name: 'landing-url',
				};
			default:
				return {
					title: '',
					name: '',
				};
		}
	}
	const titles = returnTitles();
	return (Object.keys(links)).map((linkNumber) => (
		<div key={titles.name + '-' + linkNumber + '-key'} className="add-campaign-field-row">
			<Input
				onChange={({target: {value}}) => onChange(value, linkNumber, 'url')}
				label={titles.title}
				value={links[linkNumber].url}
				size={{m: '12px', width: 'calc(66.66% - 24px)'}}
				name={titles.name + '-' + linkNumber}
				error={errors.fields[typeMapping[type] + linkNumber]}
				helperText={errors.fields[typeMapping[type] + linkNumber]}
				multiline
			/>
			<Input
				onChange={({target: {value}}) => onChange(value, linkNumber, 'weight')}
				label="Weight"
				value={links[linkNumber].weight}
				size={{m: '12px', width: linkNumber === '1' ? 'calc(33.33% - 24px)' : 'calc(28.33% - 24px)'}}
				name={titles.name + '-weight-' + linkNumber}
				numeric
			/>
			{linkNumber !== '1' && (
				<IconButton
					className="links-minus-button"
					id={titles.name + '-' + linkNumber + '-remove'}
					onClick={() => dispatch(removeLink({stateKey: typeMapping[type], index: linkNumber}))}
				>
					<Minus className="links-minus"/>
				</IconButton>
			)}
		</div>
	))
}

export default function Links({ editLinks } : { editLinks?: boolean }) {
	const {
		advancedTracking,
		estimatedSpends,
		link,
		settings,
		errors
	} = useAppSelector((state) => state.addCampaign);
	const dispatch = useAppDispatch();
	const updateSettings = (name, value) => {
		dispatch(changeSettingsField({name, value}));
	};
	return (
		<>
			<SectionTitle
				title={settings.maximusLinks ? "Review Page" : "Campaign Link"}
				EndAddition={
					<div>
						<Toggle
							name="maximus-links"
							label="Maximus Links"
							trackColor="#4285F4"
							value={settings.maximusLinks}
							onChange={(checked) => updateSettings('maximusLinks', checked)}
						/>
						{settings.maximusLinks && (
							<Toggle
								label="Advanced Tracking"
								trackColor="#4285F4"
								value={advancedTracking}
								onChange={(checked) => dispatch(changeNonField({key: 'advancedTracking', value: checked}))}
								name="advanced-tracking"
							/>
						)}
						{!editLinks && settings.maximusLinks && (
							<Toggle
								label="Estimated Spends"
								trackColor="#4285F4"
								value={estimatedSpends}
								onChange={(checked) => dispatch(changeNonField({key: 'estimatedSpends', value: checked}))}
								name="estimated-spends"
							/>
						)}
					</div>
				}
			/>
			{settings.maximusLinks ? (
				<>
					<Input
						onChange={({target: {value}}) => dispatch(changeLink({key: 'link', value}))}
						label="Review Page URL"
						value={link}
						size={{m: '12px', width: 'calc(100% - 24px)'}}
						name="review-url"
						error={errors.fields.link}
						helperText={errors.fields.link}
						multiline
					/>
					<div className="mb-4"/>
					<SectionTitle title="Presell Links"/>
					<AdditionalLinks type="presell"/>
					<div className="add-link-text">
						<IconButton
							id="presell-url-add"
							className="links-add-button"
							onClick={() => dispatch(addLink({stateKey: 'presellLinks'}))}
						>
							<Add className="fill-white"/>
						</IconButton>
						New Presell Link
					</div>
					<div className="mb-4"/>
					{advancedTracking && (
						<>
							<SectionTitle title="Landing Page Links"/>
							<AdditionalLinks type="landing"/>
							<div className="add-link-text">
								<IconButton
									id="landing-url-add"
									className="links-add-button"
									onClick={() => dispatch(addLink({stateKey: 'landingPageLinks'}))}
								>
									<Add className="fill-white"/>
								</IconButton>
								New Landing Page Link
							</div>
							<div className="mb-4"/>
						</>
					)}
				</>
			) : (
				<>
					<Input
						onChange={({target: {value}}) => dispatch(changeNonField({key: 'link', value}))}
						label="Campaign Link URL"
						value={link}
						size={{m: '12px', width: 'calc(100% - 24px)'}}
						name="campaign-link-url"
						error={errors.fields.link}
						helperText={errors.fields.link}
						multiline
					/>
					<div className="mb-4"/>
				</>
			)}
		</>
	)
}
