export function editTableRows<T = Record<string, any>>(
  rows: Array<T>, id: number, fieldName: string, newValue: number | string | boolean, idField = 'id',
) {
  return rows.map((row) => {
    if (row[idField] === id) {
      return {
        ...row,
        [fieldName]: newValue,
      };
    }
    return row;
  });
}

/** @todo FIXME: uppercase naming is triggering a false positive for react-refresh linter export rule */
export const CurrencyComparator = (v1: string, v2: string) => Number(v1) - Number(v2);

const twelveHourClock = ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM'];

const normalizeHour = (hour: string) => {
  const time = hour.slice(0, -2).trim();
  const period = hour.slice(-2).toUpperCase();
  return `${time}${period}`;
}

export const hoursComparator = (h1: string, h2: string) => {
  const h1Index = twelveHourClock.indexOf(normalizeHour(h1));
  const h2Index = twelveHourClock.indexOf(normalizeHour(h2));
  return h1Index - h2Index;
};
