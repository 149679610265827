import Toggle from "../../../../../FormComponents/Toggle";
import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function ClickPacingClicks(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			clickPacingClicks,
			clickPacingActive,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				clickPacingClicks: clickPacingClicksError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('clickPacingClicks', value)}
			label="Click Pacing Clicks"
			value={clickPacingClicks}
			name="click-pacing-clicks"
			nonDecimal
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			endAdornment={
				<Toggle
					trackColor="#4285F4"
					value={clickPacingActive}
					onChange={(checked) => updateFireAndForget('clickPacingActive', checked)}
					name="click-pacing-status"
					disabled={fireAndForgetStatus === false}
				/>
			}
			disabled={clickPacingActive === false || fireAndForgetStatus === false}
			error={clickPacingClicksError}
			helperText={clickPacingClicksError}
		/>
	)
}
