import { FormControl, NativeSelect } from "@mui/material";
import {changeFilter} from "../../Redux/Slices/filters";
import {useAppDispatch, useAppSelector} from "../../hooks";

export default function CampaignStatusFilter() {
	const status = useAppSelector((state) => state.filters.status);
	const archiveStatus = useAppSelector((state) => state.filters.archiveStatus);
	const dispatch = useAppDispatch()
	const handleChange = ({ target: { value } }) => {
		if (value === "2") {
			dispatch(changeFilter({ filter: 'status', value: '-1' }));
			dispatch(changeFilter({ filter: 'archiveStatus', value: '1' }));
		} else {
			dispatch(changeFilter({ filter: 'status', value }));
			dispatch(changeFilter({ filter: 'archiveStatus', value: '-1' }));
		}
	}
	return (
		<FormControl className="select-filter-form-control" variant="standard" sx={{ m: 1, minWidth: 120 }}>
			<NativeSelect
				id="campaign-status-filter"
				value={archiveStatus === "1" ? "2" : status}
				onChange={handleChange}
				label="Status"
			>
				<option data-testid="campaign-status-filter--1" value="-1">All Campaigns</option>
				<option data-testid="campaign-status-filter-1" value="1">Active Campaigns</option>
				<option data-testid="campaign-status-filter-2" value="2">Archived Campaigns</option>
			</NativeSelect>
		</FormControl>
	)
}
