
export default function Upload({className}: {className?: string}) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			height="48"
			viewBox="0 -960 960 960"
			width="48"
		>
			<path xmlns="http://www.w3.org/2000/svg" d="M220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Zm230-153v-371L330-564l-43-43 193-193 193 193-43 43-120-120v371h-60Z"/>
		</svg>
	)
}
