import Report from './Report';
import Glance from "./Glance";
import {useEffect} from "react";
import {updateMainStore} from "../../../Redux/Slices/main";
import {useAppDispatch} from "../../../hooks";
import "./ManageCampaigns.css";

export default function ManageCampaigns() {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(updateMainStore({ key: 'tableRows', value: [] }));
	}, [])
	return (
		<div className="manage-container">
			<Glance />
			<Report />
		</div>
	)
}
