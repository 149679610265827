import {useLocation} from "react-router-dom";
import {Auth0Provider} from '@auth0/auth0-react';
import Appbar from "../Components/Navigation/Appbar";
import {Router} from "./Routes";

export default function Auth0RoutingContainer({combinedURL}) {
	const {pathname} = useLocation();

	const requestURI = () => {
		if (combinedURL) {
			return combinedURL;
		} else if (typeof window !== 'undefined') {
			return window.location.origin;
		}
		return '';
	}


	const getAuth0Data = (): { domain: string, clientId: string, audience: string } => {
		let domain = process.env.VITE_AUTH_0_DOMAIN || '';
		let clientId = process.env.VITE_AUTH_0_CLIENT_ID ||'';
		let audience : string = process.env.VITE_AUTH_0_AUDIENCE || '';

		if (import.meta?.env?.VITE_AUTH_0_DOMAIN) {
			domain = import.meta.env.VITE_AUTH_0_DOMAIN;
		}
		if (import.meta?.env?.VITE_AUTH_0_CLIENT_ID) {
			clientId = import.meta.env.VITE_AUTH_0_CLIENT_ID;
		}
		if (import.meta?.env?.VITE_AUTH_0_AUDIENCE) {
			audience= import.meta.env.VITE_AUTH_0_AUDIENCE;
		}

		return {
			domain,
			clientId,
			audience
		};
	}

	return (
		<Auth0Provider
			domain={getAuth0Data().domain}
			clientId={getAuth0Data().clientId}
			authorizationParams={{
				redirect_uri: requestURI(),
				prompt: 'login',
				audience: getAuth0Data().audience
			}}
			// cacheLocation='localstorage' // Uncomment to keep auth section after refresh on firefox
		>
			{!['/login', '/logout'].includes(pathname) && <Appbar/>}
			<Router/>
		</Auth0Provider>
	)
}
