import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {format} from "date-fns";
import {ValidateStepProps} from "../../Components/Pages/AddCampaign/Components/StepsValidation";
import { Network } from '../../utils/network-utils';

export type Ad = {
	image: string
	headline: string
	description: string
	callToAction: string
	fileType: string
	thumbnail: string
	uuid: string
}

export interface AddCampaignState {
	accountOptions: string[];
	settings: {
		creativeTrafficAllocation: string; siteRuleSet: string; marketingObjective: string;
		campaignObjective: string; usmID: string, conversionCapThreshold: string;
		brandingText: string; connectionType: string; osTargeting: string[]; countryTargeting: string[];
		taboolaAccount: string; taboolaSubAccount: string; outbrainSubAccount: string;
		conversionCapTimeframe: string; maximusLinks: boolean; originalMaximusLinks: boolean; platformTargeting: string[];
		browserTargeting: string[]; cpc: string; campaignName: string; startDate: string;
		budget: string; group: string, optimization: string, targetCPA: string,
		bidStrategy: string, isAgencyCampaign: boolean; vertical: string; affiliate: string; s1: string;
		isOttoCampaign: boolean, splitByDevice: boolean, language: string,
	};
	submitting: boolean;
	pixelOptions: string[];
	generatingAds: boolean;
	presellLinks: { "1": { weight: number; url: string } };
	originalPresellLinks: string[];
	originalLPLinks: string[];
	currentStep: number;
	ads: Ad[];
	adSetup: {
		validFilesToUpload: File[];
		filesToUpload: File[];
	}
	dayParting: object[];
	networkDayParting: object[];
	advancedTracking: boolean;
	maximusDayParting: boolean;
	estimatedSpends: boolean;
	link: string;
	landingPageLinks: { "1": { weight: number; url: string } };
	pulledSubAccounts: boolean;
	fireAndForget: {
		lowestBid: string; slowStopAmountActive: boolean; stopAmount: string; clickPacingClicks: string; clickPacingMinutes: string; campaignCpaGoal: string;
		budgetReset: string; campaignAcceptableProfit: string; campaignCpaGoalActive: boolean; campaignAcceptableProfitActive: boolean; siteAcceptableProfit: string; sampleSize: string;
		clickPacingActive: boolean; budgetResetActive: boolean; siteLowestBid: string; highestBid: string;
		protectProfitStop: string, protectProfitSlow: string, protectProfitActive: boolean;
		lookbackDays: string; siteHighestBid: string; slowAmount: string; fireAndForgetStatus: boolean, ignoreAct: boolean,
	};
	allowDenyListsLoaded: boolean;
	dayPartingLoaded: boolean;
	isClone: boolean;
	subAccountOptions: string[],
	errors: ValidateStepProps,
}

export const initialState: AddCampaignState = {
	submitting: false,
	pulledSubAccounts: false,
	isClone: false,
	allowDenyListsLoaded: false,
	dayPartingLoaded: false,
	advancedTracking: false,
	maximusDayParting: true,
	estimatedSpends: true,
	generatingAds: false,
	link: '',
	currentStep: 1,
	pixelOptions: [''],
	accountOptions: [''],
	subAccountOptions: [''],
	settings: {
		siteRuleSet: '',
		startDate: format(new Date(), 'yyyy-MM-dd'),
		connectionType: '0',
		browserTargeting: ['0'],
		marketingObjective: '1',
		campaignObjective: '0',
		campaignName: '',
		budget: '15',
		bidStrategy: '1',
		taboolaAccount: '0',
		taboolaSubAccount: '0',
		outbrainSubAccount: '0',
		cpc: '0.5',
		conversionCapThreshold: '0',
		usmID: '',
		countryTargeting: ['US'],
		osTargeting: ['0'],
		platformTargeting: ['0'],
		group: '0',
		creativeTrafficAllocation: '1',
		brandingText: '',
		conversionCapTimeframe: '0',
		maximusLinks: true,
		originalMaximusLinks: false,
		isAgencyCampaign: false,
		isOttoCampaign: false,
		splitByDevice: false,
		optimization: '0',
		targetCPA: '0',
		vertical: 'auto',
		affiliate: '',
		language: '',
		s1: '',
	},
	fireAndForget: {
		fireAndForgetStatus: true,
		budgetReset: '100',
		budgetResetActive: true,
		protectProfitStop: '1000.00',
		protectProfitSlow: '500.00',
		protectProfitActive: false,
		campaignAcceptableProfit: '15',
		campaignAcceptableProfitActive: false,
		campaignCpaGoal: '0',
		campaignCpaGoalActive: false,
		lowestBid: '0.01',
		highestBid: '0.25',
		slowAmount: '99',
		slowStopAmountActive: true,
		stopAmount: '100',
		lookbackDays: '0',
		sampleSize: '500',
		clickPacingClicks: '5',
		clickPacingMinutes: '15',
		clickPacingActive: true,
		ignoreAct: false,
		siteAcceptableProfit: '15',
		siteLowestBid: '0.01',
		siteHighestBid: '0.25',
	},
	landingPageLinks: {
		1: {
			url: '',
			weight: 100,
		},
	},
	presellLinks: {
		1: {
			url: '',
			weight: 100,
		},
	},
	originalPresellLinks: [],
	originalLPLinks: [],
	ads: [],
	adSetup: {
		filesToUpload: [],
		validFilesToUpload: [],
	},
	dayParting: [],
	networkDayParting: [],
	errors: {fields: {}},
};

export const initialRevContentState: AddCampaignState = {
	...initialState,
	settings: {
		...initialState.settings,
		budget: '50',
		language: '1',
	},
}

const removeErrors = (state: AddCampaignState, action: object) => {
	const {name} = action.payload;
	const {fields} = state.errors;
	if (fields[name]) {
		delete fields[name];
	}
}

const addCampaign = createSlice({
	name: 'addCampaign',
	initialState,
	reducers: {
		changeStep: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.currentStep = action.payload;
		},
		changeNonField: <K extends keyof AddCampaignState>(
			state: AddCampaignState,
			action: PayloadAction<{ key: K; value: AddCampaignState[K] }>
		) => {
			state[action.payload.key] = action.payload.value;
		},
		changeAdSetup: (state, action: PayloadAction<Partial<AddCampaignState['adSetup']>>) => {
			return {
				...state,
				adSetup: {
					...state.adSetup,
					...action.payload,
				}
			}
		},
		bulkUpdateAddCampaign: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			Object.assign(state,{
				...state,
				...action.payload,
				settings: {
					...state.settings,
					...action.payload.settings,
				},
				fireAndForget: {
					...state.fireAndForget,
					...action.payload.fireAndForget,
				},
			});
		},
		changeAdField: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.ads[action.payload.index][action.payload.name] = action.payload.value;
			removeErrors(state, action);
		},
		changeSettingsField: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.settings[action.payload.name] = action.payload.value;
			removeErrors(state, action);
		},
		changeFireAndForgetField: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.fireAndForget[action.payload.name] = action.payload.value;
			removeErrors(state, action);
		},
		changeLandingPageLink: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.landingPageLinks[action.payload.index] = action.payload.value;
			removeErrors(state, {payload: {name: 'landingPageLinks' + action.payload.index}});
		},
		addLink: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			const {stateKey} = action.payload;
			const defaultObject = Object.keys(state[stateKey]).length === 0 ? {'0': ''} : state[stateKey];
			const highestKey = Object.keys(defaultObject).reduce((a, b) => Math.max(+a, +b));
			const newKey = (parseInt(highestKey, 10) + 1).toString();
			state[stateKey][newKey] = {
				url: '',
				weight: 100,
			};
		},
		removeLink: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			const {stateKey, index} = action.payload;
			removeErrors(state, {payload: {name: stateKey + action.payload.index}});
			delete state[stateKey][index];
		},
		changePresellLink: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			removeErrors(state, {payload: {name: 'presellLinks' + action.payload.index}});
			state.presellLinks[action.payload.index] = action.payload.value;
		},
		changeLink: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			removeErrors(state, {payload: {name: 'link'}});
			state.link = action.payload.value;
		},
		changeAccount: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.accountOptions[action.payload.index] = action.payload.value;
		},
		changeSubAccount: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.subAccountOptions[action.payload.index] = action.payload.value;
		},
		addToDayParting: (state, action = {
			payload: undefined,
			type: ""
		}) => {
			state.dayParting.push(action.payload.value);
		},
    addToNetworkDayParting: (state, action = {
      payload: undefined,
      type: ""
    }) => {
      state.networkDayParting.push(action.payload.value);
    },
		resetAddCampaign: (state, action) => {
			const stateMap = new Map([
				[Network.Taboola, initialState],
				[Network.RevContent, initialRevContentState],
			])
			Object.assign(state, stateMap.get(action.payload.network) || initialState);
		}
	}
});


export const {
	changeStep,
	changeNonField,
	changeAdField,
	changeAdSetup,
	changeSettingsField,
	changeLandingPageLink,
	changePresellLink,
	changeLink,
	changeAccount,
	changeSubAccount,
	addToDayParting,
	addToNetworkDayParting,
	resetAddCampaign,
	changeFireAndForgetField,
	bulkUpdateAddCampaign,
	addLink,
	removeLink,
} = addCampaign.actions;

export default addCampaign.reducer;
