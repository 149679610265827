import {Button, ButtonGroup} from '@mui/material';
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function OptimizationMode(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			campaignAcceptableProfitActive,
			campaignCpaGoalActive,
			fireAndForgetStatus
		}
	} = useAppSelector((state) => state.addCampaign);

	const optimizationLevelSelect = (acceptableProfitActive: boolean, cpaGoalActive: boolean) => {
		dispatch(changeFireAndForgetField({name: 'campaignAcceptableProfitActive', value: acceptableProfitActive}));
		dispatch(changeFireAndForgetField({name: 'campaignCpaGoalActive', value: cpaGoalActive}));
	};

	return (
		<div className="optimization-mode">
			Please select your optimization mode:
			<ButtonGroup
				aria-label="campaign cpa goal"
				className="optimization-mode-buttons"
				disabled={fireAndForgetStatus === false}
			>
				<Button
					variant={(!campaignAcceptableProfitActive && !campaignCpaGoalActive) ? 'contained' : 'outlined'}
					onClick={() => optimizationLevelSelect(false, false)}
					id="optimization-mode-none-button"
				>
					None
				</Button>
				<Button
					variant={(!campaignAcceptableProfitActive && campaignCpaGoalActive) ? 'contained' : 'outlined'}
					onClick={() => optimizationLevelSelect(false, true)}
					id="optimization-mode-cpa-button"
				>
					CPA Goal
				</Button>
				<Button
					variant={(campaignAcceptableProfitActive && !campaignCpaGoalActive) ? 'contained' : 'outlined'}
					onClick={() => optimizationLevelSelect(true, false)}
					id="optimization-mode-profit-button"
				>
					Acceptable Profit
				</Button>
			</ButtonGroup>
		</div>
	)
}
