import { format } from 'date-fns';
import SectionTitle from '../Components/SectionTitle';
import Toggle from '../../../FormComponents/Toggle';
import ChipSelect from '../Fields/ChipSelect';
import Input from '../Fields/Input';
import Select from '../Fields/Select';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeSettingsField } from '../../../../Redux/Slices/addCampaign';

const formatDate = (date: string) => {
  if (!date) return '';
  const splitDate = date.split('-');
  if (splitDate.length !== 3) return '';
  if (splitDate[0].length !== 4) return date;
  return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
}

// TODO - We need to pull these from BE when we have the API for it.
const conversionCapOptions: Array<{value: string, label: string}> = [
  { value: '0', label: 'No Cap' },
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
];

const trafficAllocationOptions: Array<{value: string, label: string}> = [
  { value: '1', label: 'Even' },
  { value: '2', label: 'Optimized' },
];

const groupOptions: Array<{value: string, label: string}> = [
  { value: '0', label: 'Please Select' },
  { value: '1', label: 'Test Group' },
  { value: '2', label: 'Second Test Group' },
];

const subAccountOptions: Array<{value: string, label: string}> = [
  { value: '0', label: 'Please Select' },
  { value: '1', label: 'Outbrain Sub Account' },
  { value: '2', label: 'Second Test Sub Account' },
];

const osTargeting: Array<{value: string, label: string}> = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Mac OS' },
  { value: '2', label: 'iOS' },
];

const platformOptions: Array<{value: string, label: string}> = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Desktop' },
  { value: '2', label: 'Mobile' },
];

const campaignObjective: Array<{value: string, label: string}> = [
  { value: '0', label: 'Conversions' },
  { value: '1', label: 'Not Conversions' },
];

const optimizationOptions: Array<{value: string, label: string}> = [
  { value: '0', label: 'Automatic' },
  { value: '1', label: 'Semi Manual' },
  { value: '2', label: 'Manual' },
];

export default function OutbrainDetails() {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state) => state.addCampaign);
  const updateSettings = (name, value) => {
    dispatch(changeSettingsField({ name, value }));
  }
  return (
    <>
      <SectionTitle
        title="Outbrain Campaign Details"
        EndAddition={
          <Toggle
            name="maximus-links"
            label="Maximus Links"
            trackColor="#4285F4"
            value={settings.maximusLinks}
            onChange={(checked) => updateSettings('maximusLinks', checked)}
          />
        }
      />
      <div className="add-campaign-field-row">
        <Input
          onChange={({ target: { value } }) => updateSettings('campaignName', value)}
          label="Campaign Name"
          value={settings.campaignName}
          name="campaign-name"
        />
        <Input
          onChange={({ target: { value } }) => updateSettings('siteRuleSet', value)}
          label="Site Rule Set"
          value={settings.siteRuleSet}
          name="site-rule-set"
        />
        <Input
          onChange={({ target: { value } }) => updateSettings('startDate', (value || '').toString())}
          label="Start Date"
          type="date"
          value={format(new Date(formatDate(settings.startDate)), 'yyyy-MM-dd')}
          name="start-date"
        />
      </div>
      <div className="add-campaign-field-row">
        <Input
          onChange={({ target: { value } }) => updateSettings('budget', value)}
          label="Budget"
          value={settings.budget}
          name="campaign-budget"
          numeric
          startAdornment={<span>$</span>}
        />
        <Input
          onChange={({ target: { value } }) => updateSettings('cpc', value)}
          label="Campaign CPC"
          value={settings.cpc}
          numeric
          name="campaign-cpc"
          startAdornment={<span>$</span>}
        />
        <Select
          onChange={({ target: { value } }) => updateSettings('creativeTrafficAllocation', value)}
          label="Creative Traffic Allocation"
          value={settings.creativeTrafficAllocation}
          name="creative-traffic-allocation"
          options={trafficAllocationOptions}
        />
      </div>
      <div className="add-campaign-field-row">
        <Input
          onChange={({ target: { value } }) => updateSettings('conversionCapThreshold', value)}
          label="Conversion Cap Threshold"
          value={settings.conversionCapThreshold}
          name="conversion-cap-threshold"
          numeric
          disabled={settings.conversionCapTimeframe === '0'}
        />
        <Select
          onChange={({ target: { value } }) => updateSettings('conversionCapTimeframe', value)}
          label="Conversion Cap Timeframe"
          value={settings.conversionCapTimeframe}
          name="conversion-cap-timeframe"
          options={conversionCapOptions}
        />
        <Input
          onChange={({ target: { value } }) => updateSettings('brandingText', value)}
          label="Branding Text"
          value={settings.brandingText}
          name="branding-text"
        />
      </div>
      <div className="mb-3" />
      <SectionTitle title="Account Details" />
      <div className="add-campaign-field-row">
        <Select
          onChange={({ target: { value } }) => updateSettings('group', value)}
          label="Group"
          value={settings.group}
          name="campaign-group"
          options={groupOptions}
        />
        <Select
          onChange={({ target: { value } }) => updateSettings('outbrainSubAccount', value)}
          label="Outbrain Sub Account"
          value={settings.outbrainSubAccount}
          name="outbrain-sub-account"
          options={subAccountOptions}
        />
      </div>
      <div className="mb-3" />
      <SectionTitle title="Campaign Targeting" />
      <div className="add-campaign-field-row">
        <ChipSelect
          onChange={({ target: { value } }) => updateSettings('osTargeting', value)}
          label="OS Targeting"
          value={settings.osTargeting}
          name="os-targeting"
          options={osTargeting}
        />
        <ChipSelect
          onChange={({ target: { value } }) => updateSettings('platformTargeting', value)}
          label="Platform Targeting"
          value={settings.platformTargeting}
          name="platform-targeting"
          options={platformOptions}
        />
      </div>
      <div className="mb-3"/>
      <SectionTitle title="Outbrain Optimization" />
      <div className="add-campaign-field-row">
        <Select
          onChange={({ target: { value } }) => updateSettings('campaignObjective', value)}
          label="Campaign Objective"
          value={settings.campaignObjective}
          name="campaign-objective"
          options={campaignObjective}
        />
        <Select
          onChange={({ target: { value}}) => updateSettings('optimization', value)}
          label="Optimization"
          value={settings.optimization}
          name="optimization"
          options={optimizationOptions}
        />
      </div>
    </>
  )
}
