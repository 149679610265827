import Toggle from "../../../../../FormComponents/Toggle";
import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function BudgetReset(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget:
			{
				budgetReset,
				budgetResetActive,
				fireAndForgetStatus
			},
		errors: {
			fields: {
				budgetReset: budgetResetError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('budgetReset', value)}
			label="Budget Reset"
			value={budgetReset}
			numeric
			name="budget-reset"
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			endAdornment={
				<Toggle
					trackColor="#4285F4"
					value={budgetResetActive}
					onChange={(checked) => updateFireAndForget('budgetResetActive', checked)}
					name="budget-reset-status"
					disabled={fireAndForgetStatus === false}
				/>
			}
			disabled={budgetResetActive === false || fireAndForgetStatus === false}
			error={budgetResetError}
			helperText={budgetResetError}
		/>
	)
}
