import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import { changeFireAndForgetField } from '../../../../../../Redux/Slices/addCampaign';

export default function ProtectProfitSlow(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			protectProfitSlow,
			protectProfitActive,
			fireAndForgetStatus,
		},
		errors: {
			fields: {
				protectProfitSlow: protectProfitSlowError
			}
		},
	} = useAppSelector((state) => state.addCampaign);
	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('protectProfitSlow', value)}
			label="Protect Profit Slow"
			value={protectProfitSlow}
			name="protect-profit-slow"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={protectProfitActive === false || fireAndForgetStatus === false}
			error={protectProfitSlowError}
			helperText={protectProfitSlowError}
		/>
	)
}
