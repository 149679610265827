import IgnoreACT from "../Fields/IgnoreACT";
import LookBackDays from "../Fields/LookBackDays";
import SampleSize from "../Fields/SampleSize";
import ProtectProfitStop from "../Fields/ProtectProfitStop";
import StopAmount from "../Fields/StopAmount";
import BudgetReset from "../Fields/BudgetReset";

export default function NonCPCCampaign(): JSX.Element {
	return (
		<>
			<div className="add-campaign-field-row">
				<BudgetReset/>
				<StopAmount/>
			</div>
			<div className="add-campaign-field-row">
				<ProtectProfitStop/>
			</div>
			<div className="add-campaign-field-row">
				<SampleSize/>
				<LookBackDays/>
			</div>
			<div className="add-campaign-field-row">
				<IgnoreACT/>
			</div>
		</>
	)
}
