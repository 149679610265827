import { Datas, Columns } from 'react-csv-downloader/dist/esm/lib/csv';

export default function formatCSVData (data: object[], columns: Columns, customFormat = (value) => value): Datas {
	return data.map((row) => {
		const newRow = {};
		columns.map((col) => {
			newRow[col.id] = customFormat(row[col.id], col.id);
		});
		return newRow;
	});
}
