import {Provider} from "react-redux";
import {StyledEngineProvider, ThemeProvider} from "@mui/material";
import { LicenseInfo } from '@mui/x-license';
import {store} from "./Redux/reduxStore";
import UIElements from "./utils/UIElements";
import {theme} from "./utils/ThemeOverride";
import Auth0RoutingContainer from "./Routing/Auth0RoutingContainer";

export default function App({combinedURL}) {

	LicenseInfo.setLicenseKey(import.meta.env.VITE_APP_MUI_LICENSE);

	return (
		<div className="flex h-full w-full flex-col">
			<Provider store={store}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Auth0RoutingContainer combinedURL={combinedURL} />
						<UIElements/>
					</ThemeProvider>
				</StyledEngineProvider>
			</Provider>
		</div>
	)
}
