import {GridAggregationFunction} from "@mui/x-data-grid-premium";

export const avg : GridAggregationFunction<string, number | null> =
	{
		apply: ({values}) => {
			if (values.length === 0) {
				return '';
			}

			const nonZeroValues = values.filter((value) => +value !== 0);

			const sum = nonZeroValues.reduce((partialSum, a) => partialSum + +a, 0);

			if (isNaN(sum)) {
				return '';
			} else if (+sum === 0 && nonZeroValues.length === 0) {
				return '0';
			}
			return sum / nonZeroValues.length;
		},
		label: '',
		columnTypes: ['string', 'number'],
	};