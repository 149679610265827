import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function SampleSize(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			sampleSize,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				sampleSize: sampleSizeError
			}
		},
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('sampleSize', value)}
			label="Sample Size"
			value={sampleSize}
			name="sample-size"
			nonDecimal
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			error={sampleSizeError}
			helperText={sampleSizeError}
			disabled={fireAndForgetStatus === false}
		/>
	)
}
