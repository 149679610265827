
export default function ExpandMore({className}: {className?: string}) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			height="48"
			viewBox="0 -960 960 960"
			width="48"
		>
			<path xmlns="http://www.w3.org/2000/svg" d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"/>
		</svg>
	)
}
