import { store } from '../../../../../Redux/reduxStore.ts';
import { AddCampaignState } from '../../../../../Redux/Slices/addCampaign.ts';
import { getNetworkLabel } from '../../Pages/constants.ts';
import { ValidateStepProps } from '../StepsValidation.tsx';

export const countDecimals = (number: number) => {
	if (Math.floor(number) === number) return 0;
	const decimalPart = number.toString().split('.')[1] || '';
	return decimalPart.length || 0;
}

export const validateFireAndForget = (settings: AddCampaignState['fireAndForget'], hasCpc: boolean): ValidateStepProps => {
	const errors: ValidateStepProps = {fields: {}};
	const network = store.getState().campaignDetails.network
	const networkLabel = getNetworkLabel(network)
	if (settings.fireAndForgetStatus) {
		if (settings.budgetResetActive) {
			if (Number(settings.budgetReset) <= 5) {
				errors.fields.budgetReset = 'Budget Reset should be greater than $5.';
			}
		}
		if (hasCpc) {
			if (settings.campaignAcceptableProfitActive && Number(settings.campaignAcceptableProfit) <= 0) {
				errors.fields.campaignAcceptableProfit = 'Campaign Acceptable Profit should be greater than 0%.';
			}
			if (settings.campaignCpaGoalActive && Number(settings.campaignCpaGoal) <= 0) {
				errors.fields.campaignCpaGoal = 'Campaign CPA Goal should be greater than $0.';
			}
			if (!settings.campaignAcceptableProfitActive) {
				if (Number(settings.lowestBid) < 0.01 || Number(settings.lowestBid) >= Number(settings.highestBid)) {
					errors.fields.lowestBid = 'Lowest Bid should be greater than $0.01 and less than Highest Bid.';
				}
				if (Number(settings.highestBid) > 5 || Number(settings.highestBid) <= Number(settings.lowestBid)) {
					errors.fields.highestBid = 'Highest Bid should be greater than Lowest Bid but not more than $5.';
				}
			}
		}

		if (settings.slowStopAmountActive) {
			if (hasCpc) {
				if (Number(settings.slowAmount) <= 0 || Number(settings.slowAmount) >= Number(settings.stopAmount)) {
					errors.fields.slowAmount = 'Slow Amount should be greater than $0 and less than Stop Amount.';
				}
				if (Number(settings.stopAmount) <= 0 || Number(settings.stopAmount) <= Number(settings.slowAmount)) {
					errors.fields.stopAmount = 'Stop Amount should be greater than Slow Amount.';
				}
				if (countDecimals(Number(settings.slowAmount)) > 2 || countDecimals(Number(settings.stopAmount)) > 2) {
					errors.fields.slowAmount = 'Slow Amount and Stop Amount should not have more than 2 decimal places.';
				}
			} else {
				if (Number(settings.stopAmount) <= 0 || countDecimals(Number(settings.stopAmount)) > 2) {
					errors.fields.stopAmount = 'Stop Amount should be greater than 0 and should not have more than 2 decimal places.';
				}
			}
		}
		if (settings.protectProfitActive) {
			if (hasCpc) {
				if (Number(settings.protectProfitSlow) <= 0 || Number(settings.protectProfitSlow) >= Number(settings.protectProfitStop)) {
					errors.fields.protectProfitSlow = 'Protect Profit Slow should be greater than $0 and less than Protect Profit Stop.';
				}
				if (Number(settings.protectProfitStop) <= 0 || Number(settings.protectProfitStop) <= Number(settings.protectProfitSlow)) {
					errors.fields.protectProfitStop = 'Protect Profit Stop should be greater than Protect Profit Slow.';
				}
				if (countDecimals(Number(settings.protectProfitSlow)) > 2 || countDecimals(Number(settings.protectProfitStop)) > 2) {
					errors.fields.protectProfitSlow = 'Protect Profit Slow and Protect Profit Stop should not have more than 2 decimal places.';
				}
			} else {
				if (Number(settings.protectProfitStop) <= 0 || countDecimals(Number(settings.protectProfitStop)) > 2) {
					errors.fields.protectProfitStop = 'Protect Profit Stop should be greater than 0 and should not have more than 2 decimal places.';
				}
			}
		}
		if (settings.clickPacingActive && hasCpc) {
			if (Number(settings.clickPacingClicks) <= 0 || Number(settings.clickPacingClicks) > 1000000) {
				errors.fields.clickPacingClicks = 'Click Pacing Clicks number should be greater than 0 but not more than 1000000.';
			}
			if (Number(settings.clickPacingMinutes) < 1 || Number(settings.clickPacingMinutes) > 60) {
				errors.fields.clickPacingMinutes = 'Click Pacing Minutes number should be greater than 0 but not more than 60.';
			}
		}
		if (Number(settings.sampleSize) <= 0) {
			errors.fields.sampleSize = 'Sample Size number should be greater than 0.';
		}
		if (settings.campaignAcceptableProfitActive && hasCpc) {
			if (Number(settings.siteAcceptableProfit) <= 0) {
				errors.fields.siteAcceptableProfit = `${networkLabel} Acceptable Profit should be greater than 0%.`;
			}
			if (Number(settings.siteLowestBid) < 0.01 || Number(settings.siteLowestBid) >= Number(settings.siteHighestBid)) {
				errors.fields.siteLowestBid = `${networkLabel} Lowest Bid should be greater than $0.01 and less than ${networkLabel} Highest Bid.`;
			}
			if (Number(settings.siteHighestBid) > 5 || Number(settings.siteHighestBid) <= Number(settings.siteLowestBid)) {
				errors.fields.siteHighestBid = `${networkLabel} Highest Bid should be greater than ${networkLabel} Lowest Bid but not more than $5.`;
			}
		}
	}
	return errors;
};