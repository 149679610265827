import {GridColumnVisibilityModel} from '@mui/x-data-grid-premium';

export const visitsAndClicks = (estimatedSpends: boolean, advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
	const showVisits = (advancedTracking && estimatedSpends && maximusLinks);
	return {visits: showVisits, clicks: true};
}

export const conditionalCTR = (advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
  const showCTR = advancedTracking && maximusLinks;
  return {ctr: showCTR};
}

export const conditionalPresellCTR = (advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
  const showCTR = advancedTracking && maximusLinks;
  return {presell_ctr: showCTR};
}
