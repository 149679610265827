import {ChangeEvent, ComponentProps} from "react";
import {FormControl, SxProps, TextField} from "@mui/material";

interface SelectProps {
	options: Array<{ value: string, label: string }>,
	onChange: (event: ChangeEvent<HTMLInputElement>) => void,
	label?: string,
	value: string,
	name: string,
	error?: boolean,
	helperText?: string,
	disabled?: boolean,
	size?: SxProps,
	inputSize?: ComponentProps<typeof TextField>['size'],
}

export default function Select(
	{
		options,
		onChange,
		label = '',
		value,
		name,
		error = false,
		helperText = '',
		disabled = false,
		size = {m: '12px', width: 'calc(33.33% - 24px)'},
		inputSize,
	}: SelectProps
) {
	return (
		<FormControl sx={size} variant="filled">
			<TextField
				id={`select-${name}`}
				select
				onChange={onChange}
				label={label}
				value={value}
				name={name}
				error={error}
				disabled={disabled}
				SelectProps={{
					native: true,
				}}
				helperText={helperText}
				variant="filled"
				size={inputSize}
			>
				{options.map(({ value, label }) => (
					<option key={`${value}_${label}`} value={value}>
						{label}
					</option>
				))}
			</TextField>
		</FormControl>
	)
}
