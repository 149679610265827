import { useEffect, useState } from 'react';
import DateRange from "../../../Filters/DateRange";
import WeekdayFilter from "../../../Filters/WeekdayFilter";
import SectionTitle from "./Components/SectionTitle";
import SectionItem from "./Components/SectionItem";
import Filter from "../../../../Icons/Filter";
import { useAppDispatch } from '../../../../hooks.ts';
import { getFiltersFromLocalStorageState } from '../../ManageCampaigns/Filters';

export default function FilterSection() {
	const dispatch = useAppDispatch();
	const [collapsed, setCollapsed] = useState<boolean>(false);

	useEffect(() => {
		getFiltersFromLocalStorageState(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={`menu-section ${collapsed ? 'menu-section-collapsed' : ''}`}
		>
			<SectionTitle
				title="Filters"
				icon={<Filter className="menu-section-icon"/>}
				onClick={() => {setCollapsed(!collapsed)}}
				collapsed={collapsed}
			/>
			<SectionItem>
				<DateRange/>
			</SectionItem>
			<SectionItem>
				<WeekdayFilter/>
			</SectionItem>
		</div>
	)
}
