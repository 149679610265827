import SectionTitle from "../../Components/SectionTitle";
import AddSettings from "./AddSetting";
import SettingsTable from "./SettingsTable";
import Toggle from '../../../../FormComponents/Toggle.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeNonField } from '@/Redux/Slices/addCampaign.ts';
import { isRevContent } from '@/utils/network-utils.ts';

export default function DayParting({campaignDetails = false}) {
  const { maximusDayParting } = useAppSelector((state) => state.addCampaign);
	const { network } = useAppSelector((state) => state.campaignDetails);
	const dispatch = useAppDispatch();

  return (
		<>
      <SectionTitle
        title="Create Day Parting Settings"
        EndAddition={!isRevContent(network) ?
          <Toggle
            name="maximusDayParting"
            label="Maximus Dayparting"
            trackColor="#4285F4"
            value={maximusDayParting}
            onChange={(checked) => dispatch(changeNonField({ key: 'maximusDayParting', value: checked }))}
          /> : undefined
        }
      />
			<AddSettings campaignDetails={campaignDetails}/>
			<SectionTitle title="Day Parting Calendar"/>
			<SettingsTable/>
		</>
	);
}
