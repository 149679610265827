import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, createSelectorHook, useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from './Redux/reduxStore';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = createSelectorHook()
export const usePrevious = (value: any) => {
  const ref= useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
