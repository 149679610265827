import { createSlice } from "@reduxjs/toolkit";

export interface FiltersState {
	user: string;
	weekDay: string;
	status: string;
	archiveStatus: string;
	network: string;
	startDate: string;
	endDate: string;
	dateRange: string;
	refreshedAt: string;
	isAgency: boolean,
	refreshAllData: boolean,
}

const initialState: FiltersState = {
	user: '-1',
	weekDay: '-1',
	status: '1',
	archiveStatus: '-1',
	network: '-1',
	startDate: '',
	endDate: '',
	dateRange: 'today',
	refreshedAt: '',
	refreshAllData: false,
	isAgency: false,
};

const saveFilterToLocalStorage = (key: string, value) => {
	const filterLSKey = `${localStorage.getItem('userID')}_filters`;
	let storedFilters = localStorage.getItem(filterLSKey) || '{}';
	storedFilters = JSON.parse(storedFilters);
	storedFilters = {
		...storedFilters,
		[key]: value,
	};
	localStorage.setItem(
		filterLSKey,
		JSON.stringify(storedFilters),
	);
};

const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		changeFilter: (state, action = {payload: { filter: '', value: ''}}) => {
			saveFilterToLocalStorage(action.payload.filter, action.payload.value);
			state[action.payload.filter] = action.payload.value;
		},
		changeDate: (state, action = {payload: { startDate: new Date(), endDate: new Date(), dateRange: '' }}) => {
			state.startDate = action.payload.startDate;
			state.endDate = action.payload.endDate;
			state.dateRange = action.payload.dateRange;
			state.refreshedAt = new Date().toJSON();
		},
		updateRefreshedAt: (state) => {
			state.refreshedAt = new Date().toJSON();
		},
		refreshAll: (state, action) => {
			state.refreshAllData = action.payload;
		},
	}
});

export const { changeFilter, changeDate, updateRefreshedAt, refreshAll } = filtersSlice.actions;

export default filtersSlice.reducer;
