import Input from '../../../AddCampaign/Fields/Input.tsx';
import Select from '../../../AddCampaign/Fields/Select.tsx';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import '../../../AddCampaign/addCampaign.css';
import {callToActionOptions} from "../../../AddCampaign/Pages/Ads/CallToActionOptions.tsx";
import { isRevContent } from '../../../../../utils/network-utils.ts';

export default function EditAdData() {
  const dispatch = useAppDispatch();
  const { editedAdData, network } = useAppSelector((state) => state.campaignDetails);

  return (
    <>
      <div className="add-campaign-field-row">
        <Input
          onChange={({ target: { value } }) => dispatch(changeCampaignDetails({ key: 'editedAdData', value: {
            ...editedAdData,
            headline: value,
          }}))}
          label="Headline"
          value={editedAdData.headline}
          size={{ m: '12px', width: 'calc(100%)' }}
          name="edit-image-headline"
          multiline
        />
        {!isRevContent(network) && <Select
          onChange={({ target: { value } }) => dispatch(changeCampaignDetails({ key: 'editedAdData', value: {
            ...editedAdData,
            cta: value,
          }}))}
          label="Call To Action"
          value={editedAdData.cta}
          options={callToActionOptions}
          size={{ m: '12px', width: 'clamp(160px, 100%, 200px)' }}
          name="edit-image-cta"
        />}
      </div>
      <div className="add-campaign-field-row">
        <Input
          onChange={({ target: { value } }) => dispatch(changeCampaignDetails({ key: 'editedAdData', value: {
            ...editedAdData,
            description: value,
          }}))}
          label="Description"
          value={editedAdData.description}
          size={{ m: '12px', width: 'calc(100%)' }}
          name="edit-image-description"
					helperText="Including a description is highly recommended as it can drastically improve your ad's performance"
          multiline
          />
      </div>
    </>
  );
}
