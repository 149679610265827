import {GridAggregationFunction} from "@mui/x-data-grid-premium";

export const sum : GridAggregationFunction<string, number | null> =
	{
		apply: ({values}) => {
			if (values.length === 0) {
				return '';
			}


			return values.reduce((partialSum, a) => partialSum + +a, 0);
		},
		label: '',
		columnTypes: ['string', 'number'],
	};