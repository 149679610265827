import {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import PageLoader from '../../Loaders/PageLoader.tsx';

export default function Login() {
	const {loginWithRedirect} = useAuth0();
	useEffect(() => {
		loginWithRedirect();
	})
	return (
		<div className="h-screen w-screen bg-black">
			<PageLoader />
		</div>
	)
}
