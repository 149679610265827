import Toggle from '@/Components/FormComponents/Toggle'
import { updateMainStore } from '@/Redux/Slices/main'
import { Button, Dialog } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks.ts'
import networkRequest from '../utils/networkRequest.ts'

const MINUTES = 60 * 1_000

export default function VersionChecker() {
	const dispatch = useAppDispatch()
	const lastStoredVersion = useAppSelector(state => state.main.version)
	const interval = useRef<ReturnType<typeof setTimeout>>()
	const [open, setOpen] = useState(false)
	const [enableCheck, setEnableCheck] = useState(true)

	useEffect(() => {
		const checkAuth = async () => {
			if (!enableCheck) return
			networkRequest('api/version/check', {}, 'GET')
				.then(response => response.json())
				.then(response => {
					if (response?.version) {
						if (!lastStoredVersion) {
							dispatch(updateMainStore({ key: 'version', value: response.version }))
						}
						if (!!lastStoredVersion && response.version !== lastStoredVersion) {
							setOpen(true)
						}
					}
				})
		}

		if (!lastStoredVersion) checkAuth()
		if (interval.current) clearInterval(interval.current)
		interval.current = setInterval(() => checkAuth(), 10 * MINUTES)
	}, [dispatch, enableCheck, lastStoredVersion, open])

	return (
		<Dialog open={open} maxWidth={false}>
			<section className="flex w-[800px] flex-col p-4 text-center">
				<h1 className="mb-3 text-3xl font-semibold">Maximus Update Available</h1>
				<p>
					A new version of Maximus is now available. <br />
					Please refresh your browser to access the latest features.
				</p>
				<footer className="mt-8 flex gap-6">
					<Toggle
						value={!enableCheck}
						label="Do not ask me again during this session"
						labelPlacement="end"
						onChange={() => setEnableCheck(!enableCheck)}
					/>
					<Button className="ml-auto" variant="text" onClick={() => setOpen(false)}>
						I'll do it later
					</Button>
					<Button variant="contained" onClick={() => window.location.reload()}>
						Reload now
					</Button>
				</footer>
			</section>
		</Dialog>
	)
}
