import Glance from '@/Components/Glance'
import MaximusLoader from '@/Components/Loaders/MaximusLoader'
import { useAppSelector } from '@/hooks'
import { useMemo } from 'react'
import Filters from '../Filters'

const emptyData = {
	clicks: 0,
	conversions: 0,
	revenue: 0,
	spend: 0,
	profit: 0,
	profit_percent: 0,
}

const dataTypes = {
	clicks: 'number',
	conversions: 'number',
	revenue: 'currency',
	spend: 'currency',
	profit: 'currency',
	profit_percent: 'percent',
}

const dataNames = {
	clicks: 'Clicks',
	conversions: 'Conversions',
	revenue: 'Revenue',
	spend: 'Spend',
	profit: 'Profit',
	profit_percent: 'Profit %',
}

const dataKeys = ['clicks', 'conversions', 'revenue', 'spend', 'profit', 'profit_percent']

export default function ManageGlance() {
	const tableRows: Record<string, any>[] = useAppSelector(state => state.main.tableRows)

	const glanceData = useMemo(() => {
		if (!tableRows?.length) return emptyData

		const sum = tableRows.reduce((acc, row) => {
			dataKeys.forEach(key => {
				acc[key] = (acc[key] || 0) + Number(row[key])
			})
			return acc
		}, {}) as typeof emptyData

		if (sum.profit && sum.revenue) {
			const ratio = (sum.profit / sum.revenue)
			sum.profit_percent = Math.max(-1, ratio) * 100
		} else if (sum.profit < 0 && sum.revenue === 0) {
			sum.profit_percent = -100
		} else {
			sum.profit_percent = 0
		}

		return sum
	}, [tableRows])

	return (
		<div className="flex w-full flex-col items-center justify-center rounded-md border border-solid border-black/80 bg-white shadow-md">
			<Filters />
			{tableRows?.length > 0
				? <Glance data={glanceData} dataTypes={dataTypes} dataNames={dataNames} />
				: <MaximusLoader size={18} />}
		</div>
	)
}
