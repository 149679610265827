import { useState } from 'react';
import {CircularProgress, IconButton, Tooltip} from '@mui/material';
import networkRequest from '../../../../../../utils/networkRequest.ts';
import { openSnackbar, updateMainStore } from '@/Redux/Slices/main.ts';
import type { AppDispatch } from '@/Redux/reduxStore.ts';
import { editTableRows } from '@/utils/tables-utils.tsx';
import { storeData } from './Sites';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import Lock from '@/Icons/Lock.tsx';
import LockOpen from '@/Icons/LockOpen.tsx';

export default function SiteBlockCell(params: never, {
	campaignID,
	network,
	tableRows,
	blockingSubmitting,
}: storeData, dispatch: AppDispatch, isWidget = false) {
	const [submitting, setSubmitting] = useState<boolean>(false);

	const updateBlockStatus = () => {
		setSubmitting(true);
		dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: true }));
		const blocked = !params.row.blocked;
		const idFieldName = isWidget ? 'widgetID': 'siteID';
		networkRequest('api/v1/campaign/siteBlock/update', {
			campaignID,
			network,
			[idFieldName]: params.row[idFieldName],
			blocked,
		}, 'POST')
			.then((response) => response.json())
			.then((response) => {
					if (response && response.success) {
						dispatch(updateMainStore({
							key: 'tableRows',
							value: editTableRows(tableRows, params.row[idFieldName], 'blocked', blocked, idFieldName)
						}));
						dispatch(openSnackbar({
							children: `${params.row[isWidget ? 'widgetName' : 'siteName']} was ${blocked ? 'blocked' : 'unblocked'} successfully.`,
							severity: 'success',
						}));
					} else {
						dispatch(openSnackbar({
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error',
						}));
					}
					setSubmitting(false);
					dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: false }));
				}
			)
			.catch((error) => {
				dispatch(openSnackbar({
					children: error || 'Something went wrong. Please try again.',
					severity: 'error',
				}));
				setSubmitting(false);
				dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: false }));
			});
	}

	const blockedText = params.row.blocked ? 'Blocked' : 'Unblocked';

	return params.value === undefined ? '' : (
		<Tooltip title={blockedText}>
			<IconButton
				color={params.row.blocked ? 'error' : 'inherit'}
				onClick={updateBlockStatus}
				disabled={submitting || blockingSubmitting}
				data-testid={blockedText}
			>
				{submitting ? (
					<CircularProgress size={25} color="inherit"/>
				) : (
					params.row.blocked ? <Lock className="fill-red-600" /> : <LockOpen />
				)}
			</IconButton>
		</Tooltip>
	);
}
