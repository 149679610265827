import { useEffect } from 'react';
import Input from '../../../AddCampaign/Fields/Input.tsx';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import Select from '../../../AddCampaign/Fields/Select.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';

import '../../CampaignDetails.css';

export default function ConversionCap() {
  const dispatch = useAppDispatch();
  const { conversionCap, conversionCapTimeframe, campaignID, network, settingsDialogLoading } = useAppSelector((state) => state.campaignDetails);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
    networkRequest('api/v1/campaign/conversionCap/pull', {
      campaignID,
      network,
    }, 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object') {
          setSettings(response);
        }
        dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
      }
    )
    .catch(() => {
      dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
    });
  };

  const setSettings = (response) => {
    dispatch(changeCampaignDetails({ key: 'conversionCap', value: response.conversionCapThreshold || 0 }));
    dispatch(changeCampaignDetails({ key: 'conversionCapTimeframe', value: response.conversionCapTimeframe || '1' }));
  };

  return (
    <>
      {settingsDialogLoading ? <FormLoader /> :
      <div className="add-campaign-field-row conversion-cap-row">
        Pause this campaign if it receives
        <Input
          onChange={({ target: { value} }) => dispatch(changeCampaignDetails({ key: 'conversionCap', value }))}
          value={conversionCap}
          numeric
          name="conversionCap"
          size={{ m: '5px', width: '100px' }}
        />
        conversions in a
        <Select
          onChange={({ target: { value}}) => dispatch(changeCampaignDetails({ key: 'conversionCapTimeframe', value }))}
          value={conversionCapTimeframe}
          name="conversionCapTimeframe"
          size={{ m: '5px', width: '150px'}}
          options={[
            { label: 'day', value: '1' },
            { label: 'week', value: '2' },
            { label: 'month', value: '3' },
          ]}
        />
      </div>}
    </>
  );
}
