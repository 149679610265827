import { DependencyList, EffectCallback, useCallback, useEffect } from 'react'

/**
 * Holds execution of given effect until the delay has passed and no other deps is triggered
 *
 * @param effect Effect callback to be executed
 * @param deps Dependencies that trigger the effect
 * @param delay Delay in milliseconds before the effect is executed
 */
export const useDebouncedEffect = (effect: EffectCallback, deps: DependencyList, delay: number) => {
	const callback = useCallback(effect, deps)

	useEffect(() => {
		const handler = setTimeout(() => {
			callback()
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [callback, delay])
}
