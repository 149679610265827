import {ReactElement, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import FormLoader from '../../Components/Loaders/FormLoader.tsx';

export interface DialogRawProps {
	children: ReactElement,
	title: string,
	dialogOpen: boolean,
	loading?: boolean,
	useConfirm?: () => void,
	useCancel: () => void,
  cancelText?: string,
  confirmText?: string,
}

export default function DialogComponent(
	{
		children,
		title,
		useConfirm,
		useCancel,
		dialogOpen = false,
		loading = false,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
	}: DialogRawProps) {
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleClose = () => {
		// eslint-disable-next-line
		useCancel();
	}

	return (
		<Dialog
			sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: '90vh'}}}
			maxWidth="md"
			open={dialogOpen}
		>
			<DialogTitle className="dialogTitle">
				{title}
			</DialogTitle>
			<DialogContent dividers>
				{children}
			</DialogContent>
			<DialogActions className="settingsDialogActions">
				<Button autoFocus onClick={handleClose}>
					{cancelText}
				</Button>
				{useConfirm &&
          <Button
            className="saveButton"
            disabled={submitting || loading}
            onClick={
							() => {
								setSubmitting(true);
								// eslint-disable-next-line
								useConfirm().finally(() => setSubmitting(false));
							}
						}
          >
						{submitting ? <FormLoader button/> : confirmText}
          </Button>}
			</DialogActions>
		</Dialog>
	)
}