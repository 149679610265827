import {ChangeEvent} from "react";
import {Autocomplete, FormControl, TextField} from "@mui/material";

interface SelectProps {
	options: Array<{ value: string, label: string }>,
	onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void,
	label?: string,
	value: string,
	name: string,
	error?: boolean,
	helperText?: string,
	disabled?: boolean,
	size?: {m: string, width: string},
}

export default function AutoComplete(
	{
		options,
		onChange,
		label = '',
		name,
		error = false,
		helperText = '',
		disabled = false,
		size = {m: '12px', width: 'calc(33.33% - 24px)'},
	}: SelectProps
) {
	return (
		<FormControl sx={size} variant="filled">
			<Autocomplete
				id={`select-${name}`}
				freeSolo
				onChange={onChange}
				options={options.map((option) => option.label)}
				disabled={disabled}
				renderInput={(params) => <TextField {...params} label={label} name={name} error={error} helperText={helperText} variant="filled" />}
			/>
		</FormControl>
	)
}
