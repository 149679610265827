import Input from "../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {type Ad, changeNonField} from "../../../../../Redux/Slices/addCampaign";
import Select from "../../Fields/Select";
import {callToActionOptions} from "./CallToActionOptions.tsx";
import { isRevContent } from '../../../../../utils/network-utils.ts';
import { useMemo } from 'react';
import Close from '@/Icons/Close';
import { tokenMap } from '@/utils/token-utils';

export default function Ad({ad, index}: {ad: Ad, index: number}) {
	const dispatch = useAppDispatch();
	const {ads, errors} = useAppSelector(({addCampaign}) => addCampaign);
	const { network } = useAppSelector(state => state.campaignDetails)
	const onChange = (key, value: string) => {
		const newAds = JSON.parse(JSON.stringify(ads));
		newAds[index][key] = value;
		dispatch(changeNonField({key: 'ads', value: newAds}));
		const newErrors = structuredClone(errors);
		if (newErrors.fields[`${key}-${ad.uuid}`]) {
			delete newErrors.fields[`${key}-${ad.uuid}`];
		}
		dispatch(changeNonField({ key: 'errors', value: newErrors }));
	}
	const onDelete = () => {
		const newAds = JSON.parse(JSON.stringify(ads));
		newAds.splice(index, 1);
		dispatch(changeNonField({key: 'ads', value: newAds}));
	}

	const Thumbnail = useMemo(
		() => (ad.fileType === 'video' ? 'video' : 'img'),
		[ad.fileType]
	)

	return (
		<div className="relative flex h-fit w-full flex-col rounded-md border border-black/20 shadow-md p-3">
			<div
				className="absolute -top-3 right-[-13px] flex cursor-pointer items-center justify-center rounded-full bg-red-500 p-0.5 shadow transition hover:bg-red-600"
				id={`display-ad-delete-${index}`}
				onClick={onDelete}
			>
				<Close className="size-5 fill-white" />
			</div>

			<Thumbnail
				autoPlay
				loop
				muted
				controls
				src={ad.image}
				alt="ad"
			/>

			<div className="display-ad-text-container">
				<div className="display-ad-text-edit">
					<Input
						onChange={({target: {value}}) => onChange('headline', value)}
						label="Headline"
						value={ad.headline}
						size={{width: '100%'}}
						inputSize='small'
						name={`headline-edit-${index}`}
						maxSize={tokenMap[network].maxLength.headline}
						error={!!errors.fields[`headline-${ad.uuid}`]}
						helperText={errors.fields[`headline-${ad.uuid}`]}
						multiline
					/>
				</div>
				<div className="display-ad-text-edit">
					<Input
						onChange={({target: {value}}) => onChange('description', value)}
						label="Description"
						value={ad.description}
						size={{width: '100%'}}
						inputSize='small'
						name={`description-edit-${index}`}
						maxSize={tokenMap[network].maxLength.description}
						error={!!errors.fields[`description-${ad.uuid}`]}
						helperText={errors.fields[`description-${ad.uuid}`]}
						multiline
					/>
				</div>
				{!isRevContent(network) && ad.fileType !== 'video' &&
					<div className="display-ad-text-edit">
						<Select
							onChange={({target: {value}}) => onChange('callToAction', value)}
							label="Call To Action"
							value={ad.callToAction}
							name={`add-campaign-cta-edit-${index}`}
							options={callToActionOptions}
							size={{width: '100%'}}
							inputSize='small'
						/>
					</div>}
			</div>
		</div>
	)
}
