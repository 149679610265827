import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';

export const useSubmitReassignCampaign = () => {
  const dispatch = useAppDispatch();
  const { selectedUserID, selectedGroupID, campaignID, network } = useAppSelector((state) => state.campaignDetails);
  const { userID } = useAppSelector((state) => state.main);

  return async () =>  {
    if (selectedUserID === '0' || selectedGroupID === '0') {
      dispatch(openSnackbar({
        children: 'Please select user and group from the list to reassign this campaign',
        severity: 'error',
      }));
      return;
    }
    await networkRequest('api/v1/campaign/userGroup/update', {
      campaignID,
      network,
      userID: selectedUserID,
      groupID: selectedGroupID,
      user_id: userID,
    }, 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object' && response.success) {
          dispatch({ type: 'campaignDetails/closeDialog' });
        } else {
          dispatch(openSnackbar({
            children: response.message || 'Something went wrong. Please try again.',
            severity: 'error',
          }));
        }
      }
    );
  }
}
