import { LinearProgress } from '@mui/material'

export default function PageLoader() {
	return (
		<div className="flex min-h-[50vh] items-center justify-center" data-testid="page-loader">
			<LinearProgress
				className="w-1/2 min-w-48"
				classes={{ colorPrimary: 'bg-[#4285F466]', bar: 'bg-[#4285F4]' }}
			/>
		</div>
	)
}
