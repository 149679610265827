export default function SectionTitle({EndAddition, title}: { EndAddition?: JSX.Element, title: string }) {
	return (
		<div className="add-campaign-section-title">
			<div>
				{title}
			</div>
			{EndAddition ? EndAddition : ''}
		</div>
	)
}
