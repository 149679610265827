import { Box, Chip, FilledInput, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo } from 'react';

interface SelectProps {
	options: Array<{ value: string, label: string }>,
	onChange: (event: SelectChangeEvent<string[]>) => void,
	label?: string,
	value: string[],
	name: string,
	error?: boolean,
	helperText?: string,
	disabled?: boolean,
	size?: { m: string, width: string },
	/** When `true`, creates a new `All` option at the top that automatically maps to all available options */
	aggregateAllOptions?: boolean,
}

export default function ChipSelect(
	{
		options,
		onChange,
		label = '',
		value,
		name,
		error = false,
		helperText = '',
		disabled = false,
		size = {m: '12px', width: 'calc(33.33% - 24px)'},
		aggregateAllOptions = false,
	}: SelectProps
) {
	const parsedOptions = useMemo(() => {
		if (aggregateAllOptions) {
			return [{ value: 'all', label: 'All' }, ...options]
		}
		return options
	}, [aggregateAllOptions, options])

	const chipLabelFromOptions = (selected) => {
		const option = parsedOptions.find((option) => option.value === selected)
		return option?.label || ''
	}

	return (
		<FormControl error={error} sx={size} variant="filled">
			<InputLabel id="demo-multiple-chip-label">
				{label}
			</InputLabel>
			<Select
				onChange={e => {
					if (aggregateAllOptions) {
						if (e.target.value.at(-1) === 'all') {
							e.target.value = options.map(option => option.value)
						} else {
							e.target.value = (e.target.value as string[]).filter(value => value !== 'all')
						}
					}
					onChange(e)
				}}
				value={aggregateAllOptions && value.length === options.length ? ['all'] : value}
				name={name}
				multiple
				disabled={disabled}
				input={
					<FilledInput
						id={`select-${name}`}
						label={label}
						name={name}
						variant="filled"
					/>
				}
				renderValue={(selected) => (
					<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.75}}>
						{selected.map((value) => (
							<Chip sx={{ height: 'unset' }} key={value} label={chipLabelFromOptions(value)}/>
						))}
					</Box>
				)}
			>
				{parsedOptions.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}
