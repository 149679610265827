import { isRevContent, Network } from '../../../../utils/network-utils'

// TODO: add repeat options from Details components

export const countryTargetingOptions = [
	{ value: 'US', label: 'United States' },
	{ value: 'AU', label: 'Australia' },
	{ value: 'AT', label: 'Austria' },
	{ value: 'BH', label: 'Bahrain' },
	{ value: 'BE', label: 'Belgium' },
	{ value: 'BR', label: 'Brazil' },
	{ value: 'CA', label: 'Canada' },
	{ value: 'CN', label: 'China' },
	{ value: 'DK', label: 'Denmark' },
	{ value: 'FR', label: 'France' },
	{ value: 'DE', label: 'Germany' },
	{ value: 'IN', label: 'India' },
	{ value: 'IE', label: 'Ireland' },
	{ value: 'IL', label: 'Israel' },
	{ value: 'IT', label: 'Italy' },
	{ value: 'JP', label: 'Japan' },
	{ value: 'KW', label: 'Kuwait' },
	{ value: 'MX', label: 'Mexico' },
	{ value: 'NL', label: 'Netherlands' },
	{ value: 'NZ', label: 'New Zealand' },
	{ value: 'NO', label: 'Norway' },
	{ value: 'PL', label: 'Poland' },
	{ value: 'PT', label: 'Portugal' },
	{ value: 'QA', label: 'Qatar' },
	{ value: 'SA', label: 'Saudi Arabia' },
	{ value: 'SG', label: 'Singapore' },
	{ value: 'SK', label: 'Slovakia' },
	{ value: 'ZA', label: 'South Africa' },
	{ value: 'ES', label: 'Spain' },
	{ value: 'SE', label: 'Sweden' },
	{ value: 'TW', label: 'Taiwan, Province of China' },
	{ value: 'AE', label: 'United Arab Emirates' },
	{ value: 'GB', label: 'United Kingdom' },
]

export const getNetworkLabel = (network: Network) => {
	if (isRevContent(network)) return 'Widget'
	return 'Site'
}
