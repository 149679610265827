import { Button } from '@mui/material';
import {
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarColumnsButton,
} from '@mui/x-data-grid-premium';
import {columnsStorageName} from './Helpers/ColumnReorderHelper';
import Close from "../../Icons/Close";

export const CustomToolBar = (hideQuick = false, hideExport = false, tableName: string, dateRangeTitle = '', forceRefreshedCont) => (
	<GridToolbarContainer className="relative justify-end border-b border-black/10 p-3 text-black">
		{tableName && (
			<span className="mr-auto text-xl font-semibold text-black/50">
				{tableName}
			</span>
		)}

		{!hideQuick && <GridToolbarQuickFilter />}
		<GridToolbarFilterButton />
		<GridToolbarDensitySelector />
		<GridToolbarColumnsButton />
		{!hideExport &&
      <GridToolbarExport csvOptions={{fileName: `${tableName} Report ${dateRangeTitle}`}}/>}
		{localStorage.getItem(columnsStorageName(tableName)) &&
      <Button
				size='small'
        onClick={() => {
					forceRefreshedCont();
					localStorage.removeItem(columnsStorageName(tableName));
				}}
      >
				<Close className="mr-1 h-5 w-5 fill-[#1976d2]" />
				Reset Column Order
      </Button>
		}
	</GridToolbarContainer>
);
