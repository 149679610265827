import { useAppSelector } from '@/hooks'
import { Network } from '@/utils/network-utils'
import { CropPresetOption, getEditorDefaults, OptionGroup } from '@pqina/pintura'
import { PinturaEditorModal } from '@pqina/react-pintura'
import { ComponentProps } from 'react'

import '@pqina/pintura/pintura.css'

type EditorParams = {
	aspectRatio?: number
	aspectRatioOptions?: OptionGroup[] | CropPresetOption[]
}

const settingsMap: Partial<Record<Network, EditorParams>> & Record<'default', EditorParams> = {
	[Network.Taboola]: {
		aspectRatio: 16 / 9,
		aspectRatioOptions: [
			['Crop Aspect Ratios', [
				[16 / 9, '16:9'],
				[4 / 3, '4:3'],
				[1 / 1, '1:1'],
			]],
			['Recommended Sizes', [
				[[1200, 674], '1200x674px (16:9)'],
			]],
		],
	},
	[Network.RevContent]: {
		aspectRatio: 4 / 3,
		aspectRatioOptions: [
			['Crop Aspect Ratios', [
				[4 / 3, '4:3'],
			]],
			['Recommended Sizes', [
				[[1000, 750], '1000x750px (4:3)'],
			]],
		],
	},
	default: {
		aspectRatioOptions: [
			[undefined, 'Custom'],
			[16 / 9, '16:9'],
			[4 / 3, '4:3'],
			[1 / 1, '1:1'],
		],
	},
}

type ImageEditorProps = ComponentProps<typeof PinturaEditorModal>

export default function ImageEditor({ ...props }: ImageEditorProps) {
	const { network } = useAppSelector(state => state.campaignDetails)
	const settings = settingsMap[network] || settingsMap.default

	return (
		<PinturaEditorModal
			{...getEditorDefaults()}
			{...props}
			imageCropAspectRatio={settings.aspectRatio}
			cropSelectPresetOptions={settings.aspectRatioOptions}
			cropEnableInfoIndicator
		/>
	)
}

/**
 * TODO:
 * - Remove legacy (v1) component
 */
