import {FormControl, NativeSelect} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {changeFilter} from "../../Redux/Slices/filters";

export default function WeekdayFilter() {
	const weekDay = useAppSelector((state) => state.filters.weekDay);
	const dispatch = useAppDispatch();
	const handleChange = ({ target: { value }}) => {
		dispatch(changeFilter({ filter: 'weekDay', value }));
	}
	return (
		<FormControl className="select-filter-form-control" variant="standard" sx={{ m: 1, minWidth: 120 }}>
			<NativeSelect
				id="weekday-filter"
				value={weekDay}
				onChange={handleChange}
				label="Weekday"
			>
				<option value="-1">All Days</option>
				<option value="week_days">Week Days</option>
				<option value="week_ends">Week Ends</option>
			</NativeSelect>
		</FormControl>
	)
}
