import campaignDetails from './campaignDetails';
import filters from './filters';
import addCampaign from './addCampaign';
import main from './main';

export default {
  main,
  campaignDetails,
  addCampaign,
  filters,
};
