import { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import networkRequest from '../../../../../../utils/networkRequest.ts';
import { openSnackbar, updateMainStore } from '@/Redux/Slices/main.ts';
import type { AppDispatch } from '@/Redux/reduxStore.ts';
import { editTableRows } from '@/utils/tables-utils.tsx';
import type { storeData } from './States';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import Lock from '@/Icons/Lock.tsx';
import LockOpen from '@/Icons/LockOpen.tsx';

export default function StateBlockCell(params: never, {
  campaignID,
  network,
  tableRows,
  blockingSubmitting,
}: storeData, dispatch: AppDispatch) {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const updateBlockStatus = () => {
    setSubmitting(true);
    dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: true }));
    const blocked = !params.row.blocked;
    networkRequest('api/v1/campaign/stateBlock/update', {
      campaignID,
      network,
      state: params.row.code,
      blocked,
    }, 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && response.success) {
          dispatch(updateMainStore({
            key: 'tableRows',
            value: editTableRows(tableRows, params.row.code, 'blocked', blocked, 'code')
          }));
          dispatch(openSnackbar({
            children: `${params.row.state} was ${blocked ? 'blocked' : 'unblocked'} successfully.`,
            severity: 'success',
          }));
        } else {
          dispatch(openSnackbar({
            children: response.message || 'Something went wrong. Please try again.',
            severity: 'error',
          }));
        }
        setSubmitting(false);
        dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: false }));
      }
    )
    .catch((error) => {
      dispatch(openSnackbar({
        children: error || 'Something went wrong. Please try again.',
        severity: 'error',
      }));
      setSubmitting(false);
      dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: false }));
    });
  }

  return params.value === undefined ? '' : (
		<Tooltip title={params.row.blocked ? 'Blocked' : 'Unblocked'}>
			<IconButton
				color={params.row.blocked ? 'red' : 'inherit'}
        onClick={updateBlockStatus}
        disabled={submitting || blockingSubmitting}
      >
				{submitting ? (
					<CircularProgress size={25} color="inherit"/>
				) : (
					params.row.blocked ? <Lock className="fill-red-600" /> : <LockOpen />
				)}
			</IconButton>
		</Tooltip>
  );
}
