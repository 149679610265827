import {styled} from "@mui/system";
import { Switch, FormControlLabel, Tooltip } from '@mui/material';


const Android12Switch = styled(Switch)(({trackcolor}) => ({
	padding: 8,
	margin: '0!important',
	'& .MuiSwitch-switchBase': {
		'&.Mui-checked': {
			color: '#fff',
			'& + .MuiSwitch-track': {
				borderRadius: 12,
				backgroundColor: trackcolor,
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled': {
				color: '#f5f5f5',
				'& + .MuiSwitch-track': {
					opacity: 0.4,
				},
			},
		},
	},
	'& .MuiSwitch-track': {
		borderRadius: 12,
		'&:before, &:after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
	},
}));


export default function Toggle(
	{
		className,
		name = '',
		label = '',
		labelPlacement = 'start',
		trackColor = 'rgb(34, 140, 34)',
		onChange = () => null,
		value,
		disabled = false,
		tooltipTitle = '',
	} : {
		className?: string,
		name?: string,
		label?: string,
		labelPlacement?: 'start' | 'end' | 'top' | 'bottom',
		value: boolean,
		onChange?: (checked: boolean) => void,
		trackColor?: string,
		disabled?: boolean,
		tooltipTitle?: string,
	}
) {
	return (
		<Tooltip title={tooltipTitle}>
			<FormControlLabel
				control={<Android12Switch sx={{m: 1}} checked={Boolean(value)} disabled={disabled} trackcolor={trackColor} id={`toggle-${name}`}/>}
				className={className}
				label={label}
				name={name}
				checked={Boolean(value)}
				labelPlacement={labelPlacement}
				onChange={({target: {checked}}) => onChange(checked)}
			/>
		</Tooltip>
	)
}
