import {useAppDispatch, useAppSelector} from "@/hooks";
import {changeFilter} from "@/Redux/Slices/filters";
import networkRequest from "@/utils/networkRequest";
import {Autocomplete, FormControl, TextField, Option} from "@mui/material";
import {useEffect, useMemo, useState} from "react";

type UserOption = { value: string; label: string, id: string }

export default function UserFilter() {
	const user = useAppSelector((state) => state.filters.user);
	const {userID} = useAppSelector((state) => state.main);
	const [users, setUsers] = useState<UserOption[]>([{value: '-1', label: 'All Users', id: '-1'}]);
	const dispatch = useAppDispatch();

	useEffect(() => {
		pullUsers();
	}, []);

	function pullUsers() {
		networkRequest('api/v1/user/list/pull', {userID}, 'POST')
			.then((response) => response.json())
			.then((response) => {
					if (response && typeof response === 'object') {
						setUsers([
							...users,
							...response.map(item => ({value: item.user_id, label: item.name, id: item.user_id})),
						]);
					}
				}
			);
	}

	const handleChange = (_event: React.SyntheticEvent | null, option: UserOption | null) => {
		dispatch(changeFilter({filter: 'user', value: option?.value || '-1'}));
	};

	const selectedUser = useMemo(() => users.find(item => `${item.value}` === `${user}`), [user, users]);

	return (
		<FormControl className="select-filter-form-control autocomplete" variant="standard" sx={{m: 1, minWidth: 120}}>
			<Autocomplete
				data-testid="user-filter"
				options={users}
				value={selectedUser}
				onChange={handleChange}
				renderInput={(params) => <TextField {...params} variant="standard"/>}
				renderOption={(props, option, { selected }) => (
					<li {...props} key={option.id}>
						<span>{option.label}</span>
					</li>
				)}
			/>
		</FormControl>
	)
}
