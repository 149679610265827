import {validateCampaignSettings} from "./Validation/Details.tsx";
import {validateLinks} from "./Validation/Links.tsx";
import {validateFireAndForget} from "./Validation/FireAndForget.tsx";
import {validateAds} from "./Validation/Ads.tsx";
import {AddCampaignState} from "@/Redux/Slices/addCampaign.ts";
import { Network } from '../../../../utils/network-utils.ts';

export interface ValidateStepProps {
	fields: Record<string, string>,
}

export default function validateStep(currentStep: number, values: AddCampaignState, network: Network): ValidateStepProps {
  let errors: ValidateStepProps = {fields: {}};
  switch (currentStep) {
    case 1:
      errors = validateCampaignSettings(values.settings, network);
      break;
    case 2:
      errors = validateLinks({
        link: values.link,
        presellLinks: values.presellLinks,
        landingPageLinks: values.landingPageLinks,
        maximusLinks: values.settings.maximusLinks,
        advancedTracking: values.advancedTracking,
				campaignSettings: values.settings,
      });
      break;
    case 3:
      errors = validateFireAndForget(values.fireAndForget, (values.settings.bidStrategy === '1' || values.settings.bidStrategy === '2'));
      break;
    case 4:
      break;
    case 5:
      errors = validateAds(values, network);
      break;
    default:
      break;
  }
  return errors;
}
