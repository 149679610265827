import NoImage from "../../../../../Icons/NoImage";
import {useAppSelector} from "../../../../../hooks";
import Ad from "./Ad";

export default function DisplayAdsSection(): JSX.Element {
	const {ads} = useAppSelector(({addCampaign}) => addCampaign);
	return (
		<div>
			<div className="mb-2 mt-6 border-b font-semibold text-black/60">
				Ads
			</div>
			{ads.length === 0 ? (
				<div className="display-no-image">
					<NoImage className="add-campaign-no-image-icon" />
					<div className="display-no-image-text">
						No Ads To Display
					</div>
				</div>
			) : (
				<div className="grid w-full gap-6 p-3 auto-fill-80">
					{ads.map((ad, index) => <Ad key={ad.uuid} ad={ad} index={index} />)}
				</div>
			)}
		</div>
	);
}
