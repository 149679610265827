export const enum Network {
  Taboola = 1,
  Outbrain = 2,
  RevContent = 3,
}

const parseNetwork = (network: Network | string | number) => parseInt(`${network}`, 10);
export const isTaboola = (network: Network | string | number) => parseNetwork(network) === Network.Taboola;
export const isOutbrain = (network: Network | string | number) => parseNetwork(network) === Network.Outbrain;
export const isRevContent = (network: Network | string | number) => parseNetwork(network) === Network.RevContent;

export const getNetworkFromName = (networkName: string) => {
  const prefix = networkName?.toUpperCase().split('_')[0]
  if (prefix === 'T') return Network.Taboola
  if (prefix === 'O') return Network.Outbrain
  if (prefix === 'RC') return Network.RevContent
  return Network.Taboola
}