import { format } from 'date-fns'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks.ts'
import { changeCampaignDetails, closeDialog } from '../../../../Redux/Slices/campaignDetails.ts'
import networkRequest from '../../../../utils/networkRequest.ts'
import DialogComponent from '../../../../utils/UIElements/Dialog.tsx'
import FormLoader from '../../../Loaders/FormLoader.tsx'

export default function CampaignProcessingDialog({ title, onClose }: { title: string, onClose?: () => void }) {
	const dispatch = useAppDispatch()
	const { campaignID, networkCampaignID, network } = useAppSelector(state => state.campaignDetails)
	const filters = useAppSelector((state) => state.filters);

	useEffect(() => {
		if (!networkCampaignID) return
		dispatch(changeCampaignDetails({ key: 'networkCampaignID', value: '' }))
		networkRequest('api/v1/campaign/reporting/glance_v2', {
			startDate: format(new Date(filters.startDate), 'yyyy-MM-dd'),
			endDate: format(new Date(filters.endDate), 'yyyy-MM-dd'),
			weekDay: filters.weekDay,
			dateRange: filters.dateRange,
			campaignID,
			network,
		})
			.then(response => response.json())
			.then(data => {
				dispatch(changeCampaignDetails({ key: 'networkCampaignID', value: data.networkCampaignID || 'NA' }))
			})
			.catch(() => {
				dispatch(changeCampaignDetails({ key: 'networkCampaignID', value: 'NA' }))
			})
	}, [])

	return (
		<DialogComponent
			dialogOpen={true}
			title={title}
			loading={!networkCampaignID}
			useCancel={() => {
				onClose?.()
				dispatch(closeDialog())
			}}
		>
			{networkCampaignID
				? <p>This campaign is still being processed by the network. Please try again in a few minutes.</p>
				: <FormLoader />}
		</DialogComponent>
	)
}
