import {GridColDef} from '@mui/x-data-grid-premium';
import {conditionalProfitClass, formatCurrency, formatNumber, formatPercent} from "./formatNumbers";
import {CurrencyComparator} from "./tables-utils.tsx";
import {Tooltip} from "@mui/material";
import Help from "@/Icons/Help.tsx";

const columns = [
	{
		field: 'profit_percent',
		headerName: 'Profit %',
		width: 100,
		sortComparator: CurrencyComparator,
		type: 'number',
		renderCell: (params) => (
			<span className={conditionalProfitClass('profit_percent', params.value)}>
				{formatPercent(params.value)}
			</span>
		),
	},
	{
		field: 'profit',
		headerName: 'Profit',
		width: 100,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => (
			<span className={conditionalProfitClass('profit', params.value)}>
				{formatCurrency(params.value)}
			</span>
		),
	},
	{
		field: 'epc',
		headerName: 'EPC',
		width: 80,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'cpc',
		headerName: 'CPC',
		width: 80,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'cpa',
		headerName: 'CPA',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'cpm',
		headerName: 'CPM',
		width: 120,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'rpm',
		headerName: 'RPM',
		width: 120,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'roas',
		headerName: 'ROAS',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatPercent(params.value),
	},
	{
		field: 'average_cpc',
		headerName: 'Avg. CPC',
		width: 100,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'revenue',
		headerName: 'Revenue',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'spend',
		headerName: 'Spend',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatCurrency(params.value),
	},
	{
		field: 'vctr',
		headerName: 'vCTR',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatPercent(params.value),
	},
	{
		field: 'ctr',
		headerName: 'CTR',
		width: 100,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatPercent(params.value),
	},
	{
		field: 'presell_ctr',
		headerName: 'Presell CTR',
		width: 125,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => params.value && <div className="flex justify-end items-center">
			{formatPercent(params.value)}
			{' '}
			<Tooltip title="Presell CTR is always based on Maximus Estimates">
				<div>
					<Help className="fill-gray-500 h-[18px]"/>
				</div>
			</Tooltip>
		</div>,
	},
	{
		field: 'cvr',
		headerName: 'CVR',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatPercent(params.value),
	},
	{
		field: 'impressions',
		headerName: 'Impressions',
		width: 130,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatNumber(params.value),
	},
	{
		field: 'visits',
		headerName: 'Visits',
		width: 110,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatNumber(params.value),
	},
	{
		field: 'clicks',
		headerName: 'Clicks',
		width: 100,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatNumber(params.value),
	},
	{
		field: 'conversions',
		headerName: 'Conversions',
		width: 120,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatNumber(params.value),
	},
	{
		field: 'sales',
		headerName: 'Sales',
		width: 80,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params) => formatNumber(params.value),
	},
] as const satisfies GridColDef[];

export const commonColumns = (commonKeys: Array<typeof columns[number]['field']>) => {
	const commonFields = columns.map((column) => column.field);
	const returnColumns: GridColDef[] = [];
	commonKeys.forEach((key) => {
		if (commonFields.includes(key)) {
			const returnColumn = columns.find((column) => column.field === key);
			if (returnColumn) returnColumns.push(returnColumn);
		}
	});
	return returnColumns;
}
