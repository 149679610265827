import { useCallback, useEffect, useRef } from 'react';
import { store } from '../Redux/reduxStore';

const networkRequest = async (
	action: string,
	body?: object,
	method = 'POST',
	signal?: AbortSignal,
): Promise<Response> => {
	const {token} = store.getState()?.main || {};

	if (!token) return;

	let domain = 'https://api2.maximusx.app';

	if (import.meta?.env?.VITE_APP_LOCALHOST) {
		domain = import.meta.env.VITE_APP_LOCALHOST;
	}

	return fetch(`${domain}/${action}?${method === 'GET' ? new URLSearchParams(body) : ''}`,
		{
			method,
			headers: {Authorization: `Bearer ${token}`, 'Content-Type':'application/json', Accept: 'application/json'},
			body: method === 'POST' ? JSON.stringify(body) : undefined,
			signal,
		}
	);
}

export const creativesRequest = async (
	action: string,
	body?: object,
	method = 'POST',
): Promise<Response> => {
	const {token} = store.getState()?.main || {};

	if (!token) return;

	let domain = 'https://creatives.maximusx.app';


	return fetch(`${domain}/${action}?${method === 'GET' ? new URLSearchParams(body) : ''}`,
		{
			method,
			headers: {Authorization: `Bearer ${token}`, 'Content-Type':'application/json', Accept: 'application/json'},
			body: method === 'POST' ? JSON.stringify(body) : undefined,
		}
	);
}



/**
 * Wrapper hook that provides a automatically cancellable
 * network request instance when the component is unmounted.
 *
 * @returns A function with the same API as the standard networkRequest function.
 */
export function useCancellableNetworkRequest() {
	const abortControllerRef = useRef<AbortController>()

	useEffect(() => {
		return () => {
			if (abortControllerRef.current) abortControllerRef.current.abort()
		}
	}, [])

	return useCallback(async (path: string, body?: Record<string, any>, method = 'POST'): Promise<void | Response> => {
		if (abortControllerRef.current) abortControllerRef.current.abort()
		abortControllerRef.current = new AbortController()

		return networkRequest(path, body, method, abortControllerRef.current.signal)
			.catch(error => {
				if (error?.name !== 'AbortError') throw error
			})
	}, [])
}

const createFormData = (params: object) => {
	const formData = new FormData();
	Object.keys(params).forEach((key) => {
		const item = params[key];
		formData.append(key, item);
	});
	return formData;
};

export async function networkRequestMultipart(
	action: string,
	body: object,
	overrideDomain?: string,
): Promise<Response> {
	const { token } = store.getState()?.main || {};

	if (!token) return;

	let domain = overrideDomain || 'https://api.maximusx.app';

	if (import.meta?.env?.VITE_APP_LOCALHOST) {
		domain = import.meta.env.VITE_APP_LOCALHOST;
	}

	return fetch(`${domain}/${action}`,
		{
			method: 'POST',
			headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' },
			body: createFormData(body),
		}
	);
}

export default networkRequest;
