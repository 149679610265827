import FilterSection from "./FilterSection";
import ChartsSection from "./ChartsSection";
import ReportSection from "./ReportSection";
import SettingsSection from "./SettingsSection";
import InfoSection from './InfoSection.tsx';

export default function Menu() {
	return (
		<div className="menu-container">
			<FilterSection />
			<ChartsSection />
			<ReportSection />
			<SettingsSection />
			<InfoSection />
			<div className="mb-12" />
		</div>
	)
}
