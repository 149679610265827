import Input from '../../../Fields/Input';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeFireAndForgetField } from '@/Redux/Slices/addCampaign.ts';

export default function LowestBid() : JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			lowestBid,
			highestBid,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				lowestBid: lowestBidError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => {
				updateFireAndForget('lowestBid', value);
				updateFireAndForget('highestBid', highestBid);
			}}
			label="Lowest Bid"
			value={lowestBid}
			name="lowest-bid"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={fireAndForgetStatus === false}
			error={!!lowestBidError}
			helperText={lowestBidError}
		/>
	)
}
