import ReportTable from '../../../../DataTable/ReportTable.tsx';

const columns = [
  {
    field: 'user_name', headerName: 'User', width: 190,
  }, {
    field: 'date', headerName: 'Date', width: 200, type: 'timestamp'
  }, {
    field: 'event_name', headerName: 'User Action', width: 230,
  },  {
    field: 'message', headerName: 'Message', flex: 1000,
  },
];

export default function CampaignLog() {
  return (
    <div className="campaign-details-report">
      <ReportTable
        columns={columns}
        tableName="Campaign Log"
        endpoint="api/v1/campaign/reporting/log"
      />
    </div>
  );
}
