export const aggregationModel = {
  clicks: 'sum',
  conversions: 'sum',
  average_cpc: 'avg',
  cpc: 'blankColumn',
  epc: 'avg',
  spend: 'sum',
  revenue: 'sum',
  profit: 'sum',
  profit_percent: 'profitPercent',
  status: 'blankColumn',
  budget: 'blankColumn',
  budgetActions: 'blankColumn',
  fireAndForgetStatus: 'blankColumn',
};
