import {useMemo} from 'react';
import {
	Table, TableBody, TableHead, TableCell, IconButton, TableRow,
} from '@mui/material';
import Delete from "../../../../../Icons/Minus";
import {
	formatDayPartingForSchedule, removeDayFromDayParting
} from './dayPartingHelper';
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import { changeNonField } from '../../../../../Redux/Slices/addCampaign';

export default function SettingsTable() {
  const { dayParting, networkDayParting, maximusDayParting } = useAppSelector((state) => state.addCampaign);
	const dispatch = useAppDispatch();

	const removeDay = (id: string, day: string): void => {
    const newDayParting: Array<object> = removeDayFromDayParting(maximusDayParting ? dayParting : networkDayParting, id, day);
    dispatch(changeNonField({ key: maximusDayParting ? 'dayParting' : 'networkDayParting', value: newDayParting }));
  };

  const memoizedDataRows = useMemo(() =>
    formatDayPartingForSchedule(maximusDayParting ? dayParting : networkDayParting, maximusDayParting).map((
			{
				day, startAt, pauseAt, firstTime, id, budget, cpc, enableBudget, enableCPC, status,
			}, index
		) => (
			<TableRow className="day-parting-table-row" id={`day-parting-table-row-${index + 1}`} key={id + day}>
				<TableCell
					className={`day-parting-table-cell ${!firstTime ? 'day-parting-grayed-out' : ''}`}
				>
					{day}
				</TableCell>
				<TableCell className="day-parting-table-cell">
					{`${pauseAt} - ${startAt}`}
				</TableCell>
        {maximusDayParting ? <>
          <TableCell
            className={`day-parting-table-cell ${enableBudget && budget ? '' : 'day-parting-grayed-out'}`}
          >
            {enableBudget && budget ? '$' + budget : 'N/A'}
          </TableCell>
          <TableCell
            className={`day-parting-table-cell ${enableCPC && cpc ? '' : 'day-parting-grayed-out'}`}
          >
            {enableCPC && cpc ? '$' + cpc : 'N/A'}
          </TableCell>
          <TableCell className={`day-parting-table-cell ${(!enableCPC && !enableBudget) ? '' : 'day-parting-grayed-out'}`}>
            {(!enableCPC && !enableBudget) ? 'Paused' : 'N/A'}
          </TableCell>
        </> :
          <TableCell className={`day-parting-table-cell ${!status ? '' : 'day-parting-grayed-out'}`}>
            {!status ? 'Paused' : 'N/A'}
          </TableCell>
        }
				<TableCell className="day-parting-table-cell">
					<IconButton
						className="day-parting-table-delete"
						onClick={() => {
							removeDay(id, day);
						}}
					>
						<Delete/>
					</IconButton>
				</TableCell>
			</TableRow>
    )), [dayParting, networkDayParting, maximusDayParting]
	);

	return (
		<div className="day-parting-calendar-container">
			<div className="day-parting-table-container">
				<Table className="day-parting-table">
					<TableHead className="day-parting-table-header">
						<TableRow className="day-parting-table-row">
							<TableCell className="day-parting-table-cell">Day</TableCell>
							<TableCell className="day-parting-table-cell">Time</TableCell>
              {maximusDayParting && <>
                <TableCell className="day-parting-table-cell">Budget</TableCell>
                <TableCell className="day-parting-table-cell">CPC</TableCell>
              </>}
              <TableCell className="day-parting-table-cell">Status</TableCell>
              <TableCell className="day-parting-table-cell">Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
            {(maximusDayParting ? dayParting.length >= 1 : networkDayParting.length >= 1)
							? memoizedDataRows
							: (
								<TableRow className="day-parting-table-row">
									<TableCell className="day-parting-no-data-cell">No Day Parting Settings Have Been Created</TableCell>
								</TableRow>
							)
						}
					</TableBody>
				</Table>
			</div>
		</div>
	);
}
