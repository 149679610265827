
export default function NoImage({className}: {className?: string}) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			height="48"
			viewBox="0 -960 960 960"
			width="48"
		>
			<path xmlns="http://www.w3.org/2000/svg" d="m840-233-60-60v-487H293l-60-60h547q24 0 42 18t18 42v547ZM806-56l-64-64H180q-24 0-42-18t-18-42v-562l-63-63 43-43L849-99l-43 43ZM236-277l117-152 93 127 50-65-316-316v503h503l-97-97H236Zm301-260ZM432-432Z"/>
		</svg>
	)
}
