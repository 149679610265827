import {
	LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import {formatNumber} from '../../utils/formatNumbers';

const formatXAxis = (tick) => tick.split('_').join(' ');

export default function LineChartComponent(
	{
		height,
		data,
		keyName,
		showDollar = false,
		dataKey = '',
		name = '',
		CustomTooltip = null,
	}: {
		height: number,
		data: object[],
		keyName: string,
		showDollar: boolean,
		dataKey: string,
		name: string,
		CustomTooltip: ({ active, payload, label }: { active: boolean, payload: object[number], label: string }) => JSX.Element | null,
	}) {
	return (
		<ResponsiveContainer width="100%" height={height}>
			<LineChart
				data={data}
				margin={{
					top: 10, right: 40, left: 0, bottom: 5,
				}}
			>
				<XAxis dataKey={keyName} tickFormatter={formatXAxis}/>
				<YAxis type="number" domain={['dataMin', 'dataMax']} />
				<CartesianGrid strokeDasharray="3 3"/>
				{CustomTooltip ? (
					<Tooltip content={<CustomTooltip />}/>
				) : (
					<Tooltip formatter={(value) => {
						let str = `${showDollar ? '$' : ''}${formatNumber(value)}`;
						if (str.includes('-')) {
							str = str.replace('-', '');
							return `-${str}`;
						}
						return str;
					}}
					/>
				)}
				<Legend/>
				<Line
					type="monotone"
					dataKey={dataKey}
					name={name}
					activeDot={{r: 8}}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}
