import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function ClickPacingMinutes(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			clickPacingMinutes,
			clickPacingActive,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				clickPacingMinutes: clickPacingMinutesError
			}
		},
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('clickPacingMinutes', value)}
			label="Click Pacing Minutes"
			value={clickPacingMinutes}
			name="click-pacing-minutes"
			nonDecimal
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			disabled={clickPacingActive === false || fireAndForgetStatus === false}
			error={clickPacingMinutesError}
			helperText={clickPacingMinutesError}
		/>
	)
}
