import ExpandMore from "../../../../../Icons/ExpandMore";

export default function SectionTitle({ title, icon, onClick, collapsed }: { title: string, icon: JSX.Element, onClick: () => void, collapsed: boolean }) {
	return (
		<div className="menu-section-title">
			<div className="menu-section-title-left">
				{icon}
				{title}
			</div>
			<div className="expand-icon-container" onClick={onClick}>
				<ExpandMore
					className={`expand-icon ${collapsed ? 'expand-icon-collapsed' : ''}`}
				/>
			</div>
		</div>
	)
}
