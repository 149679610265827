import Input from '../../../Fields/Input';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeFireAndForgetField } from '@/Redux/Slices/addCampaign.ts';

export default function SiteHighestBid(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			siteHighestBid,
			siteLowestBid,
			campaignAcceptableProfitActive,
			fireAndForgetStatus,
		},
		errors: {
			fields: {
				siteHighestBid: siteHighestBidError
			}
		},
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => {
				updateFireAndForget('siteHighestBid', value);
				updateFireAndForget('siteLowestBid', siteLowestBid);
			}}
			label="Highest Bid"
			value={siteHighestBid}
			numeric
			name="site-highest-bid"
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={!campaignAcceptableProfitActive || fireAndForgetStatus === false}
			error={!!siteHighestBidError}
			helperText={siteHighestBidError}
		/>
	)
}
