import { useAppDispatch, useAppSelector } from '@/hooks'
import Upload from '@/Icons/Upload'
import { changeAdSetup } from '@/Redux/Slices/addCampaign'
import { openSnackbar } from '@/Redux/Slices/main'
import { isTaboola } from '@/utils/network-utils'
import { processDefaultImage } from '@pqina/pintura'
import { memo, useEffect, useState } from 'react'
import FileList from './FileList'
import ImageEditor from './ImageEditor'
import { FileToUpload, validateFile } from './validation'

const getEmptyValidation = () => [] as FileToUpload[]

function UploadAdsV2() {
	const dispatch = useAppDispatch()
	const currentFiles = useAppSelector(state => state.addCampaign.adSetup.filesToUpload)
	const validFilesToUpload = useAppSelector(state => state.addCampaign.adSetup.validFilesToUpload)
	const network = useAppSelector(state => state.campaignDetails.network)
	const [editingImage, setEditingImage] = useState<File>()
	const [parsedFiles, setParsedFiles] = useState(() => getEmptyValidation())

	useEffect(() => {
		const parseFiles = async () => {
			const result = getEmptyValidation()
			for (const file of currentFiles) {
				const validation = await validateFile(file, network)
				if (validation?.error) {
					result.push({ file, error: true, reason: validation.reason })
					continue
				}
				if (validation?.reason) {
					result.push({ file, warn: true, reason: validation.reason })
					continue
				}
				result.push({ file, ok: true })
			}
			setParsedFiles(result)
			const validFiles = result.filter(item => item.ok).map(item => item.file)
			dispatch(changeAdSetup({ validFilesToUpload: validFiles }))
		}
		parseFiles()
	}, [dispatch, currentFiles, network])

	useEffect(() => {
		return () => {
			dispatch(changeAdSetup({ filesToUpload: [], validFilesToUpload: [] }))
		}
	}, [dispatch])

	const updateFiles = (files: File[]) => {
		dispatch(changeAdSetup({ filesToUpload: files }))
	}

	const appendFiles = async (fileList: FileList | null) => {
		const files: File[] = []

		for (const file of Object.values(fileList || {})) {
			const validation = await validateFile(file, network)
			if (validation?.autofix && !file.type.match('image/gif')) {
				const result = await processDefaultImage(file, { imageWriter: validation.autofix })
				files.push(result.dest)
				dispatch(
					openSnackbar({
						children: 'Some files were automatically adjusted to meet current network requirements',
						severity: 'info',
					})
				)
			} else {
				files.push(file)
			}
		}

		updateFiles([
			...files.filter(file => !currentFiles.some(f => f.name === file.name)),
			...currentFiles,
		])
	}

	return (
		<div className="flex w-full flex-col gap-2">
			<label
				className="flex cursor-pointer flex-col items-center justify-center rounded-md border border-dashed border-black/20 bg-neutral-200 p-1 font-semibold text-black/40 hover:brightness-105"
				onDragOver={e => void e.preventDefault()}
				onDrop={e => {
					e.preventDefault()
					e.stopPropagation()
					appendFiles(e.dataTransfer.files)
				}}
			>
				<input
					hidden
					multiple
					data-testid="fileInput"
					type="file"
					accept="video/*, image/*"
					value=""
					onChange={e => appendFiles(e.target.files)}
				/>
				<Upload className="fill-[#4285F4]" />
				<p>Upload Images {isTaboola(network) && 'or Videos'}</p>
			</label>

			<FileList items={parsedFiles} onSetEditingImage={setEditingImage} />

			{currentFiles.length > validFilesToUpload.length && (
				<span className="mt-4 block rounded border border-yellow-500/30 bg-yellow-500/10 p-3 text-center text-sm font-medium text-yellow-800/80">
					Some files contain errors or warnings, and will not be uploaded. <br />
					Please review them before proceeding.
				</span>
			)}

			{editingImage && (
				<ImageEditor
					src={editingImage}
					onClose={() => setEditingImage(undefined)}
					onProcess={res => {
						updateFiles(
							currentFiles.map(file => {
								if (file.name === editingImage.name) return res.dest
								return file
							})
						)
						setEditingImage(undefined)
					}}
				/>
			)}
		</div>
	)
}

export default memo(UploadAdsV2)
