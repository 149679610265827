import {memo} from 'react';
import lodash from 'lodash';
import {
  ResponsiveContainer, AreaChart, XAxis, Tooltip, CartesianGrid, Area, YAxis, Legend,
} from 'recharts';
import { formatNumber } from '../../utils/formatNumbers.ts';

const { startCase } = lodash;

const AreaChartComponent = memo(({
  data, fields, dataKey, height, showDollar, CustomTooltip = null, CustomXAxisTick = null, legend = false,
} : {
    data: object[], fields: [], dataKey: string, height: number, showDollar: boolean, legend: boolean,
    CustomTooltip: ({ active, payload, label }: { active: boolean, payload: object[number], label: string }) => JSX.Element | null,
}) => (
  <ResponsiveContainer width="100%" height={height}>
    <AreaChart
      data={data}
      margin={{
        top: 25, right: 40, left: 0, bottom: 20,
      }}
    >
      <defs>
        {fields.map((field: { name: string, color: string }) => (
          <linearGradient id={`color_${field.name}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={field.color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={field.color} stopOpacity={0.25} />
          </linearGradient>
        ))}
      </defs>
      <XAxis
        dataKey={dataKey}
        tick={CustomXAxisTick ? <CustomXAxisTick /> : true}
      />
      <YAxis type="number" domain={['dataMin', 'dataMax']} />
      <CartesianGrid vertical={false} strokeDasharray="4 2" />
      {legend && <Legend verticalAlign="bottom" />}
      {CustomTooltip ? (
        <Tooltip content={<CustomTooltip />} />
      ) : (
        <Tooltip formatter={(value) => {
            let str = `${showDollar ? '$' : ''}${formatNumber(value)}`;
            if (str.includes('-')) {
              str = str.replace('-', '');
              return `-${str}`;
            }
            return str;
          }}
        />
      )}
      {fields.map((field: { name: string, color: string } ) => (
        <Area
          type="monotone"
          dataKey={field.name}
          stroke={field.color}
          fillOpacity={1}
          fill={`url(#color_${field.name})`}
          name={startCase(field.name)}
        />
      ))}
    </AreaChart>
  </ResponsiveContainer>
));

export default AreaChartComponent;
