import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { modifyAdsForParams } from '../../../AddCampaign/Components/SubmitCampaign.tsx';
import { changeNonField, resetAddCampaign } from '@/Redux/Slices/addCampaign.ts';
import {validateAds} from "../../../AddCampaign/Components/Validation/Ads.tsx";

export const useSubmitAds = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network, isOtto } = useAppSelector((state) => state.campaignDetails);
	const addCampaign = useAppSelector((state) => state.addCampaign);

	return async () =>  {
		const newErrors = validateAds(addCampaign, network)

		if (Object.keys(newErrors.fields).length) {
			dispatch(changeNonField({ key: 'errors', value: newErrors }))
			const children = (
				<ul>
					{Object.entries(newErrors.fields).map(([key, value]) => (
						<li key={`error-${key}`}>{value}</li>
					))}
				</ul>
			)
			dispatch(openSnackbar({ children, severity: 'error' }));
			document.getElementById('generate-ads')?.scrollIntoView();
			return;
		}

		await networkRequest('api/v1/campaign/ad/create', {
			campaignID,
			network,
			ads: modifyAdsForParams(addCampaign.ads, isOtto),
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object' && response.success === true) {
					dispatch({ type: 'campaignDetails/closeDialog' });
					dispatch(resetAddCampaign({ network }));
				} else {
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again',
							severity: 'error'
						},
					));
				}
			}
		)
		.catch(() => {
			dispatch(openSnackbar(
				{
					children: 'Something went wrong. Please try again',
					severity: 'error'
				},
			));
		});
	}
}
