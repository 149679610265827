import { useEffect, useState } from 'react';
import find from 'lodash/find';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import Select from '../../../AddCampaign/Fields/Select.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';

import '../../CampaignDetails.css';

export default function ReassignCampaign() {
  const dispatch = useAppDispatch();
  const [groupsLoading, setGroupsLoading] = useState<boolean>(true);
  const [groupsOptions, setGroupsOptions] = useState<{ label: string, value: string }[]>([]);
  const [usersOptions, setUsersOptions] = useState<{ label: string, value: string }[]>([]);
  const { userID } = useAppSelector((state) => state.main);
  const {
    campaignID, network, selectedUserID, selectedGroupID, settingsDialogLoading,
  } = useAppSelector((state) => state.campaignDetails);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUserID !== '0') {
      getUserGroups(selectedUserID);
    }
  }, [selectedUserID]);

  useEffect(() => {
    if (!find(groupsOptions, ['value', selectedGroupID])) {
      dispatch(changeCampaignDetails({ key: 'selectedGroupID', value: '0' }));
    }
  }, [groupsOptions])

  const getUserGroups = (selectedUserID: string) => {
    setGroupsLoading(true);
    networkRequest('api/v1/user/group/pull', { userID: selectedUserID })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.length) {
          setGroupsOptions(response.map((i: { name: string, id: number }) => ({ label: i.name, value: (i.id).toString() })));
        }
        setGroupsLoading(false);
      })
  };

  const getData = async () => {
    dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
    const getUsers = networkRequest('api/v1/user/list/pull')
      .then((response) => response.json());
    const pullCurrentData = networkRequest('api/v1/campaign/userGroup/pull', {
      campaignID,
      network,
      user_id: userID,
    })
      .then((response) => response.json());
    Promise.all([getUsers, pullCurrentData])
    .then((responses) => {
      setOptions(responses);
      dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
    })
    .catch(() => {
      dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
    });
  };

  const setOptions = (responses: [[], [], object]) => {
    if (responses[0] && responses[0].length) {
      setUsersOptions(responses[0].map((i: { name: string, user_id: number }) => ({ label: i.name, value: (i.user_id).toString() })));
    }
    if (responses[1]) {
      dispatch(changeCampaignDetails({ key: 'selectedUserID', value: (responses[1].userID).toString() || '0' }));
      dispatch(changeCampaignDetails({ key: 'selectedGroupID', value: (responses[1].groupID).toString() || '0' }));
    }
  };

  return settingsDialogLoading ? <FormLoader /> :
    <>
      <div className="add-campaign-field-row">
        <Select
          onChange={({ target: { value}}) => dispatch(changeCampaignDetails({ key: 'selectedUserID', value }))}
          value={selectedUserID}
          name="user"
          label="User"
          options={[
            { label: 'Select user', value: '0' },
            ...usersOptions,
          ]}
        />
        <Select
          onChange={({ target: { value}}) => dispatch(changeCampaignDetails({ key: 'selectedGroupID', value }))}
          value={selectedGroupID}
          name="group"
          label="Group"
          options={[
            { label: 'Select group', value: '0' },
            ...groupsOptions,
          ]}
          disabled={groupsLoading}
        />
      </div>
    </>;
}
