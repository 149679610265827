export const formatNumber = (number: number | string | undefined) : string => {
	if (number === undefined) return '';
	const intSide = (number || 0).toString().split('.')[0];
	const decimalSide = (number || 0).toString().split('.')[1];
	return intSide.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (decimalSide ? `.${decimalSide}` : '');
}

export const formatCurrency = (number: number | string | undefined) : string => {
	if (number === undefined) return '';
	const strippedNumber: number | string = (number || 0).toString().replace('$', '').replace(',', '');
	if (!Number.isNaN(+strippedNumber)) {
		const formattedNumber = formatFloat(strippedNumber);
		if (formattedNumber.includes('-')) {
			return `-$${formattedNumber.replace('-', '')}`;
		}
		return `$${formattedNumber}`;
	}
	return strippedNumber.toUpperCase() === 'NA' ? strippedNumber : '';
};

export const formatFloat = (value: number | string | undefined, decimals = 2) : string => {
	if (value === undefined) return '';
	if (!Number.isNaN(Number(value))) return `${formatNumber(parseFloat((value || 0).toString()).toFixed(decimals))}`;
	return value as string;
};

export const formatPercent = (number: number | string | undefined) : string => {
	if (number === undefined) return '';
	return formatFloat(number || 0) + '%';
}

export const conditionalProfitClass = (key: string, value: number | string) : string => {
	if (key.toLowerCase().includes('profit')) {
		return (value || '').toString().includes('-') ? 'text-[#FF0000]' : 'text-[#228C22]';
	}
	return '';
}
