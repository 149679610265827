import { useEffect } from 'react';
import Links from '../../../AddCampaign/Pages/Links';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { changeLink, changeNonField, changeSettingsField } from '../../../../../Redux/Slices/addCampaign.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import '../../../AddCampaign/addCampaign.css';

export default function EditLinks() {
	const dispatch = useAppDispatch();
	const { campaignID, network, settingsDialogLoading } = useAppSelector((state) => state.campaignDetails);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async () => {
		dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
		networkRequest('api/v1/campaign/links/pull', {
			campaignID,
			network,
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object') {
					setSettings(response);
				}
				dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
			}
		)
		.catch(() => {
			dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
		});
	};

	const setSettings = (response) => {
		const checkLinks = (links) => (!links || (Array.isArray(links) && !links.length)) ? { 1: { url: '', weight: 100 }} : links;
		dispatch(changeSettingsField({ name: 'maximusLinks', value: response.maximusLinks || false }));
		dispatch(changeSettingsField({ name: 'originalMaximusLinks', value: response.maximusLinks || false }));
		dispatch(changeSettingsField({ name: 'isOttoCampaign', value: response.isOtto || false }));
		dispatch(changeSettingsField({ name: 's1', value: response.s1 || '' }));
		dispatch(changeNonField({ key: 'advancedTracking', value: response.advancedTracking || false }));
		dispatch(changeLink({ value: response.link || '' }));
		const presellLinks = checkLinks(response.presellLinks);
		const landingPageLinks = checkLinks(response.landingPageLinks);
		dispatch(changeNonField({ key: 'presellLinks', value: presellLinks }));
		dispatch(changeNonField({ key: 'landingPageLinks', value: landingPageLinks }));
		dispatch(changeNonField({ key: 'originalPresellLinks', value: Object.keys(presellLinks).map(key => presellLinks[key].url).filter(link => link) }));
		dispatch(changeNonField({ key: 'originalLPLinks', value: Object.keys(landingPageLinks).map(key => landingPageLinks[key].url).filter(link => link) }));
	};

	return (
		settingsDialogLoading ? <FormLoader /> : <Links editLinks />
	);
}
