import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function CampaignCPAGoal(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			campaignCpaGoal,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				campaignCpaGoal: campaignCpaGoalError
		},
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('campaignCpaGoal', value)}
			label="Campaign CPA Goal"
			value={campaignCpaGoal}
			name="campaign-cpa-goal"
			type="number"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={fireAndForgetStatus === false}
			error={campaignCpaGoalError}
			helperText={campaignCpaGoalError}
		/>
	);
}
