import { useAppSelector } from '@/hooks'
import Add from '@/Icons/Add'
import Edit from '@/Icons/Edit'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import SectionTitle from '../../Components/SectionTitle.tsx'
import AddAdsSection from './AddAdsSection.tsx'
import CopyAdsButton from './CopyAdsButton.tsx'
import DisplayAdsSection from './DisplayAdsSection.tsx'

export default function AdSetup() {
	const ads = useAppSelector(state => state.addCampaign.ads)
	const [addMoreAds, setAddMoreAds] = useState(true)

	useEffect(() => {
		if (ads.length > 0) setAddMoreAds(false)
	}, [ads]);

	return (
		<div className="w-full">
			<SectionTitle
				title="Ad Setup"
				EndAddition={
					<div className="mb-2 flex items-center gap-6">
						<Button
							className="h-fit"
							variant="contained"
							type="button"
							onClick={() => setAddMoreAds(!addMoreAds)}
						>
							{addMoreAds ? (
								<>
									<Edit className="mr-3 size-5 fill-white" /> View Ads
								</>
							) : (
								<>
									<Add className="mr-3 size-6 fill-white" /> Add More Ads
								</>
							)}
						</Button>
						<CopyAdsButton />
					</div>
				}
			/>
			<main className='p-2'>
				{addMoreAds && <AddAdsSection />}
				{!addMoreAds && <DisplayAdsSection />}
			</main>
		</div>
	)
}
