export default function SectionButton({
	text,
	onClick,
	selected = false,
	disabled = false,
}: {
	text: string
	onClick: () => void
	selected?: boolean
	disabled?: boolean
}) {
	return (
		<button
			className={`menu-button ${ selected ? 'menu-button-selected' : ''}`}
			type="button"
			onClick={onClick}
			disabled={disabled}
		>
			{text}
		</button>
	)
}
