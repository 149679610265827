import { useMemo } from 'react';
import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { isRevContent } from '@/utils/network-utils.ts';
import { estimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';
import { useAppSelector } from '@/hooks.ts';

const columns = ([
	{
		field: 'platform', headerName: 'Platform', width: 140,
	},
		...commonColumns(
		[
			'visits',
			'impressions',
			'clicks',
			'conversions',
			'epc',
			'average_cpc',
			'ctr',
			'cvr',
			'cpa',
			'roas',
			'revenue',
			'spend',
			'profit',
			'profit_percent',
		],
	),
]);

export default function Platforms() {
	const { estimatedSpends, network, advancedTracking, maximusLinks } = useAppSelector((state) => state.campaignDetails);
	const asyncColumns = useMemo(() => {
		return {
			visits: isRevContent(network) && advancedTracking && maximusLinks,
			impressions: !isRevContent(network),
		}
	}, [advancedTracking, maximusLinks, network]);

	return (
		<div className="campaign-details-report">
			{(!estimatedSpends && isRevContent(network)) && estimatedReportsDisclaimer}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns}
				tableName="Platforms"
				endpoint="api/v1/campaign/reporting/platform"
				aggregationModel={{
					impressions: 'sum',
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					average_cpc: 'avg',
					ctr: 'avg',
					epc: 'avg',
					cpa: 'avg',
					cvr: 'avg',
					roas: 'avg',
					spend: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					revenue: 'sum',
					platform: 'blankColumn',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					maximusEpc: 'avg',
					maximusCtr: 'avg',
					maximusCvr: 'avg',
					maximusCpa: 'avg',
					maximusRoas: 'avg',
				}}
			/>
		</div>
	);
}
