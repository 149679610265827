export const callToActionOptions: Array<{ value: string, label: string }> = [
	{value: 'APPLY_NOW', label: 'Apply Now'},
	{value: 'BET_NOW', label: 'Bet Now'},
	{value: 'BOOK_NOW', label: 'Book Now'},
	{value: 'BROWSE_NOW', label: 'Browse Now'},
	{value: 'BUILD_YOURS', label: 'Build Yours'},
	{value: 'BUY_NOW', label: 'Buy Now'},
	{value: 'CALL_NOW', label: 'Call Now'},
	{value: 'CLICK_HERE', label: 'Click Here'},
	{value: 'COMPARE', label: 'Compare'},
	{value: 'CONTACT_US', label: 'Contact Us'},
	{value: 'DIRECTIONS', label: 'Directions'},
	{value: 'DOWNLOAD', label: 'Download'},
	{value: 'ENROLL_NOW', label: 'Enroll Now'},
	{value: 'ENQUIRE_NOW', label: 'Enquire Now'},
	{value: 'ENTER_NOW', label: 'Enter Now'},
	{value: 'EXPLORE', label: 'Explore'},
	{value: 'FIND_NOW', label: 'Find Now'},
	{value: 'FOLLOW_NOW', label: 'Follow Now'},
	{value: 'GET_APP', label: 'Get App'},
	{value: 'GET_COUPON', label: 'Get Coupon'},
	{value: 'GET_DEALS', label: 'Get Deals'},
	{value: 'GET_INFO', label: 'Get Info'},
	{value: 'GET_NOW', label: 'Get Now'},
	{value: 'GET_OFFER', label: 'Get Offer'},
	{value: 'GET_QUOTE', label: 'Get Quote'},
	{value: 'GET_RATES', label: 'Get Rates'},
	{value: 'GET_SAMPLE', label: 'Get Sample'},
	{value: 'GIFT_NOW', label: 'Gift Now'},
	{value: 'GO_TO_QUIZ', label: 'Go to Quiz'},
	{value: 'GO_TO_TOOL', label: 'Go to Tool'},
	{value: 'INSTALL_NOW', label: 'Install Now'},
	{value: 'JOIN_NOW', label: 'Join Now'},
	{value: 'LAUNCH', label: 'Launch'},
	{value: 'LEARN_MORE', label: 'Learn More'},
	{value: 'LISTEN_NOW', label: 'Listen Now'},
	{value: 'ORDER_NOW', label: 'Order Now'},
	{value: 'PLAY_GAME', label: 'Play Game'},
	{value: 'PLAY_NOW', label: 'Play Now'},
	{value: 'PRE_ORDER', label: 'Pre Order'},
	{value: 'READ_MORE', label: 'Read More'},
	{value: 'READ_NOW', label: 'Read Now'},
	{value: 'RECORD_NOW', label: 'Record Now'},
	{value: 'REFER_NOW', label: 'Refer Now'},
	{value: 'REGISTER', label: 'Register'},
	{value: 'REMIND_ME', label: 'Remind Me'},
	{value: 'RESERVE', label: 'Reserve'},
	{value: 'SAVE_NOW', label: 'Save Now'},
	{value: 'SAVE_OFFER', label: 'Save Offer'},
	{value: 'SEARCH_NOW', label: 'Search Now'},
	{value: 'SHOP_NOW', label: 'Shop Now'},
	{value: 'SIGN_UP', label: 'Sign Up'},
	{value: 'START_NOW', label: 'Start Now'},
	{value: 'SUBSCRIBE', label: 'Subscribe'},
	{value: 'SWITCH_NOW', label: 'Switch Now'},
	{value: 'TEST_DRIVE', label: 'Test Drive'},
	{value: 'TRY_FREE', label: 'Try Free'},
	{value: 'TRY_NOW', label: 'Try Now'},
	{value: 'UPGRADE', label: 'Upgrade'},
	{value: 'VIEW_DEALS', label: 'View Deals'},
	{value: 'VOTE_NOW', label: 'Vote Now'},
	{value: 'WATCH_MORE', label: 'Watch More'},
	{value: 'WATCH_NOW', label: 'Watch Now'},
	{value: 'NONE', label: 'None'},
];