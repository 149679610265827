import { formatTokens, tokenMap } from '@/utils/token-utils'
import { AddCampaignState } from '../../../../../Redux/Slices/addCampaign.ts'
import { Network } from '../../../../../utils/network-utils.ts'
import { ValidateStepProps } from '../StepsValidation.tsx'

export const validateAds = (state: AddCampaignState, network: Network): ValidateStepProps => {
	const errors: ValidateStepProps = {fields: {}};
	if (!state.ads || state.ads.length === 0) {
		errors.fields.ads = 'You should generate at least one ad.';
	}

	for (const ad of state.ads) {
		if (!ad.headline) {
			errors.fields[`headline-${ad.uuid}`] = 'All Headlines should be at least 1 character long'
		}

		const headlineErrors = formatTokens(ad.headline, network).errors
		if (headlineErrors.length) {
			errors.fields[`headline-${ad.uuid}`] = `Invalid headline tokens: ${headlineErrors.join(', ')}`
		}
		const maxHeadline = tokenMap[network].maxLength.headline
		if (ad.headline.length > maxHeadline) {
			errors.fields[`headline-${ad.uuid}`] = `Headline exceeds character limit of ${maxHeadline}`
		}

		const descriptionErrors = formatTokens(ad.description, network).errors
		if (descriptionErrors.length) {
			errors.fields[`description-${ad.uuid}`] =
				`Invalid description tokens: ${descriptionErrors.join(', ')}`
		}
		const max = tokenMap[network].maxLength.description
		if (ad.description.length > max) {
			errors.fields[`description-${ad.uuid}`] = `Description exceeds character limit of ${max}`
		}
	}

	return errors;
};
