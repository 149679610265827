import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

type OptionType = { value: string; label: string }

const filter = createFilterOptions<OptionType>()

type HoursAutocompleteProps = {
	'data-testid'?: string
	className?: string
	error?: boolean
	helperText?: string
	label?: string
	options: OptionType[]
	value: string
	onValueChange: (value: string) => void
}

// TODO: generalize other instances and make this the new default
export default function HoursAutocomplete({
	"data-testid": dataTestid,
	className,
	error,
	helperText,
	label,
	options,
	value,
	onValueChange,
}: HoursAutocompleteProps) {
	return (
		<Autocomplete
			data-testid={dataTestid}
			freeSolo
			selectOnFocus
			clearOnBlur
			disableClearable
			className={className}
			options={options}
			filterOptions={filter}
			value={value}
			onChange={(event, newValue) => {
				if (!newValue) return
				if (typeof newValue === 'string') {
					const match = options.find(item => item.label === newValue || item.value === newValue)
					if (match) onValueChange(match.value)
				} else {
					onValueChange(newValue.value)
				}
			}}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					error={error}
					helperText={helperText}
					variant="filled"
				/>
			)}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props
				return (
					<li key={key} {...optionProps}>
						{option.label}
					</li>
				)
			}}
			getOptionLabel={option => {
				if (!option) return 'NA'
				if (typeof option !== 'string') return option.label
				const match = options.find(item => item.label === option || item.value === option)
				if (match) return match.label
				return option
			}}
		/>
	)
}
