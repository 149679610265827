import {useState} from "react";
import SectionTitle from "./Components/SectionTitle";
import SectionButton from "./Components/SectionButton";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {changeCampaignDetails} from "../../../../Redux/Slices/campaignDetails";
import BarChart from "../../../../Icons/BarChart";

export default function ChartsSection() {
	const dispatch = useAppDispatch();
	const {chart, estimatedSpends} = useAppSelector((state) => state.campaignDetails);
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const updateChart = (chart: string) => {
		dispatch(changeCampaignDetails({key: 'chart', value: chart}));
	}

	return (
		<div className={`menu-section ${collapsed ? 'menu-section-collapsed' : ''}`}>
			<SectionTitle
				title="Charts"
				icon={<BarChart className="menu-section-icon"/>}
				onClick={() => setCollapsed(!collapsed)}
				collapsed={collapsed}
			/>
			<SectionButton
				text="Clicks & Conversions"
				onClick={() => updateChart('ClicksAndConversions')}
				selected={chart === 'ClicksAndConversions'}
			/>
			{localStorage.getItem('showHidden') &&
				<>
					<SectionButton
						text="Hourly Clicks"
						onClick={() => updateChart('HourlyClicks')}
						selected={chart === 'HourlyClicks'}
					/>
					<SectionButton
						text="Hourly Comparison"
						onClick={() => updateChart('HourlyComparison')}
						selected={chart === 'HourlyComparison'}
					/>
					<SectionButton
						text="Weekly Comparison"
						onClick={() => updateChart('WeeklyComparison')}
						selected={chart === 'WeeklyComparison'}
					/>
				</>
			}
		</div>
	)
}
