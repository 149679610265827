import { ReactNode } from 'react';
import {openSnackbar} from "../Redux/Slices/main";
import {useAppDispatch} from "../hooks";

export const useErrorHelper = () => {
	const dispatch = useAppDispatch();

	const renderError = (errors: Array<string> | string | object) => {
		if (typeof errors === 'string') {
			return errors;
		} else if (typeof errors === 'object' && Array.isArray(errors) === false) {
			const errorMessages = [];
			Object.values(errors)
				.forEach((error: Array<string>) => error
					.forEach((message) =>
						errorMessages.push(message)
					)
				);

			if (errorMessages.length === 1) {
				return errorMessages[0];
			}
			return <ErrorList>{errorMessages.map((error) => <li>{error}</li>)}</ErrorList>;
		} else if (typeof errors === 'object' && errors.length === 1) {
			return errors[0];
		}
		return <ErrorList>{errors.map((error) => <li>{error}</li>)}</ErrorList>;
	}

	return (errors: Array<string> | string | object) => {
		if (
			(typeof errors === 'object' && Array.isArray(errors) === false && Object.keys(errors).length > 0)
			||
			(typeof errors === 'object' && Array.isArray(errors) && errors.length > 0)
			||
			(typeof errors === 'string' && errors.length > 0)
		) {
			dispatch(
				openSnackbar(
					{
						children: renderError(errors),
						severity: 'error',
					}
				));
			return;
		}
	}
}

export function ErrorList({ children }: { children: ReactNode }) {
	return <ul className="text-left">{ children }</ul>;
}
