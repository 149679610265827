import {useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../Redux/Slices/addCampaign';
import SectionTitle from "../../Components/SectionTitle";
import Toggle from "../../../../FormComponents/Toggle";
import NonCPCCampaign from "./Views/NonCPCCampaign";
import AcceptableProfitOptimization from "./Views/AcceptableProfitOptimization";
import CPAGoalOptimization from "./Views/CPAGoalOptimization";
import NoneOptimization from "./Views/NoneOptimization";

export default function FireAndForget({campaignDetails = false}) {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			fireAndForgetStatus,
			campaignAcceptableProfitActive,
			campaignCpaGoalActive,
		},
		settings: {
			bidStrategy
		},
	} = useAppSelector((state) => state.addCampaign);

	const {hasCpc} = useAppSelector((state) => state.campaignDetails);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	const renderView = useMemo(() => {
		const showCPCField =
			(!campaignDetails && (bidStrategy === '1' || bidStrategy === '2'))
			|| (campaignDetails && hasCpc);

		if (!showCPCField) {
			return <NonCPCCampaign />;
		}

		if (campaignAcceptableProfitActive) {
			return <AcceptableProfitOptimization />;
		}

		if (campaignCpaGoalActive) {
			return <CPAGoalOptimization />;
		}

		return <NoneOptimization />;
	}, [
		campaignAcceptableProfitActive,
		campaignCpaGoalActive,
		campaignDetails,
		hasCpc,
		bidStrategy
	]);

	return (
		<>
			<SectionTitle
				title="Campaign Fire & Forget™ Settings"
				EndAddition={
					<Toggle
						label="Fire & Forget™ Status"
						trackColor="#4285F4"
						value={fireAndForgetStatus}
						onChange={(checked) => updateFireAndForget('fireAndForgetStatus', checked)}
						name="fnf-status"
					/>
				}
			/>
			{renderView}
		</>
	)
}
