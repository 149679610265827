import Input from '../../../Fields/Input';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeFireAndForgetField } from '@/Redux/Slices/addCampaign.ts';
import Toggle from '../../../../../FormComponents/Toggle.tsx';

export default function StopAmount(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			stopAmount,
			slowAmount,
			slowStopAmountActive,
			fireAndForgetStatus,
		},
		errors: {
			fields: {
				stopAmount: stopAmountError
			}
		},
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => {
				updateFireAndForget('stopAmount', value);
				updateFireAndForget('slowAmount', slowAmount);
			}}
			label="Stop Amount"
			value={stopAmount}
			name="stop-amount"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			endAdornment={
				<Toggle
					trackColor="#4285F4"
					value={slowStopAmountActive}
					onChange={(checked) => updateFireAndForget('slowStopAmountActive', checked)}
					name="slow-amount-status"
					disabled={fireAndForgetStatus === false}
				/>
			}
			disabled={slowStopAmountActive === false || fireAndForgetStatus === false}
			error={!!stopAmountError}
			helperText={stopAmountError}
		/>
	)
}
