import {ClientOnly} from "../utils/ClientOnly";

export default function CloneCampaign() {
	return (
		<ClientOnly
			component={() => import("../Components/Pages/AddCampaign/CloneCampaign")}
		/>
	)
}



