import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';

export const useSubmitConversionCap = () => {
  const dispatch = useAppDispatch();
  const { conversionCap, conversionCapTimeframe, campaignID, network } = useAppSelector((state) => state.campaignDetails);

  return async () =>  {
    await networkRequest('api/v1/campaign/conversionCap/update', {
      campaignID,
      network,
      conversionCapThreshold: conversionCap,
      conversionCapTimeframe,
    }, 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object') {
          dispatch({ type: 'campaignDetails/closeDialog' });
        } else {
          dispatch(openSnackbar(
            {
              children: response.message || 'Something went wrong. Please try again.',
              severity: 'error'
            }
          ));
        }
      }
    );
  }
}
