import {changeCampaignDetails} from "../../../../Redux/Slices/campaignDetails";
import networkRequest from "../../../../utils/networkRequest";
import {openSnackbar} from "../../../../Redux/Slices/main";

export function updateArchiveStatus(newArchiveStatus: boolean, dispatch: any, campaignID: string, network: string, campaignStatus: boolean) {
	dispatch(changeCampaignDetails({ key: 'campaignArchiveStatus', value: newArchiveStatus }));

	return networkRequest('api/v1/campaign/archive/update', { campaignID, network, archiveStatus: newArchiveStatus })
		.then((response) => response.json())
		.then((response) => {
			if (response.success) {
				if (newArchiveStatus && campaignStatus) dispatch(changeCampaignDetails({ key: 'campaignStatus', value: false }));
			} else {
				dispatch(changeCampaignDetails({ key: 'campaignArchiveStatus', value: !newArchiveStatus }));
				dispatch(openSnackbar(
					{
						children: response.message || 'Something went wrong. Please try again.',
						severity: 'error',
					},
				));
			}
		});
}