import ClicksAndConversions from './ClicksAndConversions';
import HourlyClicks from './HourlyClicks';
import HourlyComparison from './HourlyComparison';
import WeeklyComparison from './WeeklyComparison';

export default {
	ClicksAndConversions,
	HourlyClicks,
	HourlyComparison,
	WeeklyComparison,
};
