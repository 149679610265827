import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';
import { getNetworkLabel } from '../../constants';

export default function SiteAcceptableProfit() {
	const dispatch = useAppDispatch();
	const {
		siteAcceptableProfit,
		campaignAcceptableProfitActive,
		fireAndForgetStatus,
	} = useAppSelector((state) => state.addCampaign.fireAndForget);
	const {
		siteAcceptableProfit: siteAcceptableProfitError
	} = useAppSelector((state) => state.addCampaign.errors.fields);
	const { network } = useAppSelector((state) => state.campaignDetails);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('siteAcceptableProfit', value)}
			label={`${getNetworkLabel(network)} Acceptable Profit`}
			value={siteAcceptableProfit}
			name="site-acceptable-profit"
			type="number"
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			endAdornment={<span>%</span>}
			disabled={!campaignAcceptableProfitActive || fireAndForgetStatus === false}
			error={siteAcceptableProfitError}
			helperText={siteAcceptableProfitError}
		/>
	)
}
