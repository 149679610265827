import Toggle from "../../../../../FormComponents/Toggle";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function IgnoreACT(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			ignoreAct,
			fireAndForgetStatus,
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<div className="add-campaign-field-row">
			<span className="ignore-act-padding">
				<Toggle
					label="Ignore Act"
					trackColor="#4285F4"
					value={ignoreAct}
					onChange={(checked) => updateFireAndForget('ignoreAct', checked)}
					name="ignore-act"
					disabled={fireAndForgetStatus === false}
				/>
			</span>
		</div>
	)
}
