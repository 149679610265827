import sortBy from 'lodash/sortBy';
import LineChart from '../../../../Charts/LineChart';
import { conditionalProfitClass, formatNumber } from '@/utils/formatNumbers.ts';
import ChartContainer from './ChartContainer.tsx';
import formatCSVData from '../../../../../utils/csvHelper.ts';

const csvColumns = [
	{ id: 'hour', displayName: 'Hour' },
	{ id: 'clicks', displayName: 'Clicks' },
	{ id: 'conversions', displayName: 'Conversions' },
	{ id: 'revenue', displayName: 'Revenue' },
	{ id: 'epc', displayName: 'EPC' },
	{ id: 'cvr', displayName: 'CVR' },
	{ id: 'profit_percent', displayName: 'Profit %' },
];

function formatLabel(label) {
	let labelString;
	for (let i = 0; i <= parseInt(label, 10); i++) {
		let hour = i;
		let daytime = 'am';
		if (i === 0) {
			hour = 12;
		} else if (hour > 12) {
			hour = i - 12;
			daytime = 'pm';
		} else if (i === 12) {
			daytime = 'pm';
		}
		labelString = `Averages for ${hour}${daytime}`;
	}
	return labelString;
}

function CustomTooltip({ active, payload, label }: { active: boolean, payload: object[number], label: string }) {
	if (active && payload && payload[0] && payload[0].payload) {
		const values = payload[0].payload;
		const profit = values.profit_percent || 0;
		return (
			<div className="rounded bg-black/70 px-2 py-1 text-sm font-medium text-white">
				<p className="text-base font-bold text-[#4285F4]">{formatLabel(label)}</p>
				<p>EPC: ${values.epc}</p>
				<p>CVR: {values.cvr || 0}%</p>
				<p>Clicks: {formatNumber(values.clicks || 0)}</p>
				<p>Conversions: {formatNumber(values.conversions || 0)}</p>
				<p>Profit Percent: <span className={conditionalProfitClass('profit', profit)}>{profit}%</span></p>
			</div>
		);
	}
	return null;
}
const formatData = (response) => {
	const sortedResponse = sortBy(response, 'hour');
	const chartData = sortedResponse.map((item) => {
		const finalData = {};
		Object.keys(item).forEach((key) => {
			finalData[key] = key === 'clicks' ? Number(item[key]) : Number(item[key]).toString();
		});
		return finalData;
	});

	const csvData = formatCSVData(chartData, csvColumns);
	return { chartData, csvData };
};

export default function ClicksAndConversions() {
	return (
		<ChartContainer
			endpoint="api/v1/campaign/reporting/chart/clicksConversions"
			name="Clicks & Conversions"
			csvColumns={csvColumns}
			ChartComponent={ClicksAndConversionsChart}
			formatResponse={formatData}
		/>
	);
}

function ClicksAndConversionsChart({ data: chartData }) {
	return (
		<LineChart
			width={1000}
			height={260}
			data={chartData}
			margin={{
				top: 5,
			}}
			dataKey="clicks"
			showDollar={false}
			showPercent={false}
			keyName="hour"
			name="Clicks"
			CustomTooltip={CustomTooltip}
		/>
	);
}
