import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';
import Input from '../../../AddCampaign/Fields/Input.tsx';
import Checkbox from '../../../../FormComponents/Checkbox.tsx';
import '../../CampaignDetails.css';

const { uniq } = lodash;

export default function StartLinkTest() {
  const dispatch = useAppDispatch();
  const [lpLinks, setLPLinks] = useState<string[]>([]);
  const [presellLinks, setPresellLinks] = useState<string[]>([]);
  const {
    campaignID, network, settingsDialogLoading, clicksLinkTest, linkTestURLs,
  } = useAppSelector((state) => state.campaignDetails);

  useEffect(() => {
    getData();
    return () => {
      dispatch(changeCampaignDetails({ key: 'linkTestURLs', value: [] }));
      dispatch(changeCampaignDetails({ key: 'clicksLinkTest', value: 2 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
    networkRequest('api/v1/campaign/links/pull', {
      campaignID,
      network,
    })
    .then((response) => response.json())
    .then((response) => {
      handleResponse(response)
      dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
    })
    .catch(() => {
      dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
    });
  };

  const handleResponse = (response) => {
    if (response.advancedTracking && response.landingPageLinks && Object.keys(response.landingPageLinks).length) {
      const lpLinksResp = Object.keys(response.landingPageLinks).map((key) => response.landingPageLinks[key].url);
      setLPLinks(uniq(lpLinksResp));
    }
    if (response.presellLinks && Object.keys(response.presellLinks).length) {
      const presellLinksResp = Object.keys(response.presellLinks).map((key) => response.presellLinks[key].url);
      setPresellLinks(uniq(presellLinksResp));
    }
  };

  const updateLinkTest = (checked: boolean, url: string) => {
    const newLinksToTest = [...linkTestURLs];
    if (checked) {
      newLinksToTest.push(url);
    } else {
      newLinksToTest.splice(linkTestURLs.indexOf(url), 1);
    }
    dispatch(changeCampaignDetails({ key: 'linkTestURLs', value: newLinksToTest }))
  };

  return settingsDialogLoading ? <FormLoader /> :
    <>
      <p className='mb-4'>Choose your links to test:</p>
      {!!presellLinks.length &&
        <div className="start-link-test-links">
          <p className="start-link-test-label">Presell Links:</p>
          {presellLinks.map((url, index) => (
            <Checkbox
              key={`presell-${index}`}
              label={url}
              value={linkTestURLs.includes(url)}
              onChange={(checked) => updateLinkTest(checked, url)}
              name={`start-link-presell-${index}`}
            />
          ))}
        </div>}
      {!!lpLinks.length &&
        <div className="start-link-test-links">
          <p className="start-link-test-label">Landing Page Links:</p>
          {lpLinks.map((url, index) => (
            <Checkbox
              key={`lp-${index}`}
              label={url}
              value={linkTestURLs.includes(url)}
              onChange={(checked) => updateLinkTest(checked, url)}
              name={`start-link-lp-${index}`}
            />
          ))}
        </div>}
      <div>
        <Input
          onChange={({ target: { value} }) => dispatch(changeCampaignDetails({ key: 'clicksLinkTest', value }))}
          value={clicksLinkTest}
          numeric
          name="clicksLinkTest"
          label="Number of Clicks"
        />
      </div>
    </>;
}
