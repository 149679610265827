import IgnoreACT from "../Fields/IgnoreACT";
import LookBackDays from "../Fields/LookBackDays";
import SampleSize from "../Fields/SampleSize";
import ProtectProfitStop from "../Fields/ProtectProfitStop";
import ProtectProfitSlow from "../Fields/ProtectProfitSlow";
import ClickPacingMinutes from "../Fields/ClickPacingMinutes";
import ClickPacingClicks from "../Fields/ClickPacingClicks";
import StopAmount from "../Fields/StopAmount";
import SlowAmount from "../Fields/SlowAmount";
import HighestBid from "../Fields/HighestBid";
import LowestBid from "../Fields/LowestBid";
import CampaignCPAGoal from "../Fields/CampaignCPAGoal";
import BudgetReset from "../Fields/BudgetReset";
import OptimizationMode from "../Fields/OptimizationMode";

export default function CPAGoalOptimization(): JSX.Element {
	return (
		<>
			<div className="add-campaign-field-row">
				<OptimizationMode/>
			</div>
			<div className="add-campaign-field-row">
				<BudgetReset/>
				<CampaignCPAGoal/>
			</div>
			<div className="add-campaign-field-row">
				<LowestBid/>
				<HighestBid/>
			</div>
			<div className="add-campaign-field-row">
				<SlowAmount/>
				<StopAmount/>
			</div>
			<div className="add-campaign-field-row">
				<ClickPacingClicks/>
				<ClickPacingMinutes/>
			</div>
			<div className="add-campaign-field-row">
				<ProtectProfitSlow/>
				<ProtectProfitStop/>
			</div>
			<div className="add-campaign-field-row">
				<SampleSize/>
				<LookBackDays/>
			</div>
			<div className="add-campaign-field-row">
				<IgnoreACT/>
			</div>
		</>
	)
}
