import {useEffect} from "react";
import {startOfDay} from "date-fns";
import {changeDate} from "../Redux/Slices/filters";
import {getFromLocalStorage} from "./local-storage-utils";
import {useAppDispatch} from "../hooks";
import { PREDEFINED_RANGES } from '../Components/Filters/DateRange.tsx';

export default function useInitializeApp() {
	const storedDateRange = getFromLocalStorage(getFromLocalStorage('userID') || 'dateRange');
	const initialDateRange = storedDateRange ? JSON.parse(storedDateRange || '{}') : false;

	const dispatch = useAppDispatch();

	useEffect(() => {
		const storedDate = {
			startDate: startOfDay(initialDateRange.startDate ? new Date(initialDateRange.startDate) : new Date()).toString(),
			endDate: startOfDay(initialDateRange.endDate ? new Date(initialDateRange.endDate) : new Date()).toString(),
			dateRange: initialDateRange.dateRange || 'today',
			refreshedAt: new Date().toString(),
		}
		const checkActualDate = (appLoadedDate: number) => {
			if (appLoadedDate !== new Date().getDate()) {
				const actualDateRange = getFromLocalStorage(getFromLocalStorage('userID') || 'dateRange');
				const parsedActualDateRange = JSON.parse(actualDateRange || '{}');
				const currentRange = PREDEFINED_RANGES[parsedActualDateRange.dateRange];
				if (currentRange) {
					const currentRangeObj = currentRange.range();
					const finalRange = {
						dateRange: currentRangeObj.range,
						startDate: currentRangeObj.startDate.toString(),
						endDate: currentRangeObj.endDate.toString(),
					}
					localStorage.setItem(
						localStorage.getItem('userID') || 'dateRange',
						JSON.stringify(finalRange),
					);
				}
				window.location.reload();
			}
		};
		dispatch(changeDate(storedDate));
		const appLoadedDate = new Date().getDate();
		const checkDateInterval = setInterval(() => checkActualDate(appLoadedDate), 3600000);
		return () => {
			clearInterval(checkDateInterval);
		}
	}, []);
}