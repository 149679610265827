import {useMemo} from "react";
import {formatCurrency, formatFloat, formatNumber, formatPercent, conditionalProfitClass} from "../../utils/formatNumbers";

const typeFormatMapping = {
	"number": (value: number) => formatNumber(value),
	"float": (value: number) => formatFloat(value),
	"currency": (value: number) => formatCurrency(value),
	"percent": (value: number) => formatPercent(value),
}

export default function Glance ({ data, dataTypes, dataNames }: { data: object, dataTypes: object, dataNames: object }) {
	const formattedData = useMemo(function (): Array<object> {
		const dataKeys = Object.keys(data);
		return dataKeys.map((key) => {
			const value = dataTypes[key] && typeFormatMapping[dataTypes[key]] ? typeFormatMapping[dataTypes[key]](data[key]) : data[key];
			return { key, value };
		});
	}, [data, dataTypes]);

	return (
		<div className="flex flex-initial justify-start w-full px-0 flex-col flex-nowrap py-1 sm:flex-row sm:flex-wrap sm:py-3">
			{formattedData.map((item, index) => (
				item.key !== 'act' &&
				<div key={item.key} className={`flex flex-col-reverse mt-1 border-b border-neutral-200 font-medium text-left sm:mt-4 sm:border-0 sm:block sm:basis-1/6 ${index < 6 ? '!mt-0.5' : ''}`}>
					<div className={`text-2xl/5 w-11/12 truncate pt-px pb-2 mx-auto border-neutral-200 sm:border-b
					${conditionalProfitClass(item.key, item.value)} `}>
						{item.value}
					</div>
					<div className="text-lg/4 text-black/50 pt-1.5 w-11/12 mx-auto mb-1 pb-2 sm:pb-0">
						{dataNames[item.key]}
					</div>
				</div>
			))}
		</div>
	)
}
