import CsvDownload from 'react-csv-downloader';
import { ReactNode, useEffect, useState } from 'react';
import { format } from 'date-fns';
import lodash from 'lodash';
import { Columns, Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { useAppDispatch, useAppSelector, usePrevious } from '../../../../../hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import Download from '../../../../../Icons/Download.tsx';
import MaximusLoader from '../../../../Loaders/MaximusLoader.tsx';
import { refreshAll } from '../../../../../Redux/Slices/filters.ts';

const { camelCase, isEqual, upperFirst } = lodash;

interface ChartContainerInterface {
  endpoint: string,
  formatResponse: () => ({ chartData: object, csvData: Datas }),
  name: string,
  csvColumns: Columns,
  ChartComponent: () => ReactNode,
}

export default function ChartContainer({ endpoint, formatResponse, name, ChartComponent, csvColumns } : ChartContainerInterface) {
  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<object[]>([]);
  const [csvData, setCSVData] = useState<Datas>([]);
  const filters = useAppSelector((state) => state.filters);
  const { campaignID, network } = useAppSelector((state) => state.campaignDetails);
  const dispatch = useAppDispatch();

  const setFormattedFilters = (): object => {
    const formattedFilters: object = {
      campaignID,
      network,
    };
    Object.keys(filters).forEach((filter) => {
      if (['startDate', 'endDate', 'weekDay', 'dateRange'].includes(filter) && filters[filter]) {
        if (filter === 'startDate' || filter === 'endDate') {
          formattedFilters[filter] = format(new Date(filters[filter]), 'yyyy-MM-dd');
        } else {
          formattedFilters[filter] = filters[filter];
        }
      }
    });
    return formattedFilters;
  };
  const previousFilters = usePrevious(setFormattedFilters());

  useEffect(() => {
    async function makeRequest() {
      if (!isEqual(setFormattedFilters(), previousFilters) || filters.refreshAllData) {
        return getChartData();
      }
      return true;
    }

    if (campaignID) {
      makeRequest();
    }
  }, [setFormattedFilters, previousFilters, filters.refreshAllData]);

  const getChartData = async () => {
    setLoading(true);
    dispatch(refreshAll(false));
    const formattedFilters = setFormattedFilters();
    networkRequest(endpoint, {
      ...formattedFilters,
    }, 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object') {
          const data = formatResponse(response);
          setChartData(data.chartData || []);
          setCSVData(data.csvData || []);
        }
        setLoading(false);
      }
    )
    .catch(() => {
      setChartData([]);
      setLoading(false);
    });
  };

  return (
    <div className="campaign-details-chart">
      <div className="campaign-details-charts-header">
        <div className="campaign-details-charts-header-left">
          {name}
        </div>
        <div className="campaign-details-charts-header-right">
          <CsvDownload
            filename={upperFirst(camelCase(name))}
            datas={csvData}
            columns={csvColumns}
          >
            <button type="button" className="graph-download-button">
              <Download className="graph-download-icon" />
            </button>
          </CsvDownload>
        </div>
      </div>
      {loading ? <MaximusLoader size={45} /> : <ChartComponent data={chartData} />}
    </div>);
}
