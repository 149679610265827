/**
 * Returns the scrollbar width of the root element
 *
 * @param rootElement Where to append the scroll div (usually document.body)
 * @param fallbackValue Fallback value if the scrollbar size is 0 or cannot be determined
 * @returns numeric pixel value
 *
 * @see https://github.com/mui/mui-x/issues/12908#issuecomment-2077770432
 */
export function useScrollbarSize(rootElement: Element, fallbackValue: number) {
	if (!rootElement) return fallbackValue

	const scrollDiv = document.createElement('div')
	scrollDiv.style.width = '99px'
	scrollDiv.style.height = '99px'
	scrollDiv.style.position = 'absolute'
	scrollDiv.style.overflow = 'scroll'

	rootElement.appendChild(scrollDiv)
	const size = scrollDiv.offsetWidth - scrollDiv.clientWidth
	rootElement.removeChild(scrollDiv)

	return size || fallbackValue
}
