import { AppDispatch } from '@/Redux/reduxStore.ts';
import networkRequest from '../../../../utils/networkRequest.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';

export const changeFFStatus = (campaignID: string, network: number, dispatch: AppDispatch, newStatus = false) =>
  networkRequest('api/v1/campaign/fnf/status/update', { campaignID, network, fireAndForgetStatus: newStatus }, 'POST')
  .then((response) => response.json())
  .then((response) => {
      if (response && typeof response === 'object') {
        if (!response.success) {
          dispatch(openSnackbar(
            {
              children: response.message || 'Something went wrong. Please try again',
              severity: 'error'
            }
          ));
        } else {
          dispatch(openSnackbar({ children: 'F&F Status was updated successfully.', severity: 'success' }));
					dispatch(changeCampaignDetails({ key: 'ffStatus', value: newStatus }));
        }
      }
    }
  );
