import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { ValidateStepProps } from '../../../AddCampaign/Components/StepsValidation.tsx';
import { changeNonField } from '../../../../../Redux/Slices/addCampaign.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';
import {validateLinks} from "../../../AddCampaign/Components/Validation/Links.tsx";

const findNewAddedLinks = (links, originalLinks: string[]) => {
	const newLinkURLs = Object.keys(links).map(key => links[key].url);
	const uniqueNewLinks = newLinkURLs.filter(link => originalLinks.indexOf(link) === -1);
	if (uniqueNewLinks.length) {
		return uniqueNewLinks;
	}
	return [];
};

export const useSubmitLinks = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network, maximusLinks: originalMaximusLinks } = useAppSelector((state) => state.campaignDetails);
	const {
		settings: { maximusLinks, isOttoCampaign, s1 },
		link, landingPageLinks, presellLinks,
		originalLPLinks, originalPresellLinks, advancedTracking,
	} = useAppSelector((state) => state.addCampaign);
	return async () =>  {
		const newErrors: ValidateStepProps = validateLinks({
			link,
			presellLinks,
			landingPageLinks,
			maximusLinks,
			advancedTracking,
			campaignSettings: {
				isOttoCampaign,
				s1,
			}
		});
		dispatch(changeNonField({ key: 'errors', value: newErrors }));
		if (Object.keys(newErrors.fields).length) {
			return;
		}
		await networkRequest('api/v1/campaign/links/update', {
			campaignID,
			network,
			maximusLinks,
			link,
			advancedTracking: maximusLinks ? advancedTracking : false,
			presellLinks: maximusLinks ? presellLinks : undefined,
			landingPageLinks: (maximusLinks && advancedTracking) ? landingPageLinks : undefined,
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object' && response.success) {
					const newPresellLinks = findNewAddedLinks(presellLinks, originalPresellLinks);
					const newLPLinks = advancedTracking ? findNewAddedLinks(landingPageLinks, originalLPLinks) : [];
					if (newPresellLinks.length || (newLPLinks.length && advancedTracking)) {
						dispatch({ type: 'campaignDetails/changeCampaignDetails', payload: { key: 'linkTestURLs', value: [...newPresellLinks, ...newLPLinks] }});
						dispatch({ type: 'campaignDetails/changeCampaignDetails', payload: { key: 'editSettingName', value: 'startLinkTest' }});
					} else {
						dispatch({ type: 'campaignDetails/closeDialog' });
						if (!!originalMaximusLinks !== !!maximusLinks) {
							dispatch({type: 'campaignDetails/changeCampaignDetails', payload: {key: 'updatedCampaignSettings', value: true}});
						}
					}
				} else {
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error'
						}
					));
				}
			}
		);
	}
}

export const useConfirmLinks = ({ maximusLinks, originalMaximusLinks }) => {
	if (!maximusLinks) {
		return "Editing this campaign's links with Maximus Links 'off' will result in the campaign being re-reviewed by the network. During that review process, this campaign will stop receiving traffic. Are you sure you want to continue?";
	}
	if (maximusLinks && !originalMaximusLinks) {
		return 'Adding Maximus Links to a campaign will result in the campaign needing to be re-reviewed by the network this one time, and will temporarily not get traffic until that review is completed. Once that review process is done, you\'ll be able to edit links on this campaign freely while Maximus Links are enabled. Are you sure you want to continue?'
	}
	return '';
};
