import { GridAggregationFunction } from '@mui/x-data-grid-premium';

export const profitPercent = (estimated = false) : GridAggregationFunction<{ spend: number; revenue: number }, number> => ({
  label: '',
  getCellValue: ({ row }) => ({
    spend: estimated ? row.estimatedSpend : row.spend,
    revenue: estimated ? row.maximusRevenue : row.revenue,
  }),
  apply: ({ values }) => {
    let spend = 0;
    let revenue = 0;
    values.forEach((value) => {
      if (value) {
        spend += Number(value.spend);
        revenue += Number(value.revenue);
      }
    });
    if (!revenue && !spend) {
      return 0;
    }
    if (!revenue && spend > 0) {
      return -100;
    }
    const profitPercent = ((revenue - spend) / revenue) * 100;
    if (profitPercent < -100) {
      return -100;
    }
    return profitPercent;
  },
  columnTypes: ['string', 'number'],
});
