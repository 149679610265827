import intersection from 'lodash/intersection';
import SectionTitle from "../Components/SectionTitle";
import Toggle from "../../../FormComponents/Toggle";
import Input from "../Fields/Input";
import Select from "../Fields/Select";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {AddCampaignState, changeFireAndForgetField, changeSettingsField} from "../../../../Redux/Slices/addCampaign";
import { Network } from '../../../../utils/network-utils';
import {format} from "date-fns";
import ChipSelect from "../Fields/ChipSelect";
import {useEffect, useState} from "react";
import networkRequest from "../../../../utils/networkRequest";
import {CircularProgress} from "@mui/material";
import { countryTargetingOptions } from './constants';

const formatDate = (date: string) => {
	try {
		if (!date) return '';
		const splitDate = date.split('-');
		if (splitDate.length !== 3) return '';
		if (splitDate[0].length !== 4) return date;
		return format(new Date(`${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`), 'yyyy-MM-dd');
	} catch (e) {
		return '';
	}
}

const conversionCapOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'No Cap'},
	{value: '1', label: 'Daily'},
	{value: '2', label: 'Weekly'},
	{value: '3', label: 'Monthly'},
];

const trafficAllocationOptions: Array<{ value: string, label: string }> = [
	{value: '1', label: 'Even'},
	{value: '2', label: 'Optimized'},
];

const defaultGroupOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'Please Select'},
];

const defaultAccountOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'Please Select'},
];

const defaultSubAccountOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'Please Select'},
];


const osTargetingOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Mac OS X'},
	{value: '2', label: 'iOS'},
	{value: '3', label: 'Android'},
	{value: '4', label: 'Linux'},
	{value: '5', label: 'Windows'},
];

const browserOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Chrome'},
	{value: '2', label: 'Safari'},
	{value: '3', label: 'Facebook In-App Browser'},
	{value: '4', label: 'Edge'},
	{value: '5', label: 'WebView'},
	{value: '6', label: 'Chromium'},
	{value: '7', label: 'Firefox'},
	{value: '8', label: 'Samsung Browser'},
	{value: '9', label: 'Internet Explorer'},
	{value: '10', label: 'Google Search App'},
	{value: '11', label: 'Android App'},
	{value: '12', label: 'Opera'},
	{value: '13', label: 'MIUI Browser'},
	{value: '14', label: 'Amazon Silk'},
	{value: '15', label: 'UC Browser'},
	{value: '16', label: 'Yandex'},
];

const platformOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Desktop'},
	{value: '2', label: 'Mobile'},
	{value: '3', label: 'Tablet'},
];

const connectionOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Wi-Fi Only'},
];

const marketingObjective: Array<{ value: string, label: string }> = [
	{value: '1', label: 'Brand Awareness'},
	{value: '2', label: 'Online Purchases'},
	{value: '3', label: 'Lead Generation'},
	{value: '4', label: 'Drive Website Traffic'},
];

const bidStrategyOptions: Array<{ value: string, label: string }> = [
	{value: '1', label: 'Fixed'},
	{value: '2', label: 'Smart'},
	{value: '3', label: 'Max Conversions'},
	{value: '4', label: 'Target CPA'},
];

const verticalOptions: Array<{ value: string, label: string }> = [
	{ value: 'auto', label: 'Auto' },
	{ value: 'home', label: 'Home' },
	{ value: 'life', label: 'Life' },
	{ value: 'commercial', label: 'Commercial' },
	{ value: 'pet', label: 'Pet' },
	{ value: 'medicare', label: 'Medicare' },
];

const modifyOSTargetingOptions = (selectedPlatforms: string[]) => {
	if (selectedPlatforms.includes('0') || ['1', '2', '3'].every((val: string) => selectedPlatforms.includes(val))) {
		return osTargetingOptions;
	}
	if ((selectedPlatforms.includes('2') || selectedPlatforms.includes('3')) && !selectedPlatforms.includes('1')) {
		return [
			osTargetingOptions[0],
			osTargetingOptions[2],
			osTargetingOptions[3],
		];
	}
	if (selectedPlatforms.includes('1') && !(selectedPlatforms.includes('2') || selectedPlatforms.includes('3'))) {
		return [
			osTargetingOptions[0],
			osTargetingOptions[1],
			osTargetingOptions[4],
			osTargetingOptions[5],
		];
	}
	return osTargetingOptions;
};

export const modifyOSTargetingValue = (newPlatformValue: string[], osTargetingValue: string[]) => {
	const newOSOptionsKeys = modifyOSTargetingOptions(newPlatformValue).map(val => val.value);
	const availableOptions = intersection(newOSOptionsKeys, osTargetingValue);
	if (!availableOptions.length) {
		return ['0'];
	}
	return availableOptions;
};

export default function TaboolaDetails({campaignDetails = false}: { campaignDetails?: boolean }) {
	const [groupOptionsLoading, setGroupOptionsLoading] = useState(false);
	const [groupOptions, setGroupOptions] = useState(defaultGroupOptions);
	const [accountOptionsLoading, setAccountOptionsLoading] = useState(false);
	const [accountOptions, setAccountOptions] = useState(defaultAccountOptions);
	const [subAccountOptionsLoading, setSubAccountOptionsLoading] = useState(false);
	const [subAccountOptions, setSubAccountOptions] = useState(defaultSubAccountOptions);
	const [accountData, setAccountData] = useState<any[]>([]);
	const [previousCPC, setPreviousCPC] = useState(0);
	const [previousSiteAcceptableProfit, setPreviousSiteAcceptableProfit] = useState(true);

	const dispatch = useAppDispatch();
	const {settings, errors, isClone} = useAppSelector((state) => state.addCampaign);
	const updateSettings = <K extends keyof AddCampaignState['settings']>(
		name: K,
		value: AddCampaignState['settings'][K]
	) => {
		dispatch(changeSettingsField({ name, value }))
	}
	const updateFireAndForget = <K extends keyof AddCampaignState['fireAndForget']>(
		name: K,
		value: AddCampaignState['fireAndForget'][K]
	) => {
		dispatch(changeFireAndForgetField({ name, value }))
	}

	const handleChipSelect = (value: string[], allValues: string[]) => {
		const newValue: string[] = [];
		if (allValues.includes('0') && value.includes('0') && value.length > 1) {
			newValue.push(...value.filter((v) => v !== '0'));
		} else if (value.includes('0') || value.length === 0) {
			newValue.push('0');
		} else {
			newValue.push(...value);
		}
		return newValue;
	}

	useEffect(() => {
		if (!campaignDetails) {
			pullGroupOptions();
		}
		if (isClone) {
			pullAccountOptions(settings.group);
			pullSubAccountOptions(settings.usmID);
		}
	}, []);

	const pullGroupOptions = () => {
		setGroupOptionsLoading(true);
		networkRequest("api/v1/user/group/pull", {}, "POST")
			.then((response) => response.json())
			.then((data) => {
				if (data && typeof data === 'object' && data.length > 0) {
					setGroupOptions(
						[{value: '0', label: 'Please Select'}].concat(data.map((group) => ({value: group.id, label: group.name})))
					);
				}
			})
			.then(() => setGroupOptionsLoading(false))
			.catch(() => setGroupOptionsLoading(false));
	}

	const pullAccountOptions = (group) => {
		if (group === '0') {
			setAccountOptions(defaultAccountOptions);
			return;
		}
		setAccountOptionsLoading(true);
		networkRequest("api/v1/accounts/pull", {group, network: Network.Taboola}, "POST")
			.then((response) => response.json())
			.then((data) => {
				if (data && typeof data === 'object' && data.length > 0) {
					setAccountData(data);
					setAccountOptions(
						[{value: '0', label: 'Please Select'}].concat(data.map((account) => ({
							value: account.id,
							label: account.name
						})))
					);
				}
			})
			.then(() => setAccountOptionsLoading(false))
			.catch(() => setAccountOptionsLoading(false));
	}

	const pullSubAccountOptions = (usmID) => {
		if (usmID) {
			setSubAccountOptionsLoading(true);
			networkRequest("api/v1/accounts/sub/pull", {usm_id: usmID, network: Network.Taboola}, "POST")
				.then((response) => response.json())
				.then((data) => {
					if (data && typeof data === 'object' && data.length > 0) {
						setSubAccountOptions(
							[{value: '0', label: 'Please Select'}].concat(data.map((subAccount) => ({
								value: subAccount.id,
								label: subAccount.name
							})))
						);
					}
				})
				.then(() => setSubAccountOptionsLoading(false))
				.catch(() => setSubAccountOptionsLoading(false));
		} else {
			setSubAccountOptions(defaultSubAccountOptions);
		}
	}

	return (
		<>
			<SectionTitle
				title="Taboola Campaign Details"
				EndAddition={
					<div>
						<Toggle
							name="is-otto"
							label="OTTO Campaign"
							trackColor="#4285F4"
							value={settings.isOttoCampaign}
							onChange={(checked) => updateSettings('isOttoCampaign', checked)}
						/>
						<Toggle
							name="is-agency"
							label="Agency Campaign"
							trackColor="#4285F4"
							value={settings.isAgencyCampaign}
							onChange={(checked) => updateSettings('isAgencyCampaign', checked)}
						/>
					</div>
				}
			/>

        <div className="add-campaign-field-row">
          <Input
            onChange={({target: {value}}) => updateSettings('campaignName', value)}
            label="Campaign Name"
            value={settings.campaignName}
            name="campaign-name"
            error={!!errors.fields.campaignName}
            helperText={errors.fields.campaignName}
          />
					{!campaignDetails && (
						<>
							<Input
								onChange={({target: {value}}) => updateSettings('siteRuleSet', value)}
								label="Site Rule Set"
								value={settings.siteRuleSet}
								name="site-rule-set"
								error={!!errors.fields.siteRuleSet}
								helperText={errors.fields.siteRuleSet}
							/>
							<Input
								onChange={({target: {value}}) => updateSettings('startDate', (value || '').toString())}
								label="Start Date"
								type="date"
								value={formatDate(settings.startDate)}
								name="start-date"
								error={!!errors.fields.startDate}
								helperText={errors.fields.startDate}
							/>
						</>
					)}
        </div>
			<div className="add-campaign-field-row">
				<Input
					onChange={({target: {value}}) => updateSettings('budget', value)}
					label="Budget"
					value={settings.budget}
					name="campaign-budget"
					numeric
					startAdornment={<span>$</span>}
					error={!!errors.fields.budget}
					helperText={errors.fields.budget}
				/>
				<Input
					onChange={({target: {value}}) => updateSettings('brandingText', value)}
					label="Branding Text"
					value={settings.brandingText}
					name="branding-text"
					error={!!errors.fields.brandingText}
					helperText={errors.fields.brandingText}
				/>
				<Select
					onChange={({target: {value}}) => updateSettings('creativeTrafficAllocation', value)}
					label="Creative Traffic Allocation"
					value={settings.creativeTrafficAllocation}
					name="creative-traffic-allocation"
					options={trafficAllocationOptions}
					error={!!errors.fields.creativeTrafficAllocation}
					helperText={errors.fields.creativeTrafficAllocation}
				/>
			</div>
			<div className="add-campaign-field-row">
				<Select
					onChange={({target: {value}}) => updateSettings('conversionCapTimeframe', value)}
					label="Conversion Cap Timeframe"
					value={settings.conversionCapTimeframe}
					name="conversion-cap-timeframe"
					options={conversionCapOptions}
					error={!!errors.fields.conversionCapTimeframe}
					helperText={errors.fields.conversionCapTimeframe}
				/>
				<Input
					onChange={({target: {value}}) => updateSettings('conversionCapThreshold', value)}
					label="Conversion Cap Threshold"
					value={settings.conversionCapThreshold}
					name="conversion-cap-threshold"
					numeric
					disabled={settings.conversionCapTimeframe === '0'}
					error={!!errors.fields.conversionCapThreshold}
					helperText={errors.fields.conversionCapThreshold}
				/>
			</div>
			<div className="mb-3"/>
			{!campaignDetails &&
        <>
          <SectionTitle title="Account Details"/>
          <div className="add-campaign-field-row">
            <Select
              onChange={({target: {value}}) => {
								updateSettings('group', value);
								updateSettings('taboolaAccount', '0');
								pullAccountOptions(value);
							}}
              label="Group"
              value={settings.group}
              name="campaign-group"
              options={groupOptions}
							disabled={groupOptionsLoading}
              error={!!errors.fields.group}
              helperText={errors.fields.group}
            />

						{settings.group !== '0' && accountOptions.length > 1 ? (
							<Select
								onChange={({target: {value}}) => {
									const usmID = accountData.filter((account: { id: string, usm_id: string }) => account.id === value)[0]?.usm_id || '';
									updateSettings('taboolaAccount', value);
									updateSettings('usmID', usmID);
									updateSettings('taboolaSubAccount', '0')
									pullSubAccountOptions(usmID);
								}}
								label="Taboola Account"
								value={settings.taboolaAccount}
								name="taboola-account"
								options={accountOptions}
								error={!!errors.fields.taboolaAccount}
								helperText={errors.fields.taboolaAccount}
							/>
						)
							: accountOptionsLoading && <div className="progress-inline-container"><CircularProgress /></div>
						}
						{settings.taboolaAccount !== '0' && subAccountOptions.length > 1 ? (
							<Select
								onChange={({target: {value}}) => updateSettings('taboolaSubAccount', value)}
								label="Taboola Sub Account"
								value={settings.taboolaSubAccount}
								name="taboola-sub-account"
								options={subAccountOptions}
								error={!!errors.fields.taboolaSubAccount}
								helperText={errors.fields.taboolaSubAccount}
							/>
						)
							: subAccountOptionsLoading && <div className="progress-inline-container"><CircularProgress /></div>
						}
          </div>
          <div className="mb-3"/>
        </>}
			<SectionTitle title="Campaign Targeting"/>
			<div className="add-campaign-field-row">
				<ChipSelect
					onChange={({ target: { value } }) => {
						const newValue = handleChipSelect(value, settings.platformTargeting);
						updateSettings('platformTargeting', newValue);
						const modifiedOSTargetingValue = modifyOSTargetingValue(newValue, settings.osTargeting);
						updateSettings('osTargeting', modifiedOSTargetingValue);
					}}
					label="Platform Targeting"
					value={settings.platformTargeting}
					name="platform-targeting"
					options={platformOptions}
					error={!!errors.fields.platformTargeting}
					helperText={errors.fields.platformTargeting}
				/>
				<ChipSelect
					onChange={({ target: { value} }) => updateSettings('osTargeting', handleChipSelect(value, settings.osTargeting))}
					label="OS Targeting"
					value={settings.osTargeting}
					name="os-targeting"
					options={modifyOSTargetingOptions(settings.platformTargeting)}
					error={!!errors.fields.osTargeting}
					helperText={errors.fields.osTargeting}
				/>
				<Select
					onChange={({target: {value}}) => updateSettings('connectionType', value)}
					label="Connection Type"
					value={settings.connectionType}
					name="connection-type"
					options={connectionOptions}
					error={!!errors.fields.connectionType}
					helperText={errors.fields.connectionType}
				/>
			</div>
			{!campaignDetails && settings.platformTargeting.includes('0') &&
				<div className="ignore-act-padding">
					<Toggle
						label="Create a Campaign Per Platform"
						trackColor="#4285F4"
						value={settings.splitByDevice}
						onChange={(checked) => updateSettings('splitByDevice', checked)}
						name="split-by-device"
					/>
				</div>}
			<div className="add-campaign-field-row">
				<Select
					onChange={({target: {value}}) => updateSettings('marketingObjective', value)}
					label="Marketing Objective"
					value={settings.marketingObjective}
					name="marketing-objective"
					options={marketingObjective}
					error={!!errors.fields.marketingObjective}
					helperText={errors.fields.marketingObjective}
				/>
				<Select
					onChange={({target: {value}}) => updateSettings('browserTargeting', [value])}
					label="Browser Targeting"
					value={settings.browserTargeting[0]}
					name="browser-targeting"
					options={browserOptions}
					error={!!errors.fields.browserTargeting}
					helperText={errors.fields.browserTargeting}
				/>
				<ChipSelect
					onChange={({ target: { value } }) => {
						const newValue = value?.length ? value : ['all']
						updateSettings('countryTargeting', handleChipSelect(newValue, settings.countryTargeting))
					}}
					label="Country Targeting"
					value={settings.countryTargeting}
					name="country-targeting"
					options={countryTargetingOptions}
					aggregateAllOptions
					error={!!errors.fields.countryTargeting}
					helperText={errors.fields.countryTargeting}
				/>
			</div>
			<div className="mb-3"/>
			<SectionTitle title="Taboola Optimization"/>
			<div className="add-campaign-field-row">
				<Select
					onChange={({target: {value}}) => {
						if (value === '3' || value === '4') {
							if (settings.cpc !== '0') {
								setPreviousCPC(settings.cpc);
							}
							updateSettings('cpc', '0');
						} else {
							updateSettings('targetCPA', '0');
							if (previousCPC && +previousCPC !== 0) {
								updateSettings('cpc', previousCPC);
								setPreviousCPC(0);
							}
						}
						if (value === '2') {
							setPreviousSiteAcceptableProfit(settings.siteAcceptableProfitActive);
							updateFireAndForget('campaignAcceptableProfitActive', false);
						} else {
							updateFireAndForget('campaignAcceptableProfitActive', previousSiteAcceptableProfit || false);
						}
						updateSettings('bidStrategy', value);
					}}
					label="Bid Strategy"
					value={settings.bidStrategy}
					name="bid-strategy"
					options={campaignDetails ? bidStrategyOptions : bidStrategyOptions.slice(0, -1)}
					error={!!errors.fields.bidStrategy}
					helperText={errors.fields.bidStrategy}
				/>
				{(!(['3', '4'].includes(settings.bidStrategy)) && !settings.maximusDayparting) && (
					<Input
						onChange={({target: {value}}) => updateSettings('cpc', value)}
						label="Campaign CPC"
						value={settings.cpc}
						numeric
						name="campaign-cpc"
						startAdornment={<span>$</span>}
						error={!!errors.fields.cpc}
						helperText={errors.fields.cpc}
					/>
				)}
				{settings.bidStrategy === '4' && (
					<Input
						onChange={({target: {value}}) => updateSettings('targetCPA', value)}
						label="Target CPA"
						value={settings.targetCPA}
						numeric
						name="campaign-targetCPA"
						startAdornment={<span>$</span>}
						error={!!errors.fields.targetCPA}
						helperText={errors.fields.targetCPA}
					/>
				)}
			</div>
			{settings.isOttoCampaign && <>
				<div className="mb-3" />
				<SectionTitle title="OTTO Data" />
				<div className="add-campaign-field-row">
					<Select
						onChange={({ target: { value } }) => updateSettings('vertical', value)}
						label="Vertical"
						value={settings.vertical}
						name="vertical"
						options={verticalOptions}
						error={!!errors.fields.vertical}
						helperText={errors.fields.vertical}
					/>
					<Input
						onChange={({ target: { value } }) => updateSettings('affiliate', value)}
						label="Affiliate ID"
						value={settings.affiliate}
						name="affiliate"
						error={!!errors.fields.affiliate}
						helperText={errors.fields.affiliate}
					/>
					<Input
						onChange={({ target: { value } }) => updateSettings('s1', value)}
						label="S1"
						value={settings.s1}
						name="s1"
						error={!!errors.fields.s1}
						helperText={errors.fields.s1}
					/>
				</div>
			</>}
		</>
	)
}
