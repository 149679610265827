import { useEffect, useMemo, useState } from 'react'
import { FileToUpload, getImageObj, getVideoObj } from './validation'

const commonRatios = [
	{ ratio: 16 / 9, label: '16:9' },
	{ ratio: 4 / 3, label: '4:3' },
	{ ratio: 1, label: '1:1' },
	{ ratio: 3 / 2, label: '3:2' },
	{ ratio: 21 / 9, label: '21:9' },
]

const parseAspectRatio = (width: number, height: number): string => {
	const calculatedRatio = width / height

	const closestMatchingRatio = commonRatios.reduce((prev, curr) =>
		Math.abs(curr.ratio - calculatedRatio) < Math.abs(prev.ratio - calculatedRatio) ? curr : prev
	)

	const tolerance = 0.005
	if (Math.abs(closestMatchingRatio.ratio - calculatedRatio) <= tolerance) {
		return closestMatchingRatio.label
	}

	const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b))
	const divisor = gcd(width, height)
	return `${width / divisor}:${height / divisor}`
}

export default function FileData({ item }: { item: FileToUpload }) {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

	useEffect(() => {
		const getDimensions = async () => {
			if (item.file.type.startsWith('image/')) {
				setDimensions(await getImageObj(item.file))
			} else {
				setDimensions(await getVideoObj(item.file))
			}
		}
		getDimensions()
	}, [item])

	const aspectRatio = useMemo(() => {
		return parseAspectRatio(dimensions.width, dimensions.height)
	}, [dimensions.width, dimensions.height])

	const warningColor = item.error
		? 'border-red-600 bg-red-600/20'
		: 'border-yellow-600 bg-yellow-600/15'

	return (
		<div>
			<p>
				<b>Name:</b> {item.file.name}
			</p>
			<p>
				<b>Size:</b> {(item.file.size / 1024).toFixed(2)} KB
			</p>
			<p>
				<b>Type:</b> {item.file.type}
			</p>
			<p>
				<b>Dimensions:</b> {dimensions.width} x {dimensions.height}
			</p>
			<p>
				<b>Aspect Ratio:</b> {aspectRatio}
			</p>

			{!!item.reason && (
				<p className={`my-2 rounded border p-1 text-white ${warningColor}`}>
					<b>{item.reason}</b>
				</p>
			)}
		</div>
	)
}
