import { FormControl } from '@mui/material';
import { changeFilter } from '../../Redux/Slices/filters';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Toggle from '../FormComponents/Toggle.tsx';

export default function AgencyCampaignFilter() {
  const isAgency = useAppSelector((state) => state.filters.isAgency);
  const dispatch = useAppDispatch()
  return (
    <FormControl className="select-filter-form-control" variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Toggle
        name="is-agency"
        label="Agency Campaign"
        trackColor="#4285F4"
        value={isAgency}
        onChange={(checked) => dispatch(changeFilter({ filter: 'isAgency', value: checked }))}
      />
    </FormControl>
  );
}
