import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar, updateMainStore } from '../../../../../Redux/Slices/main.ts';
import { changeCampaignDetails, defaultEdAdDataObj } from '../../../../../Redux/Slices/campaignDetails.ts';

export const useSubmitUpdateAd = () => {
  const dispatch = useAppDispatch();
  const { campaignID, network, editedAdData } = useAppSelector((state) => state.campaignDetails);
  const { tableRows } = useAppSelector((state) => state.main);

  return async () => {
    if (!editedAdData.headline) {
      dispatch(openSnackbar({
          children: 'Headline should not be empty.',
          severity: 'error',
        },
      ));
      return;
    }
    await networkRequest('api/v1/campaign/ad/update', {
      campaignID,
      network,
      title: editedAdData.headline,
      description: editedAdData.description,
      cta: editedAdData.cta,
      adID: editedAdData.adID.toString(),
    })
    .then((response) => response.json())
    .then((response) => {
        if (response && response.success) {
          const newRows = tableRows.map((row: object) => {
            if (row.id === editedAdData.id) {
              return {
                ...row,
                cta: editedAdData.cta,
                description: editedAdData.description,
                headline: editedAdData.headline,
              };
            }
            return row;
          });
          dispatch(updateMainStore({ key: 'tableRows', value: newRows }));
          dispatch(changeCampaignDetails({ key: 'editedAdData', value: defaultEdAdDataObj }));
          dispatch({ type: 'campaignDetails/closeDialog' });
        } else {
          dispatch(openSnackbar(
            {
              children: response.message || 'Something went wrong. Please try again.',
              severity: 'error',
            },
          ));
        }
      }
    );
  }
}
