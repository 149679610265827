import { useEffect } from 'react';
import { format } from 'date-fns';
import FireAndForget from '../../../AddCampaign/Pages/FireAndForget/FireAndForget';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { changeFireAndForgetField } from '../../../../../Redux/Slices/addCampaign.ts';
import FormLoader from "../../../../Loaders/FormLoader.tsx";
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import '../../../AddCampaign/addCampaign.css';

export default function FireAndForgetSettings() {
	const dispatch = useAppDispatch();
	const { campaignID, network, settingsDialogLoading } = useAppSelector((state) => state.campaignDetails);
	const { userID } = useAppSelector((state) => state.main);
	const { startDate, endDate, weekDay } = useAppSelector((state) => state.filters);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async () => {
		dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
		networkRequest('api/v1/campaign/fnf/pull', {
			campaignID,
			network,
			userID,
			weekDay,
			startDate: format(new Date(startDate), 'yyyy-MM-dd'),
			endDate: format(new Date(endDate), 'yyyy-MM-dd'),
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object') {
					setSettings(response);
				}
				dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
			}
		)
		.catch(() => {
			dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
		});
	};

	const setSettings = (response) => {
		dispatch(changeFireAndForgetField({ name: 'fireAndForgetStatus', value: response.fireAndForgetStatus === '1' }));
		dispatch(changeFireAndForgetField({ name: 'budgetReset', value: response.budgetReset || 0 }));
		dispatch(changeFireAndForgetField({ name: 'budgetResetActive', value: response.budgetResetActive || false }));
		dispatch(changeFireAndForgetField({ name: 'campaignAcceptableProfit', value: response.campaignAcceptableProfit || 0 }));
		dispatch(changeFireAndForgetField({ name: 'campaignCpaGoal', value: response.campaignCpaGoal || 0 }));
		dispatch(changeFireAndForgetField({ name: 'lowestBid', value: response.lowestBid || 0 }));
		dispatch(changeFireAndForgetField({ name: 'highestBid', value: response.highestBid || 0 }));
		dispatch(changeFireAndForgetField({ name: 'slowAmount', value: response.slowAmount || 0 }));
		dispatch(changeFireAndForgetField({ name: 'stopAmount', value: response.stopAmount || 0 }));
		dispatch(changeFireAndForgetField({ name: 'slowStopAmountActive', value: response.slowStopAmountActive || false }));
		dispatch(changeFireAndForgetField({ name: 'campaignCpaGoalActive', value: response.campaignCpaGoalActive || false }));
		dispatch(changeFireAndForgetField({ name: 'campaignAcceptableProfitActive', value: response.campaignAcceptableProfitActive || false }));
		dispatch(changeFireAndForgetField({ name: 'protectProfitSlow', value: response.protectProfitSlow || 0 }));
		dispatch(changeFireAndForgetField({ name: 'protectProfitStop', value: response.protectProfitStop || 0 }));
		dispatch(changeFireAndForgetField({ name: 'protectProfitActive', value: response.protectProfitActive || false }));
		dispatch(changeFireAndForgetField({ name: 'lookbackDays', value: response.lookbackDays || '0' }));
		dispatch(changeFireAndForgetField({ name: 'sampleSize', value: response.sampleSize || 0 }));
		dispatch(changeFireAndForgetField({ name: 'clickPacingClicks', value: response.clickPacingClicks || 0 }));
		dispatch(changeFireAndForgetField({ name: 'clickPacingMinutes', value: response.clickPacingMinutes || 0 }));
		dispatch(changeFireAndForgetField({ name: 'clickPacingActive', value: response.clickPacingActive || false }));
		dispatch(changeFireAndForgetField({ name: 'ignoreAct', value: response.ignoreAct || false }));
		dispatch(changeFireAndForgetField({ name: 'siteAcceptableProfit', value: response.siteAcceptableProfit || 0 }));
		dispatch(changeFireAndForgetField({ name: 'siteLowestBid', value: response.siteLowestBid || 0 }));
		dispatch(changeFireAndForgetField({ name: 'siteHighestBid', value: response.siteHighestBid || 0 }));
	};

	return (
		settingsDialogLoading ? <FormLoader /> : <FireAndForget campaignDetails />
	);
}
