import { useEffect } from 'react';
import DayParting from '../../../AddCampaign/Pages/DayParting/DayParting';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';
import { changeNonField } from '../../../../../Redux/Slices/addCampaign.ts';
import { daysMapping } from '../../../AddCampaign/Pages/DayParting/dayPartingHelper.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import '../../../AddCampaign/addCampaign.css';

export default function DayPartingSettings() {
	const dispatch = useAppDispatch();
	const { campaignID, network, settingsDialogLoading } = useAppSelector((state) => state.campaignDetails);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async () => {
		dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
		networkRequest('api/v1/campaign/dayParting/pull', {
			campaignID,
			network,
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object') {
					setSettings(response);
				}
				dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
			}
		)
		.catch(() => {
			dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
		});
	};

	const modifyDayPartingFromParams = (dayParting: object[]) => (
		dayParting.map((item) => ({
			...item,
			days: item.days.map((day: string) => daysMapping[day]),
			id: crypto.randomUUID(),
		})));

	const setSettings = (response) => {
		dispatch(changeNonField({ key: 'dayParting', value: response.dayParting && response.dayParting.length ? modifyDayPartingFromParams(response.dayParting) : [] }));
    dispatch(changeNonField({ key: 'networkDayParting', value: response.networkDayParting && response.networkDayParting.length ? modifyDayPartingFromParams(response.networkDayParting) : [] }));
    dispatch(changeNonField({ key: 'maximusDayParting', value: response.maximusDayParting || false }));
	};

	return (
		settingsDialogLoading ? <FormLoader /> : <DayParting campaignDetails />
	);
}
