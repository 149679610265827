import { Tooltip } from '@mui/material';
import { useAppSelector } from '../../../../../hooks.ts';

export default function EditCampaignDetailsTitle () {
  const { campaignName } = useAppSelector((state) => state.campaignDetails);
  return (
    <>
      Edit Campaign Details
      {campaignName &&
        <Tooltip title={campaignName}>
          <span> ({campaignName})</span>
        </Tooltip>}
  </>);
}
