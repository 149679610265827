import { useState } from 'react';
import lodash from 'lodash';
import { format } from 'date-fns';
import { Tab, Tabs } from '@mui/material';
import AreaChart from '../../../../Charts/AreaChart';
import { conditionalProfitClass } from '../../../../../utils/formatNumbers.ts';
import ChartContainer from './ChartContainer.tsx';

const { capitalize } = lodash;

const csvColumns = [
	{ id: 'day', displayName: 'Day' },
	{ id: 'current_clicks', displayName: 'Current Clicks' },
	{ id: 'previous_clicks', displayName: 'Previous Clicks' },
	{ id: 'current_revenue', displayName: 'Current Revenue' },
	{ id: 'previous_revenue', displayName: 'Previous Revenue' },
	{ id: 'current_profit', displayName: 'Current Profit' },
	{ id: 'previous_profit', displayName: 'Previous Profit' },
];
const tabs = ['clicks', 'profit', 'revenue'];

const generateCustomTooltip = (chartType: string) => {
	const showDollar = chartType !== 'clicks' ? '$' : '';
	const valueFormatter = (value: number) => {
		if (chartType === 'profit') {
			return <span className={conditionalProfitClass('profit', value)}>${value}</span>;
		}
		return `${showDollar}${value}`;
	}
	return function CustomTooltip({ active, payload, label }: { active: boolean, payload: object[number], label: string }) {
		if (active && payload && payload[0] && payload[0].payload) {
				const values = payload[0].payload;
				return (
					<div className="rounded bg-black/70 px-2 py-1 text-sm font-medium text-white">
						<p className="text-base font-bold text-[#4285F4]">{label}</p>
						<p>Previous: {valueFormatter(values.previous)}</p>
						<p>Last: {valueFormatter(values.last)}</p>
					</div>
				);
		}
		return null;
	}
}

const CustomizedAxisTick = ({ x, y, payload }) => (
	<g>
		<text
			x={x} y={y} dy={13} textAnchor="middle" fill="#666"
			fontWeight={format(new Date(), 'EEEE') === payload.value ? 'bold' : 'normal'}
		>
			{payload.value}
		</text>
	</g>
);

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const formatResponseToCSV = (response) =>
	weekDays.map(weekDay => {
		let newRow = {
			day: weekDay,
		};
		tabs.forEach((tab: string) =>
			newRow = {
				...newRow,
				[`current_${tab}`]: response[tab].last[weekDay],
				[`previous_${tab}`]: response[tab].previous[weekDay],
			}
		);
		return newRow;
	});

const formatResponseToCharts = (response) => {
	const newChartData : { [index: string]: [] } = {};
	Object.keys(response).forEach((typeData: string) => {
		newChartData[typeData] = weekDays.map((weekDay: string) => {
			return {
				name: weekDay,
				previous: Number(response[typeData].previous[weekDay]),
				last: Number(response[typeData].last[weekDay]),
			};
		});
	});
	const csvData = formatResponseToCSV(response);
	return { chartData: newChartData, csvData };
};

export default function WeeklyComparison() {
	return (
		<ChartContainer
			endpoint="api/v1/campaign/reporting/chart/weeklyComparison"
			name="Weekly Comparison (Estimates Only)"
			formatResponse={formatResponseToCharts}
			ChartComponent={WeeklyComparisonChart}
			csvColumns={csvColumns}
		/>
	)
}

function WeeklyComparisonChart({ data: chartData }) {
	const [selectedChart, setSelectedChart] = useState<number>(0);

	return (
		<>
			<div className="campaign-details-charts-tabs">
				<Tabs
					value={selectedChart}
					onChange={(e, tabKey: number) => setSelectedChart(tabKey)}
				>
					{tabs.map((label, i) => (
						<Tab
							key={i}
							disableRipple
							label={capitalize(label)}
						/>
					))}
				</Tabs>
			</div>
			<AreaChart
				height={240}
				fields={[
					{ name: 'previous', color: '#8a85ff' }, { name: 'last', color: '#8a85aa' },
				]}
				data={chartData[tabs[selectedChart]]}
				dataKey="name"
				showDollar={tabs[selectedChart] !== 'clicks'}
				CustomXAxisTick={CustomizedAxisTick}
				CustomTooltip={generateCustomTooltip(tabs[selectedChart])}
			/>
		</>
	)
}
