import { Checkbox as CheckboxMUI, FormControlLabel } from '@mui/material';

export default function Checkbox(
  {
    name = '',
    label = '',
    onChange = () => null,
    value,
  } : {
    name?: string,
    label?: string,
    value: boolean,
    onChange?: (checked: boolean) => void,
  }
) {
  return (
    <FormControlLabel
      control={<CheckboxMUI checked={Boolean(value)} onChange={(e, checked) => onChange(checked)} />}
      label={label}
      name={name}
      labelPlacement="end"
    />
  )
}
