import {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import { getFromLocalStorage } from '../utils/local-storage-utils.ts';

export default function Logout() {
	const {logout} = useAuth0();
	useEffect(() => {
		localStorage.removeItem(getFromLocalStorage('userID'));
		localStorage.removeItem(`${getFromLocalStorage('userID')}_filters`);
		localStorage.removeItem('userID');
		localStorage.removeItem('userSettings_detailsReport');
		const endpoint = window.location.origin + '/login';
		logout({ logoutParams: { returnTo: endpoint } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div></div>
	)
}