import Reports from './Reports';
import Menu from './Menu';
import Glance from './Glance';
import Charts from './Charts';
import Settings from './Settings';
import {useCampaignDetails} from "./useCampaignDetails";
import NotificationStripe from "./utils/NotificationStripe";
import './CampaignDetails.css';

export default function CampaignDetails() {
	useCampaignDetails();

	return (
		<div className="campaign-details-container">
			<Menu />
			<div className="campaign-details-data-container">
				<NotificationStripe />
				<Glance />
				<Charts />
				<Reports />
				<Settings />
			</div>
		</div>
	)
}
