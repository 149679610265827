import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks.ts';
import Copy from '../../../../Icons/Copy.tsx';
import { openSnackbar } from '../../../../Redux/Slices/main.ts';

export default function CampaignInfoButton({ itemName, title } : { itemName: string, title: string }) {
  const dispatch = useAppDispatch();
  const campaignDetails = useAppSelector(({ campaignDetails }) => campaignDetails);
  const gotToLinkPage = () => {
    const newTab = window.open(campaignDetails[itemName], '_blank');
    if (newTab) {
      newTab.focus();
    }
  }

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(campaignDetails[itemName]);
      dispatch(openSnackbar({ children: 'Copied to clipboard!', severity: 'success' }));
    } catch (err) {
      dispatch(openSnackbar({ children: 'Text was not copied', severity: 'error' }));
    }
  }
  return campaignDetails[itemName] && (
    itemName === 'previewLink' ?
      <div className="campaign-info-item">
        <button
          className="campaign-info-item-button"
          type="button"
          onClick={gotToLinkPage}
        >
          {title}
        </button>
        <button type="button" className="campaign-info-item-copy-button" onClick={copyText}>
          <Copy />
        </button>
      </div> :
      <div className="campaign-info-block">
        <span className="campaign-info-block-label">{title}</span>
        <div className="campaign-info-item">
          <Tooltip title={campaignDetails[itemName]}>
            <span>{campaignDetails[itemName]}</span>
          </Tooltip>
          {campaignDetails[itemName] !== 'NA' &&
            <button type="button" className="campaign-info-item-copy-button" onClick={copyText}>
              <Copy />
            </button>}
        </div>
      </div>
  );
}