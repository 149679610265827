import { createTheme } from '@mui/material'

function getRootElement() {
	if (typeof window === 'undefined') return undefined
	return document.getElementById('app')
}

export const theme = createTheme({
	typography: {
		fontFamily: 'var(--maximus-font-family)',
	},
	components: {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 0,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					marginLeft: 0,
					marginRight: 0,
				},
			},
		},
		MuiPopover: {
			defaultProps: {
				container: getRootElement(),
			},
		},
		MuiPopper: {
			defaultProps: {
				container: getRootElement(),
			},
		},
		MuiDialog: {
			defaultProps: {
				container: getRootElement(),
			},
		},
		MuiModal: {
			defaultProps: {
				container: getRootElement(),
			},
		},
	},
})
