import SectionTitle from "../../../Components/SectionTitle";
import { getNetworkLabel } from '../../constants.ts';
import {useAppSelector} from "../../../../../../hooks";
import SiteHighestBid from "../Fields/SiteHighestBid";
import SiteLowestBid from "../Fields/SiteLowestBid";
import SiteAcceptableProfit from "../Fields/SiteAcceptableProfit";
import IgnoreACT from "../Fields/IgnoreACT";
import LookBackDays from "../Fields/LookBackDays";
import SampleSize from "../Fields/SampleSize";
import ProtectProfitStop from "../Fields/ProtectProfitStop";
import ProtectProfitSlow from "../Fields/ProtectProfitSlow";
import ClickPacingMinutes from "../Fields/ClickPacingMinutes";
import ClickPacingClicks from "../Fields/ClickPacingClicks";
import StopAmount from "../Fields/StopAmount";
import SlowAmount from "../Fields/SlowAmount";
import BudgetReset from "../Fields/BudgetReset";
import OptimizationMode from "../Fields/OptimizationMode";
import CampaignAcceptableProfit from '../Fields/CampaignAcceptableProfit.tsx';

export default function AcceptableProfitOptimization(): JSX.Element {
	const {
		settings: {
			bidStrategy,
		},
	} = useAppSelector((state) => state.addCampaign);
	const { network } = useAppSelector((state) => state.campaignDetails)

	return (
		<>
			<div className="add-campaign-field-row">
				<OptimizationMode/>
			</div>
			<div className="add-campaign-field-row">
				<BudgetReset/>
				<CampaignAcceptableProfit />
			</div>
			<div className="add-campaign-field-row">
				<SlowAmount/>
				<StopAmount/>
			</div>
			<div className="add-campaign-field-row">
				<ClickPacingClicks/>
				<ClickPacingMinutes/>
			</div>
			<div className="add-campaign-field-row">
				<ProtectProfitSlow/>
				<ProtectProfitStop/>
			</div>
			<div className="add-campaign-field-row">
				<SampleSize/>
				<LookBackDays/>
			</div>
			<div className="add-campaign-field-row">
				<IgnoreACT/>
			</div>

			<div className="mb-3"/>

			<SectionTitle title={`${getNetworkLabel(network)} Fire & Forget™ Settings`}/>
			{bidStrategy === '2' && (
				<div className="add-campaign-title-disclaimer">
					Due to the fact that you have selected a bid strategy of "Smart", site settings will be disabled.
				</div>
			)}
			<div className="add-campaign-field-row">
				<SiteAcceptableProfit/>
			</div>
			<div className="add-campaign-field-row">
				<SiteLowestBid/>
				<SiteHighestBid/>
			</div>
		</>
	)
}
