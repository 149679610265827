import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';
import Toggle from '../../../../../FormComponents/Toggle.tsx';

export default function ProtectProfitStop(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			protectProfitStop,
			protectProfitActive,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				protectProfitStop: protectProfitStopError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('protectProfitStop', value)}
			label="Protect Profit Stop"
			value={protectProfitStop}
			name="protect-profit-stop"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			endAdornment={
				<Toggle
					trackColor="#4285F4"
					value={protectProfitActive}
					onChange={(checked) => updateFireAndForget('protectProfitActive', checked)}
					name="protect-profit-status"
					disabled={fireAndForgetStatus === false}
				/>
			}
			disabled={protectProfitActive === false || fireAndForgetStatus === false}
			error={protectProfitStopError}
			helperText={protectProfitStopError}
		/>
	)
}
