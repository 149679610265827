import {ChangeEvent, ComponentProps, FocusEvent} from "react";
import { FormControl, InputAdornment, SxProps, TextField } from '@mui/material';

import {countDecimals} from "../Components/Validation/FireAndForget.tsx";

interface InputProps {
	endAdornment?: JSX.Element | string,
	startAdornment?: JSX.Element | string,
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
	onChange: (event: ChangeEvent<HTMLInputElement>) => void,
	label?: string,
	value?: string,
	type?: string,
	name: string,
	helperText?: string,
	disabled?: boolean,
	numeric?: boolean,
	nonDecimal?: boolean,
	multiline?: boolean,
	error?: boolean,
	size?: SxProps,
	maxSize?: number,
	inputSize?: ComponentProps<typeof TextField>['size'],
}

export default function Input(
	{
		endAdornment,
		startAdornment,
		onBlur = () => void 0,
		onChange,
		numeric,
		label = '',
		value,
		type = 'text',
		helperText = '',
		name,
		disabled = false,
		multiline = false,
		error = false,
		nonDecimal = false,
		size = {m: '12px', width: 'calc(33.33% - 24px)'},
		maxSize,
		inputSize = 'medium',
	}: InputProps
) {

	const formattedOnChange = (onEvent) => (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
		if (numeric) {
			const val = event.target.value;
			const regex = /^[0-9\b.]+$/;
			const numberOfPeriods = (val.match(/\./g) || []).length;
			if (val === '' || (regex.test(val) && numberOfPeriods <= 1 && countDecimals(val) < 3)) {
				onEvent(event);
			}
		}	else if (nonDecimal) {
			const val = event.target.value;
			const regex = /^[0-9]+$/;
			if (val === '' || regex.test(val)) {
				onEvent(event);
			}
		} else {
			onEvent(event);
		}
	}

	return (
		<FormControl sx={size} variant="filled">
			{!!value && !!maxSize && value.length > 0  && (
				<div className={`absolute right-2 top-2 text-xs text-black/60 ${value.length > maxSize && 'text-red-600'}`}>
					{value.length} / {maxSize}
				</div>
			)}
			<TextField
				id={`input-${name}`}
				size={inputSize}
				name={name}
				label={label}
				value={value}
				onChange={formattedOnChange(onChange)}
				onBlur={formattedOnChange(onBlur)}
				disabled={disabled}
				type={type}
				multiline={multiline}
				error={(!!maxSize && !!value && value.length > maxSize) || error}
				helperText={helperText}
				variant="filled"
				InputProps={{
					endAdornment: (
						endAdornment ? (
							<InputAdornment position="end">
								{endAdornment}
							</InputAdornment>
						) : ''
					),
					startAdornment: (
						startAdornment ? (
							<InputAdornment position="start">
								{startAdornment}
							</InputAdornment>
						) : ''
					)
				}}
			/>
		</FormControl>
	)
}
