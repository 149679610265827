import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { modifyDayPartingForParams } from '../../../AddCampaign/Components/SubmitCampaign.tsx';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';

export const useSubmitDayParting = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network } = useAppSelector((state) => state.campaignDetails);
	const { dayParting, networkDayParting, maximusDayParting } = useAppSelector((state) => state.addCampaign);

	return async () =>  {
		await networkRequest('api/v1/campaign/dayParting/update', {
			campaignID,
			network,
      maximusDayParting,
      dayParting: maximusDayParting ? modifyDayPartingForParams(dayParting) : [],
      networkDayParting: !maximusDayParting ? modifyDayPartingForParams(networkDayParting) : [],
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && response.success) {
					dispatch({ type: 'campaignDetails/closeDialog' });
				} else {
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again',
							severity: 'error'
						}
					));
				}
			}
		);
	}
}
