import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';

export const useSubmitStartLinkTest = () => {
  const dispatch = useAppDispatch();
  const { clicksLinkTest, linkTestURLs, campaignID, network } = useAppSelector((state) => state.campaignDetails);

  return async () =>  {
    if (!linkTestURLs.length) {
      dispatch(openSnackbar({
        children: 'Please select at least one link',
        severity: 'error',
      }));
      return;
    } else if (Number(clicksLinkTest) <= 0) {
      dispatch(openSnackbar({
        children: 'Number of clicks should be greater than 0.',
        severity: 'error',
      }));
      return;
    }
    await networkRequest('api/v1/campaign/linkTest/activate/update', {
      campaignID,
      network,
      testClicks: clicksLinkTest,
      urls: linkTestURLs,
    }, 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object' && response.success) {
          dispatch({ type: 'campaignDetails/closeDialog' });
          dispatch(changeCampaignDetails({ key: 'linkTestNotificationLoading', value: true }));
          getLinkTestNotification(campaignID, network, dispatch);
        } else {
          dispatch(openSnackbar({
            children: response.message || 'Something went wrong. Please try again.',
            severity: 'error',
          }));
        }
      }
    );
  }
}

const getLinkTestNotification = async (campaignID: string, network: number, dispatch: any) =>
  await networkRequest('api/v1/campaign/linkTest/notification/pull', {
    campaignID,
    network,
  }, 'POST')
  .then((response) => response.json())
  .then((data) => {
    dispatch(changeCampaignDetails({ key: 'linkTestNotificationLoading', value: false }));
    dispatch(changeCampaignDetails({ key: 'linkTestNotification', value: data[0] || '' }));
  })
  .catch(() => {
    dispatch(changeCampaignDetails({ key: 'linkTestNotificationLoading', value: false }));
  });

export const deactivateLinkTest = async (linkTestNotification: string, campaignID: string, network: number, dispatch: any) => {
  const oldNotification = linkTestNotification;
  dispatch(changeCampaignDetails({ key: 'linkTestNotification', value: '' }));
  dispatch(changeCampaignDetails({ key: 'linkTestNotificationLoading', value: true }));
  return await networkRequest('api/v1/campaign/linkTest/deactivate/update', {
    campaignID,
    network,
  }, 'POST')
  .then((response) => response.json())
  .then((data) => {
    if (data && !data.success) {
      dispatch(changeCampaignDetails({ key: 'linkTestNotification', value: oldNotification }));
      dispatch(openSnackbar({
        children: data.message || 'Something went wrong. Please try again',
        severity: 'error'
      }));
    } else {
      dispatch(openSnackbar({
        children: data.message || 'Link test was canceled successfully.',
        severity: 'success'
      }));
    }
    dispatch(changeCampaignDetails({ key: 'linkTestNotificationLoading', value: false }));
  })
  .catch(() => {
    dispatch(changeCampaignDetails({ key: 'linkTestNotification', value: oldNotification }));
    dispatch(changeCampaignDetails({ key: 'linkTestNotificationLoading', value: false }));
  });
};
