import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks'
import Lock from '../../../../../../Icons/Lock'
import LockOpen from '../../../../../../Icons/LockOpen'
import { openSnackbar } from '../../../../../../Redux/Slices/main'
import networkRequest from '../../../../../../utils/networkRequest'

type RoutingButtonProps = {
	row: { id: number; routing: boolean; presell_url: string }
	site: { id: string; name: string }
	widget: { id: string; name: string }
	imageID: string
	disabled: boolean
	onSuccess: (row: Record<string, any>) => void
}

export function RoutingButton({ row, imageID, site, widget, disabled, onSuccess }: RoutingButtonProps) {
	const { routing, presell_url } = row

	const { campaignID, network } = useAppSelector(state => state.campaignDetails)
	const dispatch = useAppDispatch()

	const [submitting, setSubmitting] = useState(false)

	const siteName = () => {
		if (site.name && site.name !== 'NA') {
			return site.name
		} else if (row.widget_name && row.widget_name !== 'NA') {
			return row.widget_name
		}
		return ''
	}

	const tooltip = routing
		? `Click here to remove the routing lock from this presell`
		: `Click here to route "${siteName()}" to this presell`

	const routePresell = () => {
		if (submitting) return
		setSubmitting(true)
		networkRequest('api/v1/campaign/routing/update', {
			campaignID,
			lockRoute: !routing,
			network,
			imageID,
			siteID: site.id || undefined,
			widgetID: widget.id || undefined,
			presellUrl: presell_url,
		})
			.then(response => response.json())
			.then(response => {
				if (response?.success) {
					onSuccess(row)
				} else {
					dispatch(
						openSnackbar({
							children: response?.message || 'Something went wrong. Please try again.',
							severity: 'error',
						})
					)
				}
			})
			.catch(() => {
				dispatch(
					openSnackbar({ children: 'Something went wrong. Please try again.', severity: 'error' })
				)
			})
			.finally(() => setSubmitting(false))
	}

	if (!routing && !presell_url) return null

	return (
		<Tooltip title={tooltip}>
			<IconButton
				className={`bg-black/[.03] hover:bg-black/5 ${disabled && !submitting ? 'opacity-50' : undefined}`}
				size="small"
				disabled={disabled}
				onClick={routePresell}
			>
				{submitting ? (
					<CircularProgress size={24} color="inherit" />
				) : row.routing ? (
					<Lock className="size-6 fill-[#4285F4]" />
				) : (
					<LockOpen className="size-6 fill-[#9e9e9e]" />
				)}
			</IconButton>
		</Tooltip>
	)
}
