import Select from "../../../Fields/Select";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

const lookbackDaysOptions: { label: string, value: string }[] = [
	{label: 'All Time', value: '0'},
	{label: 'Today', value: '1'},
	{label: 'Yesterday', value: '2'},
	{label: 'Past 7 Days', value: '7'},
	{label: 'Past 24 Hours', value: '24'},
	{label: 'Yesterday & Today', value: '48'},
	{label: 'Last 30 Days', value: '30'},
];

export default function LookBackDays(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			lookbackDays,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				lookbackDays: lookbackDaysError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Select
			onChange={({target: {value}}) => updateFireAndForget('lookbackDays', value)}
			label="Look Back Days"
			value={lookbackDays}
			name="look-back-days"
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			options={lookbackDaysOptions}
			disabled={fireAndForgetStatus === false}
			error={lookbackDaysError}
			helperText={lookbackDaysError}
		/>
	)
}
