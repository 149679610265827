import { useState } from 'react';
import SectionTitle from './Components/SectionTitle';
import CampaignInfoButton from './CampaignInfoButton.tsx';
import List from '../../../../Icons/List.tsx';
import { useAppSelector } from '../../../../hooks.ts';
import { isRevContent } from '../../../../utils/network-utils.ts';

export default function InfoSection() {
  const { network } = useAppSelector((state) => state.campaignDetails);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <div className={`menu-section ${collapsed ? 'menu-section-collapsed' : ''}`}>
      <SectionTitle
        title="Campaign Info"
        icon={<List className="menu-section-icon" />}
        onClick={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
      />
      <CampaignInfoButton itemName="previewLink" title="Preview Link" />
      <CampaignInfoButton itemName="networkCampaignID" title="Campaign ID" />
      {!isRevContent(network) &&
        <CampaignInfoButton itemName="subAccountID" title="Sub Account" />}
      <CampaignInfoButton itemName="campaignID" title="Maximus Campaign ID" />
      <CampaignInfoButton itemName="startDate" title="Start Date" />
    </div>
  )
}
